import * as L from 'leaflet';
import tilesColors from '../styles/config/tilesColors.json';

import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import {TILE_SERVER_DBNAME, TILE_SERVER_HOST, TILE_SERVER_PORT} from 'constants/carteConfig';
import persistentDatas from "./persistentData";
import {DATA_FILTER_TYPE_DEP, DATA_FILTER_TYPE_REG_15, DATA_FILTER_TYPE_REG_16} from "constants/dataFilterType";


export function getFetchTokenConfig(table, css, interactivity, geoType, type) {
    const anneesGeos = persistentDatas.get('anneesGeos');
    let joinTableName = 'commune_pmsi';
    if(geoType === DATA_FILTER_TYPE_REG_15 || geoType === DATA_FILTER_TYPE_REG_16) {
        joinTableName = 'region';
    } else if(geoType === DATA_FILTER_TYPE_DEP) {
        joinTableName = 'departement';
    }
    const joinTable = joinTableName + '_' + anneesGeos[geoType];
    let columns = ['value', 'class'];
    if(type === 'grid') {
        columns = interactivity.filter(column => column !== 'code' && column !== 'lib' && column !== 'population')
    }
    const sql = "select " + columns.reduce((accu, column) => accu + 't."' + column + '",', '') + " j.id, j.code, j.lib, j.population, j.superficie, j.the_geom_webmercator from user_data." + table + " t LEFT JOIN " + joinTable + " j ON j.id = t.id;";

    return JSON.stringify({
        version: "1.2.0",
        layers: [
            {
                type: "mapnik",
                options: {
                    sql,
                    cartocss: css,
                    cartocss_version: "2.1.1",
                    srid: 4326,
                    interactivity: interactivity
                }
            }
        ]
    }, null, 2);
}

export function getGridInteractivity(hasEtsA, hasEtsB, annees) {
    let interactivity = ['code', 'lib', 'population'];
    for(let annee in annees){
        if(annees.hasOwnProperty(annee)) {
            if (hasEtsA) {
                interactivity.push(SELECTOR_TYPE_ETS_A + annee);
            }
            if (hasEtsB) {
                interactivity.push(SELECTOR_TYPE_ETS_B + annee);
            }
        }
    }


    return interactivity;
}

export function getBaseURL(token, type){
    let protocol = window.location.protocol;
    const port = TILE_SERVER_PORT
        ? ':' + TILE_SERVER_PORT
        : '';
    const layerIndex = type === 'grid'
        ? '0/'
        : '';

    return `${protocol}//${TILE_SERVER_HOST}${port}/database/${TILE_SERVER_DBNAME}/layergroup/${token}/${layerIndex}{z}/{x}/{y}`;
}

export function getTileURL(token, backend){
    const retina = L.Browser.retina;
    return  getBaseURL(token, 'tile') + (retina ? '@2x' : '') + '.png?backend=' + backend;
}

export function getUftGridUrl(token, backend){
    return getBaseURL(token, 'grid') + '.grid.json?backend=' + backend;
}

export function getGridCss(){
    return `
        #layer{
            polygon-fill: #FFFFFF;
        }`
    ;
}

export function getTileCss(benchType){
    let colorIndice = 2;
    if(SELECTOR_TYPE_ETS_A === benchType){
        colorIndice = 1
    }
    return `
        #layer {
            polygon-fill: ${tilesColors['$TILE-COLOR-DEFAULT']};
            line-color: #fff;
            polygon-opacity:0.8;
            [class="0"] {
                polygon-fill: #FFFFFF;
            }
            [class="1"] {
                polygon-fill: ${tilesColors['$DEG' + colorIndice + '-1']};
            }
            [class="2"] {
                polygon-fill: ${tilesColors['$DEG' + colorIndice + '-2']};
            }
            [class="3"] {
                polygon-fill: ${tilesColors['$DEG' + colorIndice + '-3']};
            }
            [class="4"] {
                polygon-fill: ${tilesColors['$DEG' + colorIndice + '-4']};
            }
            [class="5"] {
                polygon-fill: ${tilesColors['$DEG' + colorIndice + '-5']};
            }
        }
    `;
}
