import {
    SELECTOR_LOAD_PENDING,
    SELECTOR_LOAD_FULFILLED,
    SELECTOR_LOAD_REJECTED,
    SELECTOR_SEARCH,
    SELECTOR_CLEAN_ALL,
    SELECTOR_SAVE_PENDING,
    SELECTOR_SAVE_FULFILLED,
    SELECTOR_SAVE_REJECTED,
    SELECTOR_REGIONS_TYPE,
    SELECTOR_SET_SELECTED_MARKER,
    SELECTOR_UNSELECT_MARKER
} from 'constants/actionTypes';


import {DATA_FILTER_TYPE_REG_16} from 'constants/dataFilterType.js'

export default function mainSelectorReducer(state = {
    hash: null,
    pending: false,
    error: false,
    ready: false,
    inputValue: '',
    inputTerm: null,
    regionsType: DATA_FILTER_TYPE_REG_16,
    selectorType: 'etablissement',
    selectedMarker: null
}, action) {
    if (SELECTOR_LOAD_PENDING === action.type) {
        return {...state, hash: action.payload.hash, pending: true, error: false, ready: false};
    }
    if (SELECTOR_LOAD_FULFILLED === action.type) {
        return {...state, pending: false, error: false, ready: true};
    }
    if (SELECTOR_SAVE_PENDING === action.type) {
        return {...state, hash: null, pending: true, error: false, ready: false};
    }
    if (SELECTOR_SAVE_FULFILLED === action.type) {
        return {...state, hash: action.payload.hash, pending: false, error: false, ready: true};
    }
    if (SELECTOR_LOAD_REJECTED === action.type || SELECTOR_SAVE_REJECTED === action.type) {
        return {...state, hash: null, pending: false, error: true, ready: false};
    }
    if (SELECTOR_SEARCH === action.type) {
        return {...state, inputTerm: action.payload.inputTerm, inputValue: action.payload.inputValue};
    }
    if (SELECTOR_CLEAN_ALL === action.type) {
        return {...state, hash: null};
    }
    if (SELECTOR_REGIONS_TYPE === action.type) {
        return {...state, regionsType: action.payload}
    }
    if (SELECTOR_SET_SELECTED_MARKER === action.type) {
        return {...state, selectedMarker: action.payload}
    }
    if (SELECTOR_UNSELECT_MARKER === action.type) {
        return {...state, selectedMarker: action.payload}
    }

    return state;
}
