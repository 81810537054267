import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {navigate} from "actions/routerActions";
import {
    SECTION_NAME_SELECTEUR,
    PAGE_NAME_FICHE_ETABLISSEMENTS,
    PAGE_NAME_FICHE_APPAREIL,
    PAGE_NAME_FICHE_GHM,
    PAGE_NAME_FICHE_PRISE_EN_CHARGE,
    PAGE_NAME_FICHE_SEXE_AGE,
    PAGE_NAME_CARTE_ATTRACTIVITE,
    PAGE_NAME_CARTE_CONCURENCE
} from 'constants/router';

import TabsMenu from 'components/molecules/TabsMenu/TabsMenu'


class DataPageNav extends Component {
    onItemClickHandler(tab){
        this.props.navigate(tab.linkKey);
    }

    render(){
        if (SECTION_NAME_SELECTEUR === this.props.section) {
            return null
        }

        const tabs = [
            {
                linkKey: PAGE_NAME_FICHE_ETABLISSEMENTS,
                transKey: 'navigation.etablissement',
                isActive: PAGE_NAME_FICHE_ETABLISSEMENTS === this.props.pageName,
            },
            {
                linkKey: PAGE_NAME_FICHE_APPAREIL,
                transKey: 'navigation.appareil',
                isActive: PAGE_NAME_FICHE_APPAREIL === this.props.pageName,
            },
            {
                linkKey: PAGE_NAME_FICHE_PRISE_EN_CHARGE,
                transKey: 'navigation.priseEnCharge',
                isActive: PAGE_NAME_FICHE_PRISE_EN_CHARGE === this.props.pageName,
            },
            {
                linkKey: PAGE_NAME_FICHE_SEXE_AGE,
                transKey: 'navigation.sexeAge',
                isActive: PAGE_NAME_FICHE_SEXE_AGE === this.props.pageName,
            },
            {
                linkKey: PAGE_NAME_FICHE_GHM,
                transKey: 'navigation.ghm',
                isActive: PAGE_NAME_FICHE_GHM === this.props.pageName,
            },
            {
                linkKey: PAGE_NAME_CARTE_ATTRACTIVITE,
                transKey: 'navigation.attractivite',
                isActive: PAGE_NAME_CARTE_ATTRACTIVITE === this.props.pageName,
            },
            {
                linkKey: PAGE_NAME_CARTE_CONCURENCE,
                transKey: 'navigation.concurence',
                isActive: PAGE_NAME_CARTE_CONCURENCE === this.props.pageName,
            },
        ];

        return (
            <div className="data-page-nav">
                <TabsMenu
                    onItemClickHandler={this.onItemClickHandler.bind(this)}
                    tabs={tabs}
                    className='center-container space-between'
                />
            </div>
        )
    }
}

DataPageNav.propTypes = {
    pageName: PropTypes.string,
    navigate: PropTypes.func,
    section: PropTypes.string
};

function mapStateToProps(state) {
    return {
        pageName: state.routing.currentRouteName,
        section: state.routing.section
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (pageName) => {
            dispatch(navigate(pageName));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPageNav);
