import React, {PureComponent} from 'react';
import {connect} from 'react-redux'
import PropTypes from "prop-types";

import {DATA_FILTER_TYPE_REG_15, DATA_FILTER_TYPE_REG_16} from 'constants/dataFilterType.js'

import TabsMenu from '../TabsMenu/TabsMenu'
import {switchRegions} from 'actions/selectorActions'

class SelectorSwitch extends PureComponent {

	onItemClickHandler(tab) {
		this.props.switchRegions(tab.linkKey)
	}

	render() {
        const tabs = [
            {
                linkKey: DATA_FILTER_TYPE_REG_15,
                transKey: 'dataType.ancienneRegion_plural',
                isActive: DATA_FILTER_TYPE_REG_15 === this.props.regionsType,
            },
            {
                linkKey: DATA_FILTER_TYPE_REG_16,
                transKey: 'dataType.nouvelleRegion_plural',
                isActive: DATA_FILTER_TYPE_REG_16 === this.props.regionsType,
            },
        ];

        return (
			<TabsMenu className="selector-switch" onItemClickHandler={this.onItemClickHandler.bind(this)} tabs={tabs}/>
        )

	}
}

SelectorSwitch.propTypes = {
    regionsType: PropTypes.string,
	switchRegions: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        regionsType: state.mainSelector.regionsType
	};
}

function mapDispatchToProps(dispatch) {
    return {
		switchRegions(regionsType){
            dispatch(switchRegions(regionsType));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorSwitch);
