import axios from "axios";
import isEqual from "lodash/isEqual";
import {
  FICHE_ETABLISSEMENTS_PDM_SET_TYPE,
  FICHE_ETABLISSEMENTS_PDM_SET_DATAS,
} from "constants/actionTypes";
import { API_URL } from "constants/config";

export const fetchEtablissementsDatas =
  (typePdm, accesTime) => async (dispatch, getState) => {
    const { typePdm: stateTypePdm, accesTime: stateAccesTime } =
      getState().ficheEtablissements;

    if (stateTypePdm !== typePdm || stateAccesTime !== accesTime) {
      dispatch({
        type: FICHE_ETABLISSEMENTS_PDM_SET_TYPE,
        payload: { typePdm, accesTime },
      });

      try {
        const response = await axios.post(
          `${API_URL}/fiche/etablissements/datas`,
          {
            hash: getState().mainSelector.hash,
            typePdm,
            accesTime,
          },
          { withCredentials: true }
        );

        const { data } = response;

        if (
          data.hash === getState().mainSelector.hash &&
          data.typePdm === typePdm &&
          isEqual(data.accesTime, accesTime)
        ) {
          dispatch({
            type: FICHE_ETABLISSEMENTS_PDM_SET_DATAS,
            payload: { typePdm, accesTime, datas: data.datas },
          });
        }
      } catch (error) {
        console.log("error", FICHE_ETABLISSEMENTS_PDM_SET_DATAS);
        // dispatch({type: FICHE_ETABLISSEMENTS_PDM_SET_TYPE, payload: {id: null}});
      }
    }
  };
