import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import CarteInfosEtablissementLib from 'components/molecules/CarteInfos/CarteInfosEtablissementLib';
import CarteInfosEtablissementSimple from 'components/molecules/CarteInfos/CarteInfosEtablissementSimple';
import CarteInfosEtablissementBenchmark from 'components/molecules/CarteInfos/CarteInfosEtablissementBenchmark';
import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE} from 'constants/router';
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import cn from 'classnames';

class CarteInfosEtablissement extends React.Component {
    getBenchType(){
        if(this.props[SELECTOR_TYPE_ETS_A].includes(this.props.idEts)){
            return SELECTOR_TYPE_ETS_A;
        }
        if(this.props[SELECTOR_TYPE_ETS_B].includes(this.props.idEts)){
            return SELECTOR_TYPE_ETS_B;
        }

        return null;
    }
    getClassName(){
        const benchType = this.getBenchType();

        return cn('carte-infos-etablissement', 'center-container', {[benchType]: null !== benchType});
    }
    renderValues(){
        if(null === this.props.datas || null === this.props.datas[this.props.annee][this.props.dataType].partBench){
            return (
                <CarteInfosEtablissementSimple
                    datas={null === this.props.datas ? null : this.props.datas[this.props.annee][this.props.dataType]}
                />
            );
        }
        return (
            <CarteInfosEtablissementBenchmark
                datas={this.props.datas[this.props.annee][this.props.dataType]}
                benchType={this.getBenchType()}
                dataType={this.props.dataType}
            />
        );
    }
    render(){
        if(0 === this.props.idEts){
            return null;
        }
        return (
            <div className={this.getClassName()}>
                <div className="empty-col" />
                <CarteInfosEtablissementLib
                    id={this.props.idEts}
                />
                {this.renderValues()}
                <div className="empty-col" />
            </div>
        );
    }
}


CarteInfosEtablissement.propTypes = {
    idEts: PropTypes.number,
    datas: PropTypes.object,
    annee: PropTypes.string,
    dataType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    etsA: PropTypes.object,
    etsB: PropTypes.object
};

function mapStateToProps(state) {
    return {
        idEts: state.carteInfos.idEts,
        datas: state.carteInfos.etsDatas,
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
        [SELECTOR_TYPE_ETS_A]: state.selector[SELECTOR_TYPE_ETS_A],
        [SELECTOR_TYPE_ETS_B]: state.selector[SELECTOR_TYPE_ETS_B]
    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarteInfosEtablissement);