import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import cloneDeep from 'lodash/cloneDeep'

import GeoLib from 'components/atoms/Format/GeoLib';
import CarteInfosBenchTitle from 'components/molecules/CarteInfos/CarteInfosBenchTitle';
import CarteInfosBenchmarkContent from 'components/molecules/CarteInfos/CarteInfosBenchmarkContent';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B
} from 'constants/selectorTypes';

import persistentData from 'managers/persistentData'

class CarteInfosBenchmark extends React.Component {
    getPartBenchmark(benchType, carteInfos){
        const benchValues = {
            [SELECTOR_TYPE_ETS_A] : undefined === carteInfos.annees[this.props.selectionAnnee][SELECTOR_TYPE_ETS_A]
                ? 0
                : carteInfos.annees[this.props.selectionAnnee][SELECTOR_TYPE_ETS_A].value
            ,
            [SELECTOR_TYPE_ETS_B] : undefined === carteInfos.annees[this.props.selectionAnnee][SELECTOR_TYPE_ETS_B]
                ? 0
                : carteInfos.annees[this.props.selectionAnnee][SELECTOR_TYPE_ETS_B].value
        };
        if((benchValues[SELECTOR_TYPE_ETS_A] + benchValues[SELECTOR_TYPE_ETS_B]) === 0) {
            return 0
        }
        return benchValues[benchType] /(benchValues[SELECTOR_TYPE_ETS_A] + benchValues[SELECTOR_TYPE_ETS_B]);
    }
    render(){
        let carteInfos = cloneDeep(this.props.carteInfos)
        if(null === carteInfos.lib) {
            return null
        }
        if(undefined === carteInfos.annees[this.props.selectionAnnee]){

            Object.keys(persistentData.get('annees')).forEach(annee => {
                if(undefined === carteInfos[annee]) {
                    return carteInfos['annees'][annee] = {
                        [SELECTOR_TYPE_ETS_A]: {
                            evol: 'general.na',
                            value: 0
                        },
                        [SELECTOR_TYPE_ETS_B]: {
                            evol: 'general.na',
                            value: 0
                        }
                    }
                }
            })

        }
        return (
            <div className="carte-infos-benchmark center-container">
                <div className="col">
                    <GeoLib
                        className="lib-geo"
                        lib={carteInfos.lib}
                        tradKey={this.props.libGeoTradKey}
                    />
                </div>
                <div className="col">
                    <div className="carte-infos-benchmark-container">
                        <CarteInfosBenchTitle benchType={SELECTOR_TYPE_ETS_A} title="A" align="right" part={this.getPartBenchmark(SELECTOR_TYPE_ETS_A, carteInfos)} />
                        <CarteInfosBenchmarkContent
                            selectionAnnee={this.props.selectionAnnee}
                            datas={carteInfos.annees}
                            partBenchmark={this.getPartBenchmark(SELECTOR_TYPE_ETS_A, carteInfos)}
                        />
                        <CarteInfosBenchTitle benchType={SELECTOR_TYPE_ETS_B} title="B" align="left" part={this.getPartBenchmark(SELECTOR_TYPE_ETS_B, carteInfos)} />
                    </div>
                </div>
            </div>
        );
    }
}


CarteInfosBenchmark.propTypes = {
    carteInfos: PropTypes.object,
    selectionAnnee: PropTypes.string,
    libGeoTradKey: PropTypes.string
};

function mapStateToProps(state) {
    return {
        carteInfos: state.carteInfos.geoDatas,
        selectionAnnee: state.routing.params.annee
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarteInfosBenchmark);
