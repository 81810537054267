import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import persistentDatas from 'managers/persistentData';
import cloneDeep from 'lodash/cloneDeep'

import CarteInfosSimpleAnnee from 'components/molecules/CarteInfos/CarteInfosSimpleAnnee';
import GeoLib from 'components/atoms/Format/GeoLib';
import {SELECTOR_TYPE_ETS_A} from 'constants/selectorTypes';

class CarteInfosSimple extends React.Component {
    renderAnnees(){
        const
            annees = persistentDatas.get('annees'),
            idAnnees = Object.keys(annees).sort((a, b) => {
                return parseInt(b) - parseInt(a);
            })
        ;
        let carteInfos = cloneDeep(this.props.carteInfos)
        return idAnnees.map((idAnnee) => {
            const anneeDatas = carteInfos.annees[idAnnee];
            if(undefined === anneeDatas){
                carteInfos.annees[idAnnee] = {
                    [SELECTOR_TYPE_ETS_A]: {
                        evol: 'general.na',
                        value: 0
                    }
                }
            }

            return (
                <div className="col" key={idAnnee}>
                    <CarteInfosSimpleAnnee
                        idAnnee={idAnnee}
                        value={carteInfos.annees[idAnnee][this.props.benchType].value}
                        evolution={carteInfos.annees[idAnnee][this.props.benchType].evol}
                        active={this.props.selectionAnnee === idAnnee}
                    />
                </div>
            );
        });
    }

    render(){
        if(null === this.props.carteInfos.lib){
            return null;
        }

        return (
            <div className="carte-infos-simple center-container">
                <div className="col">
                    <GeoLib
                        className="lib-geo"
                        lib={this.props.carteInfos.lib}
                        tradKey={this.props.libGeoTradKey}
                    />
                </div>
                {this.renderAnnees()}
            </div>
        );
    }
}


CarteInfosSimple.propTypes = {
    benchType: PropTypes.string.isRequired,
    carteInfos: PropTypes.object,
    selectionAnnee: PropTypes.string,
    libGeoTradKey: PropTypes.string
};

function mapStateToProps(state) {
    return {
        carteInfos: state.carteInfos.geoDatas,
        selectionAnnee: state.routing.params.annee
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarteInfosSimple);
