/* eslint-disable no-useless-escape */
import latinize from 'latinize';

export function cleanString(str) {
    str = str.trim()
        .replace(/\./g,'')
        .replace(/\-/g,' ')
        .replace(/\,/g,' ')
        .replace(/\//g,' ')
        .toLowerCase();

    return latinize(str);
}
