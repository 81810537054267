import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "reducers";
import routerMiddleware from "middlewares/routerParamsMiddleware";

const configureAppStore = () => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(routerMiddleware),
    });

    return store;
};

export default configureAppStore;
