export function isLeaf(item) {
    return (undefined === item.children || 0 === item.children.length)
}

export function getItemPath(item, path) {
    if (undefined === item.parent) {
        path.unshift(item.id)
        return path
    }
    else {
        path.unshift(item.id)
        return getItemPath(item.parent, path)
    }
}

export function findItemFromPath(list, path) {
    const id = path.shift()
    let itemFound = null;
    list.some((item) => {
        if (item.id === id) {
            if (0 === path.length) {
                itemFound = item
            }
            else {
                itemFound = findItemFromPath(item.children, path)
            }
            return true
        }
        return false
    })
    return itemFound
}

export function getLeafCount(tree) {
    let leafCount = 0
    tree.forEach(item => {
        if (isLeaf(item)) {
            leafCount += 1
        }
        else {
            leafCount += getLeafCount(item.children)
        }
    })
    return leafCount
}