import React, {Component}  from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import cn from 'classnames'
import ReactGA from 'react-ga4'

import {PAGE_NAME_SELECTEUR_GHM, SECTION_NAME_SELECTEUR, SECTION_NAME_FICHE, SECTION_NAME_CARTE} from 'constants/router'

import TopNav from 'containers/TopNav'
import DataPageNav from 'components/molecules/DataPageNav/DataPageNav'
import SelectorPageContent from 'components/organisms/SelectorPageContent/SelectorPageContent'
import FichePageContent from 'components/organisms/FichePageContent/FichePageContent'
import CartePageContent from 'components/organisms/CartePageContent/CartePageContent'
import SelectorInfo from 'components/molecules/Selector/SelectorInfo';



class ContentBlock extends Component {

    constructor(props) {
        super(props)

        this.sections = {
            [SECTION_NAME_SELECTEUR]: {
                content: <SelectorPageContent />,
                className: 'selector-page-content',
            },
            [SECTION_NAME_FICHE]: {
                content: <FichePageContent />,
                className: 'fiche-page-content',
            },
            [SECTION_NAME_CARTE]: {
                content: <CartePageContent />,
                className: 'carte-page-content',
            },
        }
    }

    componentDidMount() {
        //first page load GA send
        ReactGA.send({ hitType: "pageview", page: this.props.routeName, title: this.props.routeName });
    }

    render() {
        const section = 'selector-section-'+this.props.routingSection
        return (
            <div id="content-block" className={this.sections[this.props.routingSection].className}>
                <TopNav />
                <DataPageNav />
                <div className={cn('container-selector-info', section, {
                    'next-to-ghm': this.props.routeName === PAGE_NAME_SELECTEUR_GHM
                })}>
                    <SelectorInfo />
                </div>
                {this.sections[this.props.routingSection].content}
            </div>
        );
    }
}

ContentBlock.propTypes = {
    routingSection: PropTypes.string,
    routeName: PropTypes.any,
};

function mapStateToProps(state) {
    return {
        routingSection: state.routing.section,
        routeName: state.routing.currentRouteName,
    };
}

export default connect(mapStateToProps)(ContentBlock);
