import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';
import persistentDatas from 'managers/persistentData';

import FormattedDataType from "components/molecules/Format/FormattedDataType";
import Trans from "components/atoms/Format/Trans";
import Percent from "components/atoms/Format/Percent";

export default class CarteInfosSimpleAnnee extends React.Component {
    renderEvolution(){
        const annee = persistentDatas.get('annees')[this.props.idAnnee],
            prevIdAnnee = (this.props.idAnnee - 1),
            prevAnnee = persistentDatas.get('annees')[prevIdAnnee]
        ;
        if(undefined === prevAnnee){
            return null;
        }
        return (
            <div className="evolution">
                <Trans k="dataType.evolution" /> {prevAnnee.lib} - {annee.lib} : <Percent value={this.props.evolution} isEvolution={true} />
            </div>
        );
    }
    render(){
        return (
            <div className={cn('carte-infos-simple-annee', {active: this.props.active})}>
                <div className="annee">
                    {persistentDatas.get('annees')[this.props.idAnnee].lib}
                </div>
                <div className="value">
                    <FormattedDataType value={this.props.value} withLib={true} />
                </div>
                {this.renderEvolution()}
            </div>
        );
    }
}


CarteInfosSimpleAnnee.propTypes = {
    idAnnee: PropTypes.number.isRequired,
    value : PropTypes.number.isRequired,
    evolution: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired
};