import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { fetch } from "actions/dataActions";
import fetchDatasManager from "managers/fetchDatasManager";

import {
  PARAM_NAME_ANNEE,
  PAGE_NAME_FICHE_ETABLISSEMENTS,
} from "constants/router";
import { DATA_TYPE_FICHE_ETABLISSEMENTS } from "constants/dataType";

import FicheSummary from "components/molecules/FicheSummary/FicheSummary";
import FicheEtablissementsListContainer from "components/molecules/FicheEtablissements/FicheEtablissementsListContainer";
import HelixLoader from "components/atoms/Loader/HelixLoader";
import Trans from "components/atoms/Format/Trans";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes";

const EtablissementsPageContent = () => {
  const [toggleResetValue, setToggleResetValue] = useState(true);

  const handleClick = () => {
    setToggleResetValue(!toggleResetValue);
  };

  const dispatch = useDispatch();
  const state = useSelector(state => state);
  
  const {
    visible,
    fetchData,
    routingParams,
    annee,
    selectorReady,
    datas,
    isModeBenchmark
  } = {
    visible: PAGE_NAME_FICHE_ETABLISSEMENTS === state.routing.currentRouteName,
    fetchData: state.fetchData[DATA_TYPE_FICHE_ETABLISSEMENTS],
    routingParams: fetchDatasManager.getParams(
      DATA_TYPE_FICHE_ETABLISSEMENTS,
      state.routing.params
    ),
    annee: state.routing.params[PARAM_NAME_ANNEE],
    selectorReady: state.mainSelector.ready,
    datas: state.ficheEtablissements, 
    isModeBenchmark:
      state.selector[SELECTOR_TYPE_ETS_A].size > 0 &&
      state.selector[SELECTOR_TYPE_ETS_B].size > 0,
  };

  useEffect(() => {
    const fetchDatas = (props) => {
      if (props.visible && props.selectorReady) {
        const newParams = fetchDatasManager.getParams(
          DATA_TYPE_FICHE_ETABLISSEMENTS,
          props.routingParams
        );
        if (fetchDatasManager.hasDifferences(props.fetchData, newParams)) {
          dispatch(
            fetch(newParams, DATA_TYPE_FICHE_ETABLISSEMENTS, "/fiche/etablissements")
          );
        }
      }
    };
    
    fetchDatas({visible, selectorReady, fetchData, routingParams});
  }, [visible, selectorReady, fetchData, routingParams, dispatch]);

  const getFicheTitle = () => {
    return {
      subtitle: "navigation.etablissement",
    };
  };

  if (false === visible) {
    return null;
  }

  if (fetchData.pending || null === datas.selection) {
    return <HelixLoader />;
  }

  return (
    <div>
      <FicheSummary
        titleKeys={getFicheTitle()}
        selection={datas.selection[annee]}
      />

      <div
        id="list-etablissements-resete-filter"
        className={cn({ benchmark: isModeBenchmark })}
      >
        <div className="btn filter-btn" onClick={handleClick}>
          <span
          className="icon icon-delete" />
          <Trans k="fiche.etablissements.reseteFilter" className="lib" />
        </div>
      </div>

      <div className="fiche-container">
        <FicheEtablissementsListContainer
          toggleReset={toggleResetValue}
          {...datas}
        />
      </div>
    </div>
  );
};

EtablissementsPageContent.propTypes = {
  visible: PropTypes.bool,
  fetchData: PropTypes.object,
  routingParams: PropTypes.object,
  annee: PropTypes.string,
  dataType: PropTypes.string,
  selectorReady: PropTypes.bool,
  datas: PropTypes.object,
  isModeBenchmark: PropTypes.bool,
};

export default EtablissementsPageContent;
