import React from 'react';
import {connect} from 'react-redux'

import UserDropdown from 'components/molecules/UserDropdown/UserDropdown'
import SwitchAnnee from 'components/molecules/TopNavActions/AnneeSwitch'
import SwitchEffectifValo from 'components/molecules/TopNavActions/EffectifValoSwitch'
import ExportCsvButton from 'components/molecules/TopNavActions/ExportCsvButton'
import SwitchZone from 'components/molecules/TopNavActions/SwitchZone';


class TopNav extends React.Component {
    render() {
        return (
            <div id="top-nav">
                <div className="filters">

                    <SwitchAnnee />
                    <SwitchZone />
                    <SwitchEffectifValo />

                    <ExportCsvButton />

                </div>

                <UserDropdown />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);