/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as L from "leaflet";
import {
  MapContainer,
  ZoomControl,
  TileLayer,
  FeatureGroup,
  Marker,
  CircleMarker,
  useMap,
} from "react-leaflet";
import cn from "classnames";
import persistentData from "managers/persistentData";
import Legende from "components/molecules/CarteConcurrence/Legende";

import getDivIconOptions from "managers/markerIconManager";
import { getHaloSize } from "managers/concurrenceManager";

import {
  INITIAL_LAT,
  INITIAL_LNG,
  INITIAL_ZOOM,
  BASE_MAP_URL,
  ATTRIBUTION,
} from "constants/carteConfig";

import {
  setConcurenceBornesPdm,
  setConcurenceVisibleMarkers,
  toggleActiveEtablissement,
} from "actions/carteActions";
import {
  PARAM_NAME_ANNEE,
  PARAM_NAME_DATA_TYPE,
  PARAM_NAME_PDM,
} from "constants/router";

const MapComponent = ({ onMount, onMoveEnd }) => {
  const map = useMap();

  useEffect(() => {
    onMount(map);
  }, [map, onMount]);

  useEffect(() => {
    map.on("moveend", onMoveEnd);
    return () => {
      map.off("moveend", onMoveEnd);
    };
  }, [map, onMoveEnd]);

  return null;
};

const CarteConcurrence = (props) => {
  const dispatch = useDispatch();
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = useState(null);

  const etabs = useSelector((state) => state.carteConcurrence.datas);
  const geoPdm = useSelector((state) => state.routing.params[PARAM_NAME_PDM]);
  const annee = useSelector((state) => state.routing.params[PARAM_NAME_ANNEE]);
  const dataType = useSelector(
    (state) => state.routing.params[PARAM_NAME_DATA_TYPE]
  );
  const visibleMarkers = useSelector(
    (state) => state.carteConcurrence.visibleMarkers
  );
  const selectedMarker = useSelector((state) => state.carteInfos.idEts);
  const pdmMax = useSelector((state) => state.carteConcurrence.bornesPdm.max);
  const maxZoomSliceMarkers = 15;

  useEffect(() => {
    initMarkers();
  }, [etabs, annee, dataType, geoPdm]);

  useEffect(() => {
    filterMarkers();
  }, [geoPdm, annee, dataType, markers]);

  useEffect(() => {
    return () => {
      dispatch(toggleActiveEtablissement(false, null));
    };
  }, []);

  const handleMapMount = useCallback((map) => {
    setMap(map);
    centerMap(map);
  }, []);

  const initMarkers = () => {
    const catSlugs = {
      Privé: "type-private",
      CLCC: "type-clcc",
      CHU: "type-chu",
      ESPIC: "type-espic",
    };

    const values = [];
    const newMarkers = Object.values(etabs).map((markerValues) => {
      values.push(getMarkerValue(markerValues));
      const markerInfos = persistentData.get("etablissements")[markerValues.id];
      return {
        ...markerValues,
        ...markerInfos,
        position: { lat: markerInfos.lat, lng: markerInfos.lng },
        catSlug: catSlugs[markerInfos.cat],
      };
    });

    const bornes = {
      min: Math.min(...values),
      max: Math.max(...values),
    };
    dispatch(setConcurenceBornesPdm(bornes));

    setMarkers(newMarkers); 
  };

  const getMarkerValue = (marker) => {
    return marker["pdm_" + geoPdm][annee][dataType];
  };

  const filterMarkers = useCallback(() => {
    if (!map) return;

    const mapBounds = map.getBounds();

    setMarkers((currentMarkers) => {
      const filteredMarkers = currentMarkers
        .filter((marker) => mapBounds.contains(marker.position))
        .sort((a, b) => {
          return getMarkerValue(b) - getMarkerValue(a);
        });

      let finalMarkers = filteredMarkers;
      if (map.getZoom() < maxZoomSliceMarkers) {
        finalMarkers = filteredMarkers.slice(0, 5);
      }

      dispatch(setConcurenceVisibleMarkers(finalMarkers));

      return currentMarkers;
    });
  }, [geoPdm, annee, dataType, map]);

  const handleMapMoveEnd = useCallback(() => {
    filterMarkers();
  }, [filterMarkers]);

  const centerMap = () => {
    const bounds = markers
      .filter((marker) => marker.bench !== null)
      .map((marker) => marker.position);

    if (bounds.length !== 0 && map !== null) {
      map.fitBounds(bounds);
    }
  };

  const onMarkerClick = (marker) => {
    dispatch(toggleActiveEtablissement(marker.id, marker.bench));
  };

  const renderMarkersLayer = () => {
    if (visibleMarkers === null) {
      return null;
    }

    return (
      <FeatureGroup>
        {visibleMarkers.map((marker) => {
          const size = getHaloSize(getMarkerValue(marker), pdmMax);
          return (
            <CircleMarker
              key={marker.id}
              className={cn("halo", marker.catSlug)}
              interactive={false}
              center={[marker.lat, marker.lng]}
              radius={size / 2}
              stroke={false}
              fillOpacity={0.5}
            />
          );
        })}
        {visibleMarkers.map((marker) => {
          const isSelected = marker.id === selectedMarker;
          return (
            <Marker
              key={marker.id}
              position={[marker.lat, marker.lng]}
              icon={L.divIcon(getDivIconOptions(isSelected, marker.bench))}
              bench={marker.bench}
              eventHandlers={{ click: () => onMarkerClick(marker) }}
            />
          );
        })}
      </FeatureGroup>
    );
  };

  const position = [INITIAL_LAT, INITIAL_LNG];

  return (
    <div className="carte carte-concurrence">
      <Legende />

      <MapContainer
        center={position}
        zoom={INITIAL_ZOOM}
        zoomControl={false}
        maxZoom={16}
      >
        <MapComponent
          onMount={handleMapMount}
          onMoveEnd={handleMapMoveEnd}
        />

        <ZoomControl position={"topright"} />

        <TileLayer url={BASE_MAP_URL} attribution={ATTRIBUTION} opacity={0.6} />

        {renderMarkersLayer()}
      </MapContainer>
    </div>
  );
};

export default CarteConcurrence;
