import {combineReducers} from 'redux';
import fetchDatasManager from '../managers/fetchDatasManager';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
    SELECTOR_TYPE_APPAREIL,
    SELECTOR_TYPE_PRISE_EN_CHARGE,
    SELECTOR_TYPE_GHM,
    SELECTOR_TYPE_AGE
} from 'constants/selectorTypes';

import mainSelector from 'reducers/selector/mainSelectorReducer';
import selector from 'reducers/selector/selectorReducer';
import carteGrid from 'reducers/carte/carteGridReducer';
import carteTiles from 'reducers/carte/carteTilesReducer';
import carteInfos from 'reducers/carte/carteInfosReducer';
import offreSoins from 'reducers/carte/offreSoinsReducer';
import ficheEtablissements from 'reducers/fiche/ficheEtablissementsReducer';
import ficheAppareils from 'reducers/fiche/ficheAppareilsReducer';
import ficheGhm from 'reducers/fiche/ficheGhmReducer';
import ficheGhmDetail from 'reducers/fiche/ficheGhmDetailReducer';
import ficheSexeAge from 'reducers/fiche/ficheSexeAgeReducer';
import fichePriseEnCharge from 'reducers/fiche/fichePriseEnChargeReducer'
import routerReducer from 'reducers/routerReducer';
import getFetchDatasReducer from 'reducers/fetchDatasReducer';
import carteConcurrenceReducer from 'reducers/carte/carteConcurrenceReducer'


const rootReducer = combineReducers({
    mainSelector: mainSelector,
    selector: combineReducers({
        [SELECTOR_TYPE_ETS_A]: selector.bind({type: SELECTOR_TYPE_ETS_A}),
        [SELECTOR_TYPE_ETS_B]: selector.bind({type: SELECTOR_TYPE_ETS_B}),
        [SELECTOR_TYPE_APPAREIL]: selector.bind({type: SELECTOR_TYPE_APPAREIL}),
        [SELECTOR_TYPE_PRISE_EN_CHARGE]: selector.bind({type: SELECTOR_TYPE_PRISE_EN_CHARGE}),
        [SELECTOR_TYPE_GHM]: selector.bind({type: SELECTOR_TYPE_GHM}),
        [SELECTOR_TYPE_AGE]: selector.bind({type: SELECTOR_TYPE_AGE}),
    }),
    carteGrid,
    carteTiles: combineReducers({
        [SELECTOR_TYPE_ETS_A]: carteTiles.bind({type: SELECTOR_TYPE_ETS_A}),
        [SELECTOR_TYPE_ETS_B]: carteTiles.bind({type: SELECTOR_TYPE_ETS_B})
    }),
    carteInfos,
    offreSoins,
    ficheEtablissements,
    ficheAppareils,
    ficheSexeAge,
    ficheGhm,
    ficheGhmDetail,
    fichePriseEnCharge,
    carteConcurrence: carteConcurrenceReducer,
    routing: routerReducer,
    fetchData: combineReducers(fetchDatasManager.getAllReducer(getFetchDatasReducer))
});

export default rootReducer;
