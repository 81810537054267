import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as L from "leaflet";
import { FeatureGroup, Marker, Polyline } from "react-leaflet";
import persistentData from "managers/persistentData";
import { PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE } from "constants/router";
import {
  SELECTION_NOT_ACTIVE,
  SELECTION_NOT_LOADED,
} from "constants/offreSoinsDisplayState";
import getDivIconOptions from "managers/markerIconManager";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes";
import { toggleActiveEtablissement } from "actions/carteActions";


const Markers = ({ hidden }) => {
  const dispatch = useDispatch();
  const {
    offreSoinsReady,
    offreSoinsTop,
    offreSoinsDatas,
    offreSoinsGeoJSON,
    selectionCodes,
    selectorReady,
    dataType,
    annee,
    idEts,
    selector
  } = useSelector((state) => ({
    offreSoinsReady:
      null !== state.offreSoins.datas &&
      !state.offreSoins.dataPending &&
      state.offreSoins.displayState !== SELECTION_NOT_ACTIVE &&
      state.offreSoins.displayState !== SELECTION_NOT_LOADED,
    offreSoinsTop: state.offreSoins.top,
    offreSoinsDatas: state.offreSoins.datas,
    offreSoinsGeoJSON: state.offreSoins.geojson,
    selectionCodes: state.offreSoins.selectionCodes,
    selectorReady: state.mainSelector.ready,
    dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    annee: state.routing.params[PARAM_NAME_ANNEE],
    idEts: state.carteInfos.idEts,
    selector: state.selector
  }));
  
    let markers = {};
  
    const onMarkerClick = (e) => {
      dispatch(
        toggleActiveEtablissement(e.target.options.id, e.target.options.bench)
      );
    };

  const getOffreSoinsMarkers = () => {
    const etablissements = persistentData.get("etablissements");
    offreSoinsTop[annee][dataType].map(
      ({ id, value }, i) => {
        if (
          !markers.hasOwnProperty(id) &&
          etablissements.hasOwnProperty(id)
        ) {
          markers[id] = {
            value,
            bench: offreSoinsDatas[id].bench,
            number: i + 1,
            selected: false,
            position: [
              parseFloat(etablissements[id].lat),
              parseFloat(etablissements[id].lng),
            ],
          };
        }
        return null;
      }
    );

    return markers;
  };

  const getSelectionMarkers = (benchType) => {
    const etablissements = persistentData.get("etablissements");
    selector[benchType].map((id) => {
      if (
        !markers.hasOwnProperty(id) &&
        etablissements.hasOwnProperty(id)
      ) {
        markers[id] = {
          value: null,
          bench: benchType,
          number: null,
          selected: false,
          position: [etablissements[id].lat, etablissements[id].lng],
        };
      }
      return null;
    }
    );

    return markers;
  };

  const renderEtablissementMarkers = () => {
    let MarkersComp = [];
    if (offreSoinsReady) {
      getOffreSoinsMarkers();
    }
    getSelectionMarkers(SELECTOR_TYPE_ETS_A);
    getSelectionMarkers(SELECTOR_TYPE_ETS_B);
    for (let id in markers) {
      if (markers.hasOwnProperty(id)) {
        let isSelected = false;
        if (parseInt(id) === idEts) {
          isSelected = true;
        }
        MarkersComp.push(
          <Marker
            position={markers[id].position}
            key={id}
            icon={L.divIcon(
              getDivIconOptions(
                isSelected,
                markers[id].bench,
                markers[id].number
              )
            )}
            bench={markers[id].bench}
            id={parseInt(id)}
            eventHandlers={{ click: onMarkerClick}}
          />
        );
      }
    }
  
    return MarkersComp;
  };

  const renderPolylines = () => {
    if (!offreSoinsReady) {
      return null;
    }
    let PolylinesComp = [];
    let maxPopGeoJSON = null;
    offreSoinsGeoJSON.map((item, code) => {
      if (
        (null === maxPopGeoJSON ||
          item.population > maxPopGeoJSON.population) &&
        selectionCodes.has(code)
      ) {
        maxPopGeoJSON = item;
      }
      return null;
    });

    for (let id in markers) {
      if (markers.hasOwnProperty(id) && null !== markers[id].number) {
        PolylinesComp.push(
          <Polyline
            key={maxPopGeoJSON.id + "_" + id}
            className="offre-soins-line"
            positions={[maxPopGeoJSON.center, markers[id].position]}
            stroke={false}
          />
        );
      }
    }

    return PolylinesComp;
  };

  if (offreSoinsReady) {
    getOffreSoinsMarkers();
  }

  return (hidden === false || selectorReady) && (
    <FeatureGroup className="offre-soins-markers">
      {renderEtablissementMarkers()}
      {renderPolylines()}
    </FeatureGroup>
  )
};

Markers.propTypes = {
  hidden: PropTypes.bool,
};

export default Markers;
