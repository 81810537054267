import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";


import SelectorActions from "components/molecules/Selector/SelectorActions";
import SelectorMapContainer from "components/organisms/SelectorPageContent/SelectorMapContainer";
import persistentData from "managers/persistentData";
import Immutable from "immutable";

class SelectorPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.tree = null;
  }

  UNSAFE_componentWillMount() {
    this.getTreeData(this.props);
  }

  getTreeData(props) {
    let datas = persistentData.get("etablissements");
    let toArray = Object.values(datas);
    this.tree = Immutable.List(toArray);
  }

  render() {
    return (
      <div className="container-map">
        <SelectorMapContainer tree={this.tree} />
        <SelectorActions loadPage={this.props.loadPage} />
      </div>
    );
  }
}

SelectorPageContent.propTypes = {
  pageName: PropTypes.string,
  loadPage: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    pageName: state.routing.currentRouteName,
    loadPage: state.routing.loadPage,
    mainSelector: state.mainSelector.regionsType,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectorPageContent);
