import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import {connect} from 'react-redux'
import _debounce from 'lodash/debounce'

import {search} from 'actions/selectorActions';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
} from 'constants/selectorTypes.js';

import {
    PAGE_NAME_SELECTEUR_ETABLISSEMENT,
} from 'constants/router.js'

import SelectorAddAllBtn from 'components/molecules/Selector/SelectorAddAllBtn'

class SelectorSearch extends PureComponent {

    constructor(props) {
        super(props)
        this.searchUpdated = _debounce(this.searchUpdated, 800)
        this.debounceSearch = this.debounceSearch.bind(this)

        this.state = {
            value: props.inputValue
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (null === nextProps.inputTerm) {
            this.setState({value: ''})
        }
    }

    searchUpdated(term) {
        this.props.search(term);
    }

    debounceSearch(event) {
        this.setState({value: event.target.value})
        this.searchUpdated(event.target.value)
    }

    deleteSearch() {
        this.setState({value: ''})
        this.props.search('');
    }

    renderDeleteSearch() {
        if (null !== this.props.inputTerm) {
            return (
                <div className="delete-search-btn" onClick={this.deleteSearch.bind(this)}>
                    <div className="icon icon-plus"/>
                </div>
            )
        }
        return null
    }

    renderAddAllBtn() {
        if (null !== this.props.inputTerm && PAGE_NAME_SELECTEUR_ETABLISSEMENT === this.props.currentRouteName) {
            const customIcon = {
                [SELECTOR_TYPE_ETS_A]: 'A',
                [SELECTOR_TYPE_ETS_B]: 'B',
            }
            return (
                <div className="benchmark-btn">
                    {this.props.selectorType.map(selectorType => {
                        return (
                            <SelectorAddAllBtn key={selectorType} selectorType={selectorType}
                                               data={this.props.data}
                                               customIcon={customIcon[selectorType]}/>
                        )
                    })}
                </div>
            )
        }

        return null
    }

    render() {
        return (
            <div className="selector-search">
                <div className="icon icon-search"/>
                <div className="input-search">
                    <input
                        type="text"
                        value={this.state.value}
                        placeholder="Rechercher"
                        onChange={this.debounceSearch}
                    />
                </div>
                {this.renderDeleteSearch()}
                {this.renderAddAllBtn()}
            </div>
        )

    }
}

SelectorSearch.propTypes = {
    selectorType: PropTypes.any.isRequired,
    data: PropTypes.object.isRequired,
    search: PropTypes.func,
    inputTerm: PropTypes.string,
    inputValue: PropTypes.string,
    currentRouteName: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        inputTerm: state.mainSelector.inputTerm,
        inputValue: state.mainSelector.inputValue,
        currentRouteName: state.routing.currentRouteName,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        search(searchInput){
            dispatch(search(searchInput));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorSearch);
