import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import * as L from 'leaflet';
import 'vendors/LeafletUtfgrid/leaflet.utfgrid';

const UTFGridLayer = (props) => {
    const map = useMap();

    useEffect(() => {
        const utfGrid = new L.UtfGrid(props.url, {
            useJsonP: false,
        });
        initEventListeners(utfGrid);

        map.addLayer(utfGrid);

        return () => {
            map.removeLayer(utfGrid);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, props]);

    const initEventListeners = (utfGrid) => {
        props.onOverHandler &&
        utfGrid.on('mouseover', (data) => {
            props.onOverHandler('enter', data.data);
        });
        props.onOutHandler &&
        utfGrid.on('mouseout', (data) => {
            props.onOutHandler('leave', data.data);
        });
        props.onClickHandler &&
        utfGrid.on('click', (data) => {
            props.onClickHandler(data.data);
        });
        props.onError &&
        utfGrid.on('tileerror', (e) => {
            props.onError(e);
        });
    };

    return null;
};

export default UTFGridLayer;