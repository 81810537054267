import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import cn from 'classnames'

import {PARAM_NAME_DATA_TYPE} from 'constants/router'
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';

import FormattedDataType from "components/molecules/Format/FormattedDataType";
import Trans from "components/atoms/Format/Trans";

class FicheSummary extends Component {


    renderBenchValue(bench) {
        let displayValue = null;
        if (undefined !== this.props.selection) {
            displayValue = 0;
            if (this.props.selection[bench] &&
                this.props.selection[bench][this.props.dataType]) {
                displayValue = this.props.selection[bench][this.props.dataType]
            }
        }

        return (
            <div className={"bench-" + bench}>
                <div className="letter" />
                {null !== displayValue
                    ? <FormattedDataType value={displayValue} withLib={true} className="value"/>
                    : null}
            </div>
        )
    }

    render() {
        const etsAVisible = this.props[SELECTOR_TYPE_ETS_A].size > 0
        const etsBVisible = this.props[SELECTOR_TYPE_ETS_B].size > 0
        const isModeBenchmark = etsAVisible && etsBVisible;

        return (
            <div className={cn('fiche-summary', 'center-container', {benchmark: isModeBenchmark})}>
                <div className="titles">
                    {Object.keys(this.props.titleKeys).map(className => {
                        return <Trans k={this.props.titleKeys[className]} key={className} className={className}/>
                    })}
                </div>
                {etsAVisible && this.renderBenchValue(SELECTOR_TYPE_ETS_A)}
                {etsBVisible && this.renderBenchValue(SELECTOR_TYPE_ETS_B)}
            </div>
        )
    }
}


FicheSummary.propTypes = {
    titleKeys: PropTypes.object.isRequired,
    selection: PropTypes.object,
    dataType: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        [SELECTOR_TYPE_ETS_A]: state.selector[SELECTOR_TYPE_ETS_A],
        [SELECTOR_TYPE_ETS_B]: state.selector[SELECTOR_TYPE_ETS_B],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(FicheSummary);