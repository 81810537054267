import { combineReducers } from "redux";
import isEqual from "lodash/isEqual";
import {
  DATA_TYPE_ATTRACTIVITE_GRID,
  DATA_TYPE_ATTRACTIVITE_TILES,
  DATA_TYPE_CARTE_INFOS_FRANCE,
  DATA_TYPE_FICHE_ETABLISSEMENTS,
  DATA_TYPE_FICHE_APPAREILS,
  DATA_TYPE_FICHE_SEXE_AGE,
  DATA_TYPE_FICHE_GHM,
  DATA_TYPE_FICHE_PRISE_EN_CHARGE,
  DATA_TYPE_CARTE_CONCURRENCE,
} from "constants/dataType";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes";
import {
  PARAM_NAME_HASH,
  PARAM_NAME_ANNEE,
  PARAM_NAME_DATA_TYPE,
  PARAM_NAME_GEO_TYPE,
  PARAM_NAME_ZONE,
  PARAM_NAME_ACCES_TIME,
} from "constants/router";

class FetchDatasManager {
  datas = {
    [DATA_TYPE_ATTRACTIVITE_GRID]: [
      PARAM_NAME_HASH,
      PARAM_NAME_DATA_TYPE,
      PARAM_NAME_GEO_TYPE,
      PARAM_NAME_ZONE,
      PARAM_NAME_ACCES_TIME,
    ],
    [DATA_TYPE_ATTRACTIVITE_TILES]: {
      [SELECTOR_TYPE_ETS_A]: [
        PARAM_NAME_HASH,
        PARAM_NAME_ANNEE,
        PARAM_NAME_DATA_TYPE,
        PARAM_NAME_GEO_TYPE,
        PARAM_NAME_ZONE,
        PARAM_NAME_ACCES_TIME,
      ],
      [SELECTOR_TYPE_ETS_B]: [
        PARAM_NAME_HASH,
        PARAM_NAME_ANNEE,
        PARAM_NAME_DATA_TYPE,
        PARAM_NAME_GEO_TYPE,
        PARAM_NAME_ZONE,
        PARAM_NAME_ACCES_TIME,
      ],
    },
    [DATA_TYPE_CARTE_INFOS_FRANCE]: [
      PARAM_NAME_HASH,
      PARAM_NAME_DATA_TYPE,
      PARAM_NAME_GEO_TYPE,
      PARAM_NAME_ZONE,
      PARAM_NAME_ACCES_TIME,
    ],
    [DATA_TYPE_FICHE_ETABLISSEMENTS]: [PARAM_NAME_HASH],
    [DATA_TYPE_FICHE_APPAREILS]: [PARAM_NAME_HASH, PARAM_NAME_ANNEE],
    [DATA_TYPE_FICHE_SEXE_AGE]: [PARAM_NAME_HASH, PARAM_NAME_ANNEE],
    [DATA_TYPE_FICHE_GHM]: [PARAM_NAME_HASH, PARAM_NAME_ANNEE],
    [DATA_TYPE_FICHE_PRISE_EN_CHARGE]: [PARAM_NAME_HASH, PARAM_NAME_ANNEE],
    [DATA_TYPE_CARTE_CONCURRENCE]: [PARAM_NAME_HASH],
  };

  getAllReducer(reducerGetter, datas = null, dataTypes = []) {
    if (null == datas) {
      datas = this.datas;
    }
    let reducers = {};
    for (let dataType in datas) {
      if (datas.hasOwnProperty(dataType)) {
        const bindDataType = [...dataTypes, dataType];
        if (Array.isArray(datas[dataType])) {
          let initialParams = {};
          datas[dataType].forEach((param) => {
            initialParams[param] = null;
          });
          reducers[dataType] = reducerGetter(initialParams).bind({
            dataTypes: bindDataType,
          });
        } else {
          reducers[dataType] = combineReducers(
            this.getAllReducer(reducerGetter, datas[dataType], bindDataType)
          );
        }
      }
    }

    return reducers;
  }

  getParams(dataType, storeState, childrens = [], datas = null, params = {}) {
    if (null == datas) {
      datas = this.datas;
    }
    if (childrens.length > 0) {
      const childrenDataType = childrens.shift();
      params = this.getParams(
        childrenDataType,
        storeState,
        childrens,
        datas[dataType]
      );
    } else {
      datas[dataType].forEach((param) => {
        if (storeState.hasOwnProperty(param)) {
          params[param] = storeState[param];
        }
      });
    }

    return params;
  }

  hasDifferences(fetchDataState, compareParams) {
    const fetchDataParams = fetchDataState.params;
    let diff = false;
    for (let param in fetchDataParams) {
      if (
        fetchDataParams.hasOwnProperty(param) &&
        compareParams.hasOwnProperty(param) &&
        !isEqual(fetchDataParams[param], compareParams[param])
      ) {
        diff = true;
        break;
      }
    }

    return diff;
  }
}
const fetchDatasManager = new FetchDatasManager();

export default fetchDatasManager;
