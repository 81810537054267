import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux"

import {PAGE_NAME_CARTE_ATTRACTIVITE} from 'constants/router'

import CarteInfos from "components/molecules/CarteInfos/CarteInfos";
import AttractivitePageContent from 'components/organisms/CartePageContent/AttractivitePageContent';
import ConcurrencePageContent from 'components/organisms/CartePageContent/ConcurrencePageContent';

class CartePageContent extends React.Component {
    render(){
        let currentRoute = this.props.route
        return (
            <div className="carte-page-content">
                <CarteInfos />
                {
                    (currentRoute === PAGE_NAME_CARTE_ATTRACTIVITE) ?
                        <AttractivitePageContent />
                        :
                        <ConcurrencePageContent />
                }
            </div>
        );
    }
}

CartePageContent.propTypes = {
    route: PropTypes.string
};

function mapStateToProps(state) {
    return {
        route: state.routing.currentRouteName
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartePageContent);
