import {cleanString} from 'utils/strings';
import Immutable from 'immutable'

import {isLeaf} from 'utils/tree'

function getTopParent(item) {
    item.beingSearched = true
    if (item.parent === undefined) {
        return item
    } else {
        return getTopParent(item.parent)
    }
}

function addChildren(children, filteredList) {
    children.forEach(item => {
        item.beingSearched = true
        if (!isLeaf(item)) {
            addChildren(item.children)
        }
    })
    return filteredList
}

function addIfNotExist(item, filteredList) {
    if (!alreadyExistInList(item.id, filteredList)) {
        item.beingSearched = true
        filteredList.push(item)
    }
    return filteredList
}

function alreadyExistInList(id, filteredList) {
    return filteredList.some(item => {
        return (item.id === id)
    })
}

function recursiveSearch(list, term, filteredList) {
    list.forEach(item => {
        let itemTerms = item.lib
        if (undefined !== item.cat) itemTerms += item.cat
        if (undefined !== item.ville) itemTerms += item.ville
        if (undefined !== item.code) itemTerms += item.code

        const termIncluded = cleanString(itemTerms).includes(term)

        let skipChildren = false
        if (termIncluded) {
            item.beingSearched = true
            if (undefined !== item.parent) {
                const parent = getTopParent(item.parent)
                addIfNotExist(parent, filteredList)
            }
            else {
                filteredList = addIfNotExist(item, filteredList)
            }

            if (!isLeaf(item)) {
                addChildren(item.children, filteredList)
                skipChildren = true
            }
        }
        else {
            item.beingSearched = false
        }

        if (isLeaf(item)) {
            return filteredList
        }
        if (false === skipChildren) {
            return recursiveSearch(item.children, term, filteredList)
        }
    })

    return filteredList
}

export function searchFilter(list, inputTerm) {
    let filteredList = list

    if (inputTerm !== null) {
        const searchResult = recursiveSearch(list, cleanString(inputTerm), [])
        filteredList = Immutable.List(searchResult)
    }

    return filteredList
}
