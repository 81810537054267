import {Set} from 'immutable';

import selectionSavedManager from '../../managers/selectionSavedManager';
import {
    SELECTOR_LOAD_FULFILLED,
    SELECTOR_ADD_ITEMS,
    SELECTOR_REMOVE_ITEMS,
    SELECTOR_CLEAN,
    SELECTOR_CLEAN_ALL,
    SELECTOR_RESET_ALL,
    SELECTOR_SAVE_FULFILLED
} from 'constants/actionTypes';

export default function selectorReducer(state = new Set(), action) {
    if (SELECTOR_LOAD_FULFILLED === action.type || SELECTOR_SAVE_FULFILLED === action.type) {
        let selection = new Set();
        if (undefined !== action.payload.selection[this.type]) {
            selection = new Set(action.payload.selection[this.type]);
        }
        selectionSavedManager.save(selection, this.type);

        return selection;
    }
    if (SELECTOR_ADD_ITEMS === action.type && action.payload.type === this.type) {
        const ids = action.payload.ids;
        for (let i = 0, l = ids.length; i < l; i++) {
            if (!state.includes(ids[i])) {
                state = state.add(parseInt(ids[i]));
            }
        }

        return state;
    }
    if (SELECTOR_REMOVE_ITEMS === action.type && action.payload.type === this.type) {
        const ids = action.payload.ids;
        for (let i = 0, l = ids.length; i < l; i++) {
            state = state.delete(ids[i]);
        }

        return state;
    }
    if ((SELECTOR_CLEAN === action.type && action.payload.type === this.type)
        || SELECTOR_CLEAN_ALL === action.type) {
        return state.clear();
    }
    if (SELECTOR_RESET_ALL === action.type) {
        const savedSelection = selectionSavedManager.get(this.type);
        if (null === savedSelection) {
            return new Set();
        }

        return savedSelection;
    }

    return state;
}
