import React, {Component} from 'react'
import cn from 'classnames'
import Trans from 'components/atoms/Format/Trans'
import PropTypes from 'prop-types';

import TabsMenuItem from 'components/molecules/TabsMenu/TabsMenuItem'

export default class TabsMenu extends Component {
    render(){
        return (
            <div className={cn(this.props.className, 'tabs-menu')}>
                {this.props.tabs.map(tab => {
                    return (
                        <TabsMenuItem
                            key={tab.linkKey}
                            active={tab.isActive}
                            lib={<Trans k={tab.transKey} />}
                            onClickHandler={this.props.onItemClickHandler.bind(this, tab)}
                        />
                    )
                })}
            </div>
        );
    }
}

TabsMenu.propTypes = {
    tabs: PropTypes.array.isRequired,
    onItemClickHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
};
