import {DATAS_FULFILLED} from 'constants/actionTypes'
import {DATA_TYPE_FICHE_PRISE_EN_CHARGE} from 'constants/dataType.js'

const initialState = {
    selection: null,
    priseEnCharge: null
};

export default function fichePriseEnChargeReducer(state = initialState, action) {
    if (DATAS_FULFILLED === action.type && action.dataType === DATA_TYPE_FICHE_PRISE_EN_CHARGE) {
        return {
            ...state,
            selection: action.payload.selection,
            priseEnCharge: action.payload.priseEnCharge
        }
    }
    return state;
}
