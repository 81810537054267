// i18n.js
import i18next from 'i18next';
import translations from 'locales';

const initI18n = async () => {
    await i18next.init({
        debug: false,
        lng: 'fr',
        supportedLngs: ['fr'],
        load: 'languageOnly',
        fallbackLng: 'fr',
        resources: translations,
        compatibilityJSON: 'v3',
    });
    return i18next;
};

export default initI18n;
