import React, {Component} from 'react'
import PropTypes from 'prop-types';
import cn from 'classnames'
import SexeChart from "./SexeChart";

export default class SexesContainer extends Component {

    render() {
        return (
            <div className="sexes-container">
                <div className="decorative-trame" />
                <div className={cn('center-container', {benchmark: Object.keys(this.props.datas).length > 1})}>
                    {Object.keys(this.props.datas).map(bench => {
                        return <SexeChart key={bench} bench={bench} datas={this.props.datas[bench]} />
                    })}
                </div>
            </div>
        )
    }
}

SexesContainer.propTypes = {
    datas: PropTypes.object,
}