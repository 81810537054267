import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';

export default class Switch extends React.Component {
    getClassName() {
        return cn('switch', this.props.className, {
            'active' : this.props.active,
            'disabled': this.props.disabled
        });
    }
    onClick(){
        if(!this.props.disabled && this.props.clickHandler){
            this.props.clickHandler();
        }
    }
    render(){
        return (
            <div className={this.getClassName()} onClick={this.onClick.bind(this)}>
                <div className="lib lib-actif">
                    {this.props.libActif}
                </div>
                <div className="lib lib-inactif">
                    {this.props.libInactif}
                </div>
                <div className="switch-mobil"></div>
            </div>
        );
    }
}

Switch.defaultProps = {
    disabled: false
};

Switch.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    libActif: PropTypes.string,
    libInactif: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    clickHandler: PropTypes.func
};
