import React, {Component}  from 'react'
import FormattedNumberWithPlaceholder from 'components/atoms/Format/FormattedNumberWithPlaceholder'
import PropTypes from 'prop-types';

export default class Decimal extends Component {
    render() {
        return <FormattedNumberWithPlaceholder {...this.props} styleNumber="decimal" />
    }
}

Decimal.propTypes = {
    value: PropTypes.number.isRequired,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
};
Decimal.defaultProps = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};