import axios from "axios";
import persistentDatas from "../managers/persistentData";
import { PARAM_NAME_ACCES_TIME } from "constants/router";
import { formatAccessTimeParam } from "../managers/appRouter";
import {
  DATAS_PENDING,
  DATAS_FULFILLED,
  DATAS_REJECTED,
  CARTE_INFOS_SET_DATAS,
  CARTE_INFOS_SET_DEFAULT,
  CARTE_INFOS_SET_ETS_ID,
  CARTE_INFOS_SET_ETS_DATAS,
  CARTE_INFOS_CLEAR_ETS,
  CARTE_ATTRACTIVITE_LOAD_ERROR,
  CARTE_CONCURRENCE_SET_VISIBLE_MARKERS,
  CARTE_CONCURRENCE_SET_BRONES_PDM,
} from "constants/actionTypes";
import {
  DATA_TYPE_ATTRACTIVITE_GRID,
  DATA_TYPE_ATTRACTIVITE_TILES,
} from "constants/dataType";
import fetchDatasManager from "../managers/fetchDatasManager";
import {
  getFetchTokenConfig,
  getGridInteractivity,
  getGridCss,
  getTileCss,
} from "../managers/windshaftUtilities";
import { API_URL } from "constants/config";

export const loadGrid = (params, hasEtsA, hasEtasB) => {
  return async (dispatch, getState) => {
    // Initialiser le chargement des données
    dispatch({
      type: DATAS_PENDING,
      payload: { params },
      dataType: DATA_TYPE_ATTRACTIVITE_GRID,
    });

    try {
      const responses = await axios.post(
        API_URL + "/carte/grid",
        {
          ...params,
          [PARAM_NAME_ACCES_TIME]: formatAccessTimeParam(
            params[PARAM_NAME_ACCES_TIME]
          ),
        },
        { withCredentials: true }
      );

      if (
        fetchDatasManager.hasDifferences(
          getState().fetchData[DATA_TYPE_ATTRACTIVITE_GRID],
          params
        )
      ) {
        return;
      }

      if (responses.data.tileSrvResponse.data === undefined) {
        return;
      }

      const table = responses.data.tileSrvResponse.data[0].table,
        windshaftConfig = getFetchTokenConfig(
          table,
          getGridCss(),
          getGridInteractivity(
            hasEtsA,
            hasEtasB,
            persistentDatas.get("annees")
          ),
          params.geoType,
          "grid"
        );

      try {
        const tokenResponses = await axios.post(
          API_URL + "/carte/token",
          windshaftConfig,
          { withCredentials: true }
        );

        if (
          !fetchDatasManager.hasDifferences(
            getState().fetchData[DATA_TYPE_ATTRACTIVITE_GRID],
            params
          )
        ) {
          dispatch({
            type: DATAS_FULFILLED,
            payload: tokenResponses.data,
            dataType: DATA_TYPE_ATTRACTIVITE_GRID,
          });
        }
      } catch (error) {
        dispatch({
          type: DATAS_REJECTED,
          payload: error.response,
          dataType: DATA_TYPE_ATTRACTIVITE_GRID,
        });
      }
    } catch (error) {
      dispatch({
        type: DATAS_REJECTED,
        payload: error.response,
        dataType: DATA_TYPE_ATTRACTIVITE_GRID,
      });
    }
  };
};

export const loadTiles = (params, benchType) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DATAS_PENDING,
      payload: { params },
      dataType: [DATA_TYPE_ATTRACTIVITE_TILES, benchType],
    });

    try {
      const responses = await axios.post(
        API_URL + "/carte/tiles",
        {
          ...params,
          benchType,
          [PARAM_NAME_ACCES_TIME]: formatAccessTimeParam(
            params[PARAM_NAME_ACCES_TIME]
          ),
        },
        { withCredentials: true }
      );

      if (
        fetchDatasManager.hasDifferences(
          getState().fetchData[DATA_TYPE_ATTRACTIVITE_TILES],
          params
        )
      ) {
        return;
      }

      if (responses.data.tileSrvResponse.data === undefined) {
        return;
      }

      const table = responses.data.tileSrvResponse.data[0].table,
        classes = responses.data.classes,
        windshaftConfig = getFetchTokenConfig(
          table,
          getTileCss(benchType),
          [],
          params.geoType,
          "tile"
        );

      try {
        const tokenResponses = await axios.post(
          API_URL + "/carte/token",
          windshaftConfig,
          { withCredentials: true }
        );

        if (
          !fetchDatasManager.hasDifferences(
            getState().fetchData[DATA_TYPE_ATTRACTIVITE_TILES],
            params
          )
        ) {
          dispatch({
            type: DATAS_FULFILLED,
            payload: { data: tokenResponses.data, classes },
            dataType: [DATA_TYPE_ATTRACTIVITE_TILES, benchType],
          });
        }
      } catch (error) {
        dispatch({
          type: DATAS_REJECTED,
          payload: error.response,
          dataType: [DATA_TYPE_ATTRACTIVITE_TILES, benchType],
        });
      }
    } catch (error) {
      dispatch({
        type: DATAS_REJECTED,
        payload: error.response,
        dataType: [DATA_TYPE_ATTRACTIVITE_TILES, benchType],
      });
    }
  };
};

export const loadError = () => {
  return { type: CARTE_ATTRACTIVITE_LOAD_ERROR };
};

export const setCarteInfosDatas = (geoDatas) => {
  return function (dispatch, getState) {
    if (getState().carteInfos.geoDatas.code !== geoDatas.code) {
      dispatch({ type: CARTE_INFOS_SET_DATAS, payload: geoDatas });
    }
  };
};

export const setCarteInfosDefault = () => {
  return { type: CARTE_INFOS_SET_DEFAULT };
};

export const toggleActiveEtablissement = (idEts, benchType) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.carteInfos.idEts === idEts || idEts === false) {
      dispatch({ type: CARTE_INFOS_CLEAR_ETS });
    } else {
      dispatch({ type: CARTE_INFOS_SET_ETS_ID, payload: { id: idEts } });

      try {
        const responses = await axios.post(
          API_URL + "/carte/infosEtablissement",
          {
            hash: state.mainSelector.hash,
            idEts,
            benchType,
            zone: state.routing.params.zone,
            accesTime: formatAccessTimeParam(state.routing.params.accesTime),
          },
          { withCredentials: true }
        );

        if (
          responses.data.hash === state.mainSelector.hash &&
          responses.data.idEts === idEts
        ) {
          dispatch({
            type: CARTE_INFOS_SET_ETS_DATAS,
            payload: { idEts, datas: responses.data.datas },
          });
        }
      } catch (error) {
        dispatch({ type: CARTE_INFOS_CLEAR_ETS });
      }
    }
  };
};

export const setConcurenceVisibleMarkers = (markers) => {
  return { type: CARTE_CONCURRENCE_SET_VISIBLE_MARKERS, payload: markers };
};

export const setConcurenceBornesPdm = (bornes) => {
  return { type: CARTE_CONCURRENCE_SET_BRONES_PDM, payload: bornes };
};
