import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import fetchDatasManager from 'managers/fetchDatasManager';
import {fetch} from 'actions/dataActions';
import {
    DATA_TYPE_CARTE_INFOS_FRANCE
} from 'constants/dataType';
import {
    DATA_FILTER_TYPE_COMMUNE,
    DATA_FILTER_TYPE_DEP,
    DATA_FILTER_TYPE_REG_15,
    DATA_FILTER_TYPE_REG_16
} from 'constants/dataFilterType';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B
} from 'constants/selectorTypes';
import {CARTE_INFOS_TYPE_ETS} from 'constants/carteInfosType';

import CarteInfosSimple from 'components/molecules/CarteInfos/CarteInfosSimple';
import CarteInfosBenchmark from 'components/molecules/CarteInfos/CarteInfosBenchmark';
import CarteInfosEtablissement from "components/molecules/CarteInfos/CarteInfosEtablissement";

class CarteInfos extends React.Component {

    UNSAFE_componentWillMount() {
        this.fetchDatas(this.props);
    }

    UNSAFE_componentWillUpdate(nexProps) {
        this.fetchDatas(nexProps);
    }

    fetchDatas(props) {
        const newParams = fetchDatasManager.getParams(DATA_TYPE_CARTE_INFOS_FRANCE, props);
        if (fetchDatasManager.hasDifferences(props.fetchDataInfos, newParams)) {
            props.fetchInfos(newParams);
        }
    }

    getLibGeoTradKey() {
        let transKey = 'dataType.';
        switch (this.props.geoType) {
            case DATA_FILTER_TYPE_COMMUNE: {
                transKey += 'commune';
                break;
            }
            case DATA_FILTER_TYPE_DEP: {
                transKey += 'departement';
                break;
            }
            case DATA_FILTER_TYPE_REG_15: {
                transKey += 'ancienneRegion';
                break;
            }
            case DATA_FILTER_TYPE_REG_16: {
                transKey += 'nouvelleRegion';
                break;
            }
            default: break;
        }

        return transKey;
    }

    renderContent() {
        if (CARTE_INFOS_TYPE_ETS === this.props.infosType) {
            return <CarteInfosEtablissement/>;
        }
        if (this.props.hasEtsA && this.props.hasEtsB) {
            return <CarteInfosBenchmark libGeoTradKey={this.getLibGeoTradKey()}/>;
        }
        if (this.props.hasEtsA) {
            return (
                <CarteInfosSimple
                    benchType={SELECTOR_TYPE_ETS_A}
                    libGeoTradKey={this.getLibGeoTradKey()}
                />
            );
        }
        if (this.props.hasEtsB) {
            return (
                <CarteInfosSimple
                    benchType={SELECTOR_TYPE_ETS_B}
                    libGeoTradKey={this.getLibGeoTradKey()}
                />
            );
        }

        return null;
    }

    render() {
        if (!this.props.ready) {
            return <span>Loading...</span>;
        }
        return (
            <div className="carte-infos">
                {this.renderContent()}
            </div>
        );
    }
}


CarteInfos.propTypes = {
    fetchDataInfos: PropTypes.object,
    ready: PropTypes.bool,
    hasEtsA: PropTypes.bool,
    hasEtsB: PropTypes.bool,
    fetchInfos: PropTypes.func,
    infosType: PropTypes.string,
    geoType: PropTypes.string
};

function mapStateToProps(state) {
    return {
        fetchDataInfos: state.fetchData[DATA_TYPE_CARTE_INFOS_FRANCE],
        ready: !state.fetchData[DATA_TYPE_CARTE_INFOS_FRANCE].pending && null !== state.carteInfos.datasFrance,
        hasEtsA: state.selector[SELECTOR_TYPE_ETS_A].size > 0,
        hasEtsB: state.selector[SELECTOR_TYPE_ETS_B].size > 0,
        infosType: state.carteInfos.type,
        ...fetchDatasManager.getParams(DATA_TYPE_CARTE_INFOS_FRANCE, state.routing.params)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchInfos: (params) => {
            dispatch(fetch(params, DATA_TYPE_CARTE_INFOS_FRANCE, '/carte/infosFrance'));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarteInfos);