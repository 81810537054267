export const LOCATION_CHANGE = 'LOCATION_CHANGE';

export const SELECTOR_LOAD_PENDING = 'SELECTOR_LOAD_PENDING';
export const SELECTOR_LOAD_FULFILLED = 'SELECTOR_LOAD_FULFILLED';
export const SELECTOR_LOAD_REJECTED = 'SELECTOR_LOAD_REJECTED';
export const SELECTOR_SAVE_PENDING = 'SELECTOR_SAVE_PENDING';
export const SELECTOR_SAVE_FULFILLED = 'SELECTOR_SAVE_FULFILLED';
export const SELECTOR_SAVE_REJECTED = 'SELECTOR_SAVE_REJECTED';
export const SELECTOR_ADD_ITEMS = 'SELECTOR_ADD_ITEMS';
export const SELECTOR_REMOVE_ITEMS = 'SELECTOR_REMOVE_ITEMS';
export const SELECTOR_CLEAN = 'SELECTOR_CLEAN';
export const SELECTOR_CLEAN_ALL = 'SELECTOR_CLEAN_ALL';
export const SELECTOR_RESET_ALL = 'SELECTOR_RESET_ALL';
export const SELECTOR_SEARCH = 'SELECTOR_SEARCH';
export const SELECTOR_REGIONS_TYPE = 'SELECTOR_REGIONS_TYPE'
export const SELECTOR_SET_SELECTED_MARKER = 'SELECTOR_SET_SELECTED_MARKER'
export const SELECTOR_UNSELECT_MARKER = 'SELECTOR_UNSELECT_MARKER'

export const DATAS_PENDING = 'DATAS_PENDING';
export const DATAS_FULFILLED = 'DATAS_FULFILLED';
export const DATAS_REJECTED  = 'DATAS_REJECTED';

export const FICHE_GHM_DETAIL_SET_GHM_ID = 'FICHE_GHM_DETAIL_SET_GHM_ID';
export const FICHE_GHM_DETAIL_SET_GHM_DATAS = 'FICHE_GHM_DETAIL_SET_GHM_DATAS';

export const FICHE_ETABLISSEMENTS_PDM_SET_TYPE = 'FICHE_ETABLISSEMENTS_PDM_SET_TYPE';
export const FICHE_ETABLISSEMENTS_PDM_SET_DATAS = 'FICHE_ETABLISSEMENTS_PDM_SET_DATAS';

export const OFFRE_SOINS_ENABLE_SELECTION = 'OFFRE_SOINS_ENABLE_SELECTION';
export const OFFRE_SOINS_DISABLE_SELECTION = 'OFFRE_SOINS_DISABLE_SELECTION';
export const OFFRE_SOINS_SELECTION_ADD = 'OFFRE_SOINS_SELECTION_ADD';
export const OFFRE_SOINS_SELECTION_REMOVE = 'OFFRE_SOINS_SELECTION_REMOVE';
export const OFFRE_SOINS_GEOJSON_FULFILLED = 'OFFRE_SOINS_GEOJSON_FULFILLED';
export const OFFRE_SOINS_GEOJSON_REJECTED = 'OFFRE_SOINS_GEOJSON_REJECTED';
export const OFFRE_SOINS_PART_DISPLAYED = 'OFFRE_SOINS_PART_DISPLAYED';
export const OFFRE_SOINS_FULL_DISPLAYED = 'OFFRE_SOINS_FULL_DISPLAYED';
export const OFFRE_SOINS_SAVE_PENDING = 'OFFRE_SOINS_SAVE_PENDING';
export const OFFRE_SOINS_SAVE_FULFILLED = 'OFFRE_SOINS_SAVE_FULFILLED';
export const OFFRE_SOINS_SAVE_REJECTED = 'OFFRE_SOINS_SAVE_REJECTED';
export const OFFRE_SOINS_LOAD_PENDING = 'OFFRE_SOINS_LOAD_PENDING';
export const OFFRE_SOINS_LOAD_FULFILLED = 'OFFRE_SOINS_LOAD_FULFILLED';
export const OFFRE_SOINS_LOAD_REJECTED = 'OFFRE_SOINS_LOAD_REJECTED';
export const OFFRE_SOINS_CLEAR = 'OFFRE_SOINS_CLEAR';

export const CARTE_ATTRACTIVITE_LOAD_ERROR = 'CARTE_ATTRACTIVITE_LOAD_ERROR';

export const CARTE_INFOS_SET_DATAS = 'CARTE_INFOS_SET_DATAS';
export const CARTE_INFOS_SET_DEFAULT = 'CARTE_INFOS_SET_DEFAULT';
export const CARTE_INFOS_SET_ETS_ID = 'CARTE_INFOS_SET_ETS_ID';
export const CARTE_INFOS_SET_ETS_DATAS = 'CARTE_INFOS_SET_ETS_DATAS';
export const CARTE_INFOS_CLEAR_ETS = 'CARTE_INFOS_CLEAR_ETS';

export const CARTE_CONCURRENCE_SET_VISIBLE_MARKERS = 'CARTE_CONCURRENCE_SET_VISIBLE_MARKERS'
export const CARTE_CONCURRENCE_SET_BRONES_PDM = 'CARTE_CONCURRENCE_SET_BRONES_PDM'
