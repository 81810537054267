import React from "react";
import PropTypes from 'prop-types';

import {connect} from "react-redux"
import Percent from 'components/atoms/Format/Percent'
import cn from 'classnames'
import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE, PARAM_NAME_PDM} from "constants/router";

import {getHaloSize} from 'managers/concurrenceManager'

class MapLegendArc extends React.Component {

    renderPopup(size) {
        let popupDefaultWidth = 110

        if (null !== this.props.selectedMarker && this.props.marker.id === this.props.selectedMarker) {
            return (
                <div className={cn('legend-popup', this.props.marker.catSlug)}
                     style={{left: (size - (popupDefaultWidth / 2))}}>
                    <Percent value={this.getValue()}/>
                </div>
            )
        }

        return null
    }

    getValue() {
        return this.props.marker['pdm_' + this.props.geoPdm][this.props.annee][this.props.dataType]
    }

    render() {
        const value = this.getValue()

        const size = getHaloSize(value, this.props.pdmMax)

        let borderR = '0 0 ' + (size / 2) + 'px ' + (size / 2) + 'px'
        let style = {
            width: size,
            height: size / 2,
            borderRadius: borderR
        }
        return (
            <div key={this.props.marker.id}>
                <div className={cn('arc', this.props.marker.catSlug, {
                    selected: this.props.selectedMarker === this.props.marker.id
                })} style={style}/>
                {this.renderPopup(size)}
            </div>
        )
    }
}

MapLegendArc.propTypes = {
    marker: PropTypes.object.isRequired,
    annee: PropTypes.string,
    geoPdm: PropTypes.string,
    dataType: PropTypes.string,
    selectedMarker: PropTypes.number,
    pdmMax: PropTypes.number,
};

function mapStateToProps(state) {
    return {
        selectedMarker: state.carteInfos.idEts,
        pdmMax: state.carteConcurrence.bornesPdm.max,
        annee: state.routing.params[PARAM_NAME_ANNEE],
        geoPdm: state.routing.params[PARAM_NAME_PDM],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE]
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MapLegendArc);
