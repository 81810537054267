import React, {Component} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames'
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import PropTypes from 'prop-types';

import {isLeaf, getLeafCount} from '../../../utils/tree.js'
import {sortAlphaAsc} from '../../../utils/sort.js'

class SelectionInfoItem extends Component {


    getElements() {
        let elements = (undefined !== this.props.items.tree)
            ? this.getElementsFromTree(this.props.items.tree, [])
            : this.getElementsFromList()

        elements.sort(sortAlphaAsc)

        return elements
    }

    getElementsFromList() {
        const elements = []
        this.props.selector.forEach(id => {
            elements.push(this.props.items.persistDatas[id].lib)
        })
        return elements
    }

    getElementsFromTree(tree, elements) {
        tree.forEach((item) => {
            if (isLeaf(item)) {
                if (item.selected) {
                    elements.push(item.lib)
                }
            }
            else {
                if (0 !== item.nb_children_selected && item.nb_children_selected === getLeafCount(item.children)) {
                    elements.push(item.lib)
                }
                else {
                    elements = this.getElementsFromTree(item.children, elements)
                }
            }
        })
        return elements
    }

    renderElements() {
        const elements = this.getElements()
        let lenght = elements.length
        if (!this.props.active) {
            const item = elements.slice(0, 1)
            const reste = lenght - 1
            return (
                <div className="selector-info-item">
                    {this.props.prefix}
                    <div className="lib">{item}</div>
                    <div className={cn('reste', {hidden: 0 === reste})}>{'+ ' + reste}</div>
                </div>
            )
        }
        else {
            return elements.map((item, index) => {
                return (
                    <div key={item} className="selector-info-item">
                        {(0 === index) && this.props.prefix}
                        <div className="lib">{item}</div>
                    </div>
                )
            })
        }
    }

    render() {
        if (this.props.selector.isEmpty()) {
            return null;
        }

        return (
            <div className={cn('selector-info-group', this.props.type)}>
                <div className="separator"/>
                {this.renderElements()}
            </div>
        );
    }
}

SelectionInfoItem.defaultProps = {
    prefix: null,
}

SelectionInfoItem.propTypes = {
    items: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    prefix: PropTypes.element,
    selector: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        selector: state.selector[ownProps.type]
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionInfoItem);