import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux'
import {navigate} from 'actions/routerActions'
import {PARAM_NAME_DATA_TYPE, SECTION_NAME_FICHE, SECTION_NAME_CARTE} from 'constants/router'
import {DATA_FILTER_TYPE_SEJOUR, DATA_FILTER_TYPE_VALO} from 'constants/dataFilterType'
import TabsMenu from '../TabsMenu/TabsMenu'

class EffectifValoSwitch extends React.Component {
    onItemClickHandler(tab) {
        this.props.navigate(null, {[PARAM_NAME_DATA_TYPE]: tab.linkKey});
    }

    render() {
        if (
            SECTION_NAME_FICHE === this.props.routingSection ||
            SECTION_NAME_CARTE === this.props.routingSection
        ) {

            const tabs = [
                {
                    linkKey: DATA_FILTER_TYPE_SEJOUR,
                    transKey: 'dataType.sejours-seances_plural',
                    isActive: DATA_FILTER_TYPE_SEJOUR === this.props.dataType,
                },
                {
                    linkKey: DATA_FILTER_TYPE_VALO,
                    transKey: 'dataType.valorisation',
                    isActive: DATA_FILTER_TYPE_VALO === this.props.dataType,
                },
            ];

            return (
                <TabsMenu className="switch-data-type" onItemClickHandler={this.onItemClickHandler.bind(this)} tabs={tabs}/>
            )

        }

        return null
    }
}

EffectifValoSwitch.propTypes = {
    navigate: PropTypes.func,
    dataType: PropTypes.string,
    routingSection: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
        routingSection: state.routing.section
    }
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (pageName, params) => {
            dispatch(navigate(pageName, params));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EffectifValoSwitch);