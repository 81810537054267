import {
    DATAS_FULFILLED,
    FICHE_ETABLISSEMENTS_PDM_SET_TYPE,
    FICHE_ETABLISSEMENTS_PDM_SET_DATAS,
} from 'constants/actionTypes'
import {DATA_TYPE_FICHE_ETABLISSEMENTS} from 'constants/dataType.js'

const initialState = {
    typePdm: null,
    accesTime: null,
    selection: null,
    etablissements: null,
    datas: null,
    availablePdm: null,
};

export default function ficheEtablissementsReducer(state = initialState, action) {
    if (DATAS_FULFILLED === action.type && action.dataType === DATA_TYPE_FICHE_ETABLISSEMENTS) {
        return {
            ...state,
            typePdm: action.payload.typePdm,
            accesTime: action.payload.accesTime,
            selection: action.payload.selection,
            etablissements: action.payload.etablissements,
            datas: action.payload.datas,
            availablePdm: action.payload.availablePdm,
        }
    }

    if (FICHE_ETABLISSEMENTS_PDM_SET_TYPE === action.type) {
        return {
            ...state,
            typePdm: action.payload.typePdm,
            accesTime: action.payload.accesTime,
            datas: null
        };
    }
    if (FICHE_ETABLISSEMENTS_PDM_SET_DATAS === action.type) {
        return {
            ...state,
            datas: action.payload.datas
        };
    }

    return state;
}
