import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux'
import {navigate} from 'actions/routerActions'
import {PARAM_NAME_ANNEE, SECTION_NAME_FICHE, SECTION_NAME_CARTE} from 'constants/router'

import TabsMenu from '../TabsMenu/TabsMenu'
import persistentData from 'managers/persistentData'

class AnneeSwitch extends React.Component {
    onItemClickHandler(tab) {
        this.props.navigate(null, {[PARAM_NAME_ANNEE]: tab.linkKey});
    }

    render() {
        if (undefined !== this.props.annee &&
            (
                SECTION_NAME_FICHE === this.props.routingSection ||
                SECTION_NAME_CARTE === this.props.routingSection
            )) {

            let tabs = []
            Object.values(persistentData.get('annees')).reverse().forEach(annee => {
                tabs.push({
                    linkKey: annee.id,
                    transKey: annee.lib,
                    isActive: annee.id.toString() === this.props.annee,
                })
            })

            return (
                <TabsMenu className="switch-annee"  onItemClickHandler={this.onItemClickHandler.bind(this)} tabs={tabs}/>
            )

        }

        return null
    }
}

AnneeSwitch.propTypes = {
    navigate: PropTypes.func,
    annee: PropTypes.string,
    routingSection: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        annee: state.routing.params[PARAM_NAME_ANNEE],
        routingSection: state.routing.section
    }
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (pageName, params) => {
            dispatch(navigate(pageName, params));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnneeSwitch);