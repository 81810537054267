import axios from "axios";
import {
  OFFRE_SOINS_ENABLE_SELECTION,
  OFFRE_SOINS_DISABLE_SELECTION,
  OFFRE_SOINS_SELECTION_ADD,
  OFFRE_SOINS_SELECTION_REMOVE,
  OFFRE_SOINS_GEOJSON_FULFILLED,
  OFFRE_SOINS_GEOJSON_REJECTED,
  OFFRE_SOINS_SAVE_PENDING,
  OFFRE_SOINS_SAVE_FULFILLED,
  OFFRE_SOINS_SAVE_REJECTED,
  OFFRE_SOINS_LOAD_PENDING,
  OFFRE_SOINS_LOAD_FULFILLED,
  OFFRE_SOINS_LOAD_REJECTED,
  OFFRE_SOINS_FULL_DISPLAYED,
  OFFRE_SOINS_PART_DISPLAYED,
  OFFRE_SOINS_CLEAR,
} from "constants/actionTypes.js";
import { PART_DISPLAY, FULL_DISPLAY } from "constants/offreSoinsDisplayState";

import { formatAccessTimeParam } from "../managers/appRouter";
import { API_URL } from "constants/config";

export const selectionEnabledToggle = () => async (dispatch, getState) => {
  const actionType = getState().offreSoins.selectionActive
    ? OFFRE_SOINS_DISABLE_SELECTION
    : OFFRE_SOINS_ENABLE_SELECTION;
  dispatch({ type: actionType });
};

export const selectionToggle = (code) => async (dispatch, getState) => {
  const geoType = getState().routing.params.geoType;
  if (geoType) {
    let actionType = OFFRE_SOINS_SELECTION_REMOVE;
    if (
      getState().offreSoins.geoType !== geoType ||
      !getState().offreSoins.selectionCodes.includes(code)
    ) {
      actionType = OFFRE_SOINS_SELECTION_ADD;
    }
    dispatch({ type: actionType, payload: { geoType, code } });

    if (
      OFFRE_SOINS_SELECTION_ADD === actionType &&
      !getState().offreSoins.geojson.has(code)
    ) {
      try {
        const responses = await axios.post(
          API_URL + "/carte/geojson",
          {
            geoType,
            code,
          },
          { withCredentials: true }
        );
        if (
          getState().offreSoins.geoType === geoType &&
          getState().offreSoins.selectionCodes.includes(code) &&
          !getState().offreSoins.geojson.has(code)
        ) {
          dispatch({
            type: OFFRE_SOINS_GEOJSON_FULFILLED,
            payload: { code, data: responses.data },
          });
        }
      } catch (error) {
        let errorResponse = error.response;
        errorResponse.geoType = geoType;
        errorResponse.code = code;
        dispatch({
          type: OFFRE_SOINS_GEOJSON_REJECTED,
          payload: errorResponse,
        });
      }
    }
  }
};

export const getOffreSoins = () => async (dispatch, getState) => {
  const geoType = getState().routing.params.geoType;
  if (
    getState().offreSoins.geoType === geoType &&
    (null === getState().offreSoins.dataCodes ||
      !getState().offreSoins.selectionCodes.equals(
        getState().offreSoins.dataCodes
      ))
  ) {
    dispatch({ type: OFFRE_SOINS_SAVE_PENDING });
    const params = {
      hash: getState().mainSelector.hash,
      geoType: getState().offreSoins.geoType,
      codes: getState().offreSoins.selectionCodes.toArray(),
      zone: getState().routing.params.zone,
      accesTime: formatAccessTimeParam(getState().routing.params.accesTime),
    };
    try {
      const response = await axios.post(
        API_URL + "/offreSoins/saveSelection",
        params,
        { withCredentials: true }
      );
      if (
        null !== getState().offreSoins.dataCodes &&
        getState().offreSoins.selectionCodes.equals(
          getState().offreSoins.dataCodes
        )
      ) {
        dispatch({
          type: OFFRE_SOINS_SAVE_FULFILLED,
          payload: {
            selection: response.data.selection,
            datas: response.data.datas,
          },
        });
      }
    } catch (error) {
      let errorResponse = error.response;
      dispatch({ type: OFFRE_SOINS_SAVE_REJECTED, payload: errorResponse });
    }
  }
};

export const loadOffreSoins =
  (selectionHash, force = false) =>
  async (dispatch, getState) => {
    const geoType = getState().routing.params.geoType;
    if (getState().offreSoins.hash !== selectionHash || force) {
      dispatch({
        type: OFFRE_SOINS_LOAD_PENDING,
        payload: { hash: selectionHash, geoType },
      });
      const params = {
        hash: getState().mainSelector.hash,
        geoType,
        selectionHash,
        zone: getState().routing.params.zone,
        accesTime: formatAccessTimeParam(getState().routing.params.accesTime),
      };
      try {
        const response = await axios.post(
          API_URL + "/offreSoins/loadSelection",
          params,
          { withCredentials: true }
        );
        const { datas, geojson, selection } = response.data;
        if (
          getState().offreSoins.hash === selection.hash &&
          getState().offreSoins.geoType === selection.geoType &&
          getState().offreSoins.selectionActive
        ) {
          dispatch({
            type: OFFRE_SOINS_LOAD_FULFILLED,
            payload: { selection, geojson, datas },
          });
        }
      } catch (error) {
        let errorResponse = error.response;
        dispatch({ type: OFFRE_SOINS_LOAD_REJECTED, payload: errorResponse });
      }
    }
  };

export const clearOffreSoins = (all = true) => ({
  type: OFFRE_SOINS_CLEAR,
  payload: { all },
});

export const displayToggle = () => async (dispatch, getState) => {
  if (PART_DISPLAY === getState().offreSoins.displayState) {
    dispatch({ type: OFFRE_SOINS_FULL_DISPLAYED });
  } else if (FULL_DISPLAY === getState().offreSoins.displayState) {
    dispatch({ type: OFFRE_SOINS_PART_DISPLAYED });
  }
};
