import React  from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {navigate} from '../actions/routerActions';
import {load, cleanAll} from '../actions/selectorActions';
import {PARAM_VALUE_OFF, PAGE_NAME_FICHE_ETABLISSEMENTS} from 'constants/router';

class SelectorDispatcher extends React.Component {
    UNSAFE_componentWillMount() {
        if (PARAM_VALUE_OFF !== this.props.routingHash) {
            this.props.load(this.props.routingHash);
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        const oldRoutingHash = this.props.routingHash,
            newRoutingHash = nextProps.routingHash,
            oldSelectorHash = this.props.selectorHash,
            newSelectorHash = nextProps.selectorHash

        if (PARAM_VALUE_OFF !== newRoutingHash && oldRoutingHash !== newRoutingHash) {
            this.props.load(newRoutingHash);
        }
        if (PARAM_VALUE_OFF === newRoutingHash && PARAM_VALUE_OFF !== oldRoutingHash) {
            this.props.cleanAll();
        }
        if ((undefined === oldSelectorHash || null === oldSelectorHash) &&
            undefined !== newSelectorHash && null !== newSelectorHash &&
            !nextProps.selectorPending) {

            const page = (undefined === nextProps.loadPage || null === nextProps.loadPage)
                ? PAGE_NAME_FICHE_ETABLISSEMENTS
                : nextProps.loadPage
            this.props.navigate(page, {hash: newSelectorHash});
        }
    }

    render() {
        return null
    }
}

console.info(PropTypes);

SelectorDispatcher.propTypes = {
    routingHash: PropTypes.string,
    loadPage: PropTypes.string,
    selectorHash: PropTypes.string,
    selectorPending: PropTypes.bool,
    load: PropTypes.func,
    cleanAll: PropTypes.func,
    navigate: PropTypes.func
};

function mapStateToProps(state) {
    return {
        routingHash: state.routing.params.hash,
        loadPage: state.routing.loadPage,
        selectorHash: state.mainSelector.hash,
        selectorPending: state.mainSelector.pending,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        load: (hash) => {
            dispatch(load(hash));
        },
        cleanAll: () => {
            dispatch(cleanAll());
        },
        navigate: (pageName, params) => {
            dispatch(navigate(pageName, params));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorDispatcher);