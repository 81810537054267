import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux"
import cn from 'classnames'

import Trans from "components/atoms/Format/Trans";
import Percent from 'components/atoms/Format/Percent'
import MapLegendArc from 'components/atoms/MapLegendArc/MapLegendArc'

class Legende extends React.Component {

    renderArcs() {
        if (null !== this.props.visibleMarkers) {
            return this.props.visibleMarkers.map(marker => {
                return (<MapLegendArc key={marker.id} marker={marker}/>)
            })
        }
        return null
    }


    renderLegend() {
        const items = {
            'type-clcc': 'fiche.concurrence.legend.clcc',
            'type-chu': 'fiche.concurrence.legend.chu',
            'type-private': 'fiche.concurrence.legend.private',
            'type-espic': 'fiche.concurrence.legend.espic',
        }

        return (
            <div className="legend-blocks">
                {Object.keys(items).map(key => {
                    return (
                        <div key={key} className={cn('type-etab', key)}>
                            <div className="colored-circle"/>
                            <div className="lib">
                                <Trans k={items[key]}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        return (
            <div className="carte-legend carte-concurrence-legend">
                {this.renderLegend()}
                <div className={"legend-blocks"}>
                    <div className="pdm-txt">
                        <Trans k={"fiche.concurrence.legend.pdm"}/> :
                    </div>
                    <div className="pdm-percent">
                        <div className="pdm-arc">
                            {this.renderArcs()}
                        </div>

                        <div className="percent-block">
                            <div className="percent-number">0%</div>
                            <div className="percent-bar"/>
                        </div>
                        <div className="percent-block">
                            <div className="percent-number"/>
                            <div className="percent-bar"/>
                        </div>
                        <div className="percent-block">
                            <div className="percent-number"/>
                            <div className="percent-bar"/>
                        </div>
                        <div className="percent-block">
                            <div className="percent-number"/>
                            <div className="percent-bar"/>
                        </div>
                        <div className="percent-block">
                            <div className="percent-number"/>
                            <div className="percent-bar"/>
                        </div>
                        <div className="percent-block">
                            <div className="percent-number"/>
                            <div className="percent-bar"/>
                        </div>
                        <div className="percent-block">
                            <div className="percent-number">
                                <Percent value={(this.props.pdmMax) ? this.props.pdmMax : 1}/>
                            </div>
                            <div className="percent-bar"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Legende.propTypes = {
    visibleMarkers: PropTypes.array,
    etabs: PropTypes.object,
    pdmMax: PropTypes.number
};

function mapStateToProps(state) {
    return {
        visibleMarkers: state.carteConcurrence.visibleMarkers,
        etabs: state.carteConcurrence.datas,
        pdmMax: state.carteConcurrence.bornesPdm.max,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}



export default connect(mapStateToProps, mapDispatchToProps)(Legende);