import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';

import {loadOffreSoins} from 'actions/offreSoinsActions';

import 'react-virtualized/styles.css'
import Immutable from 'immutable'

import persistentData from 'managers/persistentData'

import {
    PARAM_NAME_ACCES_TIME, PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE, PARAM_NAME_GEO_TYPE,
    PARAM_NAME_ZONE
} from 'constants/router'

import ListEtablissements from 'components/molecules/FicheEtablissements/ListEtablissements'

class OffreSoinsListContainer extends ListEtablissements {


    UNSAFE_componentWillUpdate(nextProps) {

        if (((this.props.zone !== nextProps.zone) ||
                (this.props.accesTime !== nextProps.accesTime)) &&
            (false === this.props.dataPending && false === nextProps.dataPending)) {
            this.props.loadOffreSoins(this.props.selectionHash)
        }

        if ((this.props.annee !== nextProps.annee) ||
            (this.props.dataType !== nextProps.dataType) ||
            (true === this.props.dataPending && false === nextProps.dataPending)) {
            this.setEtablissementsList(null)
        }
    }


    initEtablissementsList() {
        let list = []
        for (let id_ets in this.props.etablissements) {
            if (this.props.etablissements.hasOwnProperty(id_ets)) {
                const etsValues = this.props.etablissements[id_ets]
                const etsInfos = persistentData.get('etablissements')[id_ets]

                const {bench, data, id, id_dep, id_reg15, id_reg16, pdm} = etsValues
                const {cat, code, lib, sec} = etsInfos

                let row = {
                    id, cat, code, lib, sec, bench,
                    id_dep, lib_dep: persistentData.get('departements')[id_dep].lib,
                    id_reg15, lib_reg15: persistentData.get('regions')[id_reg15].lib,
                    id_reg16, lib_reg16: persistentData.get('regions')[id_reg16].lib,
                }

                Object.keys(persistentData.get('annees')).forEach((id_annee) => {
                    row['value_' + id_annee] = data[id_annee][this.props.dataType]
                })

                row.pdm = pdm[this.props.annee][this.props.dataType]

                list.push(row)
            }
        }

        return Immutable.List(list);
    }


    getColumnsConf() {
        const columnSpacer = {
            ...this.getColumnDefault(),
            dataKey: 'columnSpacer',
            width: 0,
            disableSort: true,
            className: 'cell-spacer',
        }
        const columnRank = {
            ...this.getColumnDefault(),
            dataKey: 'rank',
            width: 26,
            flexGrow: 0,
            disableSort: true,
            className: 'cell-rank',
        }
        const columnLib = {
            ...this.getColumnDefault(),
            dataKey: 'lib',
            width: 150,
            flexGrow: 3,
            label: 'general.etablissement_plural',
            className: 'cell-etablissement',
            headerClassName: 'cell-etablissement',
        }
        const columnCaegorie = {
            ...this.getColumnDefault(),
            dataKey: 'cat',
            label: 'Type',
        }
        const columnPdm = {
            ...this.getColumnDefault(),
            dataKey: 'pdm',
            label: 'dataType.PdmSelection',
            cellRenderer: this.cellPdmRenderer.bind(this),
        }

        let columnsConf = [columnRank, columnLib, columnCaegorie, this.getColumnGeo()]
        columnsConf.push(columnSpacer)
        columnsConf = columnsConf.concat(this.getColumnsValue())
        columnsConf.push(columnSpacer)
        columnsConf.push(columnPdm)


        return columnsConf
    }

    render() {
        if (null === this.getEtablissementsList()) {
            this.setEtablissementsList(this.initEtablissementsList())
        }

        return (
            <div className="offre-soins-list-container center-container list-etablissements">
                {this.renderTable()}
            </div>
        )
    }
}


OffreSoinsListContainer.propTypes = {
    loadOffreSoins: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
        typeGeo: state.routing.params[PARAM_NAME_GEO_TYPE],
        dataPending: state.offreSoins.dataPending,
        etablissements: state.offreSoins.datas,
        selectionHash: state.offreSoins.hash,
        zone: state.routing.params[PARAM_NAME_ZONE],
        accesTime: state.routing.params[PARAM_NAME_ACCES_TIME],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadOffreSoins: (hash) => {
            dispatch(loadOffreSoins(hash, true));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffreSoinsListContainer);