import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';
import Trans from 'components/atoms/Format/Trans';
import Percent from 'components/atoms/Format/Percent';

export default class CarteInfosBenchmarkEvolution extends React.Component {
    render(){
        return (
            <div className={cn('carte-infos-benchmark-evolution', 'align-' + this.props.align, 'bench-' + this.props.benchType, {active: this.props.active})}>
                <Trans k="dataType.evolution" /> {this.props.lib} : <Percent value={this.props.evolution} isEvolution={true} />
            </div>
        );
    }
}
CarteInfosBenchmarkEvolution.defaultProps = {
    active: false
};

CarteInfosBenchmarkEvolution.propTypes = {
    align: PropTypes.string.isRequired,
    benchType: PropTypes.string.isRequired,
    evolution: PropTypes.number.isRequired,
    lib: PropTypes.string.isRequired,
    active: PropTypes.bool
};