import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux'

import {
    PAGE_NAME_FICHE_ETABLISSEMENTS,
    PAGE_NAME_FICHE_APPAREIL,
    PAGE_NAME_FICHE_PRISE_EN_CHARGE,
    PAGE_NAME_FICHE_GHM,
    PAGE_NAME_FICHE_SEXE_AGE
} from 'constants/router'

import EtablissementsPageContent from "components/organisms/FichePageContent/EtablissementsPageContent";
import AppareilsPageContent from 'components/organisms/FichePageContent/AppareilsPageContent'
import SexeAgePageContent from "components/organisms/FichePageContent/SexeAgePageContent";
import GhmPageContent from "components/organisms/FichePageContent/GhmPageContent";

class FichePageContent extends React.Component {
    render() {
        switch (this.props.pageName) {
            case PAGE_NAME_FICHE_ETABLISSEMENTS:
                return <EtablissementsPageContent />
            case PAGE_NAME_FICHE_APPAREIL:
                return <AppareilsPageContent />
            case PAGE_NAME_FICHE_PRISE_EN_CHARGE:
                return <AppareilsPageContent />
            case PAGE_NAME_FICHE_GHM:
                return <GhmPageContent />
            case PAGE_NAME_FICHE_SEXE_AGE:
                return <SexeAgePageContent />
            default: break;
        }
    }
}


FichePageContent.propTypes = {
    pageName: PropTypes.string
}

function mapStateToProps(state) {
    return {
        pageName: state.routing.currentRouteName,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(FichePageContent);