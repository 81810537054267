import React, {Component}  from 'react'
import {FormattedNumber} from 'react-intl'
import Trans from "./Trans";
import PropTypes from 'prop-types';

export default class FormattedNumberWithPlaceholder extends Component {
    render() {
        if (null === this.props.value) {
            return (
                <span className="placeholder">...</span>
            )
        }

        if (isNaN(this.props.value)) {
            return (
                <Trans k={this.props.value} className="error"/>
            )
        }

        return (
            <FormattedNumber {...this.props} style={this.props.styleNumber} />
        )
    }
}

FormattedNumberWithPlaceholder.propTypes = {
    value: PropTypes.number.isRequired,
    style: PropTypes.string.isRequired,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
};