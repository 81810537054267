/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import {
  loadError,
  loadTiles,
  toggleActiveEtablissement,
} from "actions/carteActions";
import debounce from "lodash/debounce";

import fetchDatasManager from "managers/fetchDatasManager";
import {
  DATA_TYPE_ATTRACTIVITE_TILES,
} from "constants/dataType";
import {
  INITIAL_LAT,
  INITIAL_LNG,
  INITIAL_ZOOM,
  BASE_MAP_URL,
  ATTRIBUTION,
  MAX_TILE_LOADING_TRY,
} from "constants/carteConfig";
import persistentData from "managers/persistentData";

import UTFGridLayer from "components/molecules/CarteAttractivite/UTFGridLayer";
import CarteSelectionLayer from "components/molecules/CarteAttractivite/CarteSelectionLayer";
import Legende from "components/molecules/CarteAttractivite/Legende";
import Markers from "components/molecules/CarteAttractivite/Markers";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes";
import {
  DATA_FILTER_TYPE_COMMUNE,
  DATA_FILTER_TYPE_DEP,
  DATA_FILTER_TYPE_REG_15,
  DATA_FILTER_TYPE_REG_16,
} from "constants/dataFilterType";

const CarteAttractivite = (props) => {
  const dispatch = useDispatch();
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [eventAdded, setEventAdded] = useState(false);
  const [retryNumber, setRetryNumber] = useState(MAX_TILE_LOADING_TRY);
  const [alertDisplayed, setAlertDisplayed] = useState(false);
  const [selectionMarkers, setSelectionMarkers] = useState({});
  
  const position = [INITIAL_LAT, INITIAL_LNG];
  
  const {
    visible,
    fetchDataTiles,
    tiles,
    grid,
    selectorReady,
    selectionActive,
    idEts,
    annee,
    dataType,
    geoType,
    [SELECTOR_TYPE_ETS_A]: selectorEtsA,
    [SELECTOR_TYPE_ETS_B]: selectorEtsB,
    routing,
  } = useSelector((state) => ({
    routing: state.routing,
    visible: state.selector[props.benchType].size > 0,
    fetchDataTiles:
      state.fetchData[DATA_TYPE_ATTRACTIVITE_TILES][props.benchType],
    tiles: state.carteTiles[props.benchType],
    grid: state.carteGrid,
    selectorReady: state.mainSelector.ready,
    selectionActive: state.offreSoins.selectionActive,
    idEts: state.carteInfos.idEts,
    annee: state.routing.params.annee,
    dataType: state.routing.params.dataType,
    geoType: state.routing.params.geoType,
    [SELECTOR_TYPE_ETS_A]: state.selector[SELECTOR_TYPE_ETS_A],
    [SELECTOR_TYPE_ETS_B]: state.selector[SELECTOR_TYPE_ETS_B]
  }));

  useEffect(() => {
    if (visible && selectorReady) {
      fetchDatas();
    }
  }, [visible, selectorReady, routing.params, fetchDataTiles]);

  useEffect(() => {
    if (mapRef.current) {
      setMap(mapRef.current);
    }
  }, [mapRef.current]);

  useEffect(() => {
    return () => {
      dispatch(toggleActiveEtablissement(false, null));
      props.carteSynchroOnMapLoaded();
    };
  }, []);

  useEffect(() => {
    if (visible && selectorReady && map !== null && eventAdded === false) {
      if (props.setLeafletMapInstance) {
        props.setLeafletMapInstance(map, props.benchType);
      }

      centerMap();

      map.on("move", onMapMove);
      map.on("zoom", onMapZoom);
      map.on("click", onMapClick);

      setEventAdded(true);
    }
  }, [visible, selectorReady, map, eventAdded]);

  const fetchDatas = () => {
    if (visible && selectorReady) {
      const newTilesParams = fetchDatasManager.getParams(
        DATA_TYPE_ATTRACTIVITE_TILES,
        routing.params,
        [props.benchType]
      );
      const hasDatasManagerDiff = fetchDatasManager.hasDifferences(
        fetchDataTiles,
        newTilesParams
        );

      if (hasDatasManagerDiff) {
        setRetryNumber(MAX_TILE_LOADING_TRY);
        setAlertDisplayed(false);
      }

      if (tiles.error && retryNumber <= 0 && !alertDisplayed) {
        setAlertDisplayed(true);
        alert("Une erreur est survenue avec le serveur de cartographie.");
      }

      if ((tiles.error && retryNumber > 0) || hasDatasManagerDiff) {
        dispatch(loadTiles(newTilesParams, props.benchType));

        if (
          props.annee === annee &&
          props.dataType === dataType &&
          props.geoType === geoType
        ) {
          dispatch(toggleActiveEtablissement(false, null));
        }
      }
    }
  };

  const onMapMove = (e) => {
    if (props.onMapMoveZoomHandler) {
      props.onMapMoveZoomHandler(
        map.getCenter(),
        map.getZoom(),
        props.benchType
      );
    }
  };

  const onMapZoom = (e) => {
    if (props.onMapMoveZoomHandler) {
      props.onMapMoveZoomHandler(
        map.getCenter(),
        map.getZoom(),
        props.benchType
      );
    }
  };

  const onMapClick = (e) => {
    if (idEts === 0) {
      return;
    } else {
      props.toggleActiveEtablissement(false, null);
    }
  };

  const onGridEnterOrLeave = (eventType, data) => {
    if (eventType === "enter") {
      props.onGridOverHandler(data);
    }
    if (eventType === "leave") {
      props.onGridOutHandler();
    }
  };

  const onGridClickHandler = (geoData) => {
    if (selectionActive) {
      props.onGridClickHandler(geoData);
    }
  };

  const centerMap = () => {
    const etablissements = persistentData.get("etablissements");
    const bounds = [];
    [SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B].forEach((benchType) => {
      if (benchType === SELECTOR_TYPE_ETS_A) {
        selectorEtsA.map((id) => {
          if (!selectionMarkers.hasOwnProperty(id)) {
            bounds.push([etablissements[id].lat, etablissements[id].lng]);
            setSelectionMarkers((prevState) => ({
              ...prevState,
              [id]: true,
            }));
          }
          return null;
        });
      } else if (benchType === SELECTOR_TYPE_ETS_B) {
        selectorEtsB.map((id) => {
          if (!selectionMarkers.hasOwnProperty(id)) {
            bounds.push([etablissements[id].lat, etablissements[id].lng]);
            setSelectionMarkers((prevState) => ({
              ...prevState,
              [id]: true,
            }));
          }
          return null;
        });
      }
    });
    const maxZoom = {
      [DATA_FILTER_TYPE_REG_16]: 8,
      [DATA_FILTER_TYPE_REG_15]: 9,
      [DATA_FILTER_TYPE_DEP]: 9,
      [DATA_FILTER_TYPE_COMMUNE]: 10,
    };

    if (0 !== bounds.length && null !== map) {
      return map.fitBounds(bounds, {
        maxZoom: maxZoom[geoType],
      });
    }
  };

  const onTileError = () => {
    setRetryNumber(retryNumber - 1);
    dispatch(loadError());
  };

  const onTileErrorDebounce = debounce(onTileError, 500);
  const onGridEnterOrLeaveDebounce = debounce(onGridEnterOrLeave, 100);

  const renderGridLayer = () => {
    if (grid.url === null) {
      return null;
    }
    return (
      <UTFGridLayer
        url={grid.url}
        onOverHandler={onGridEnterOrLeaveDebounce}
        onOutHandler={onGridEnterOrLeaveDebounce}
        onClickHandler={onGridClickHandler}
        onError={onTileErrorDebounce}
      />
    );
  };

  const renderTileLayer = () => {
    if (fetchDataTiles.pending || tiles.url === null) {
      return null;
    }
    return (
      <TileLayer
        url={tiles.url}
        opacity={0.8}
        onError={onTileErrorDebounce}
      />
    );
  };

  return !visible || !selectorReady ? null : (
    <div
      className="carte carte-attractivite"
      onMouseLeave={() => onGridEnterOrLeave("leave")}
    >
      <Legende classes={tiles.classes} benchType={props.benchType} />
      <MapContainer
        ref={mapRef}
        center={position}
        zoom={INITIAL_ZOOM}
        zoomControl={false}
      >
        <ZoomControl position={"topright"} />
        <TileLayer
          url={BASE_MAP_URL}
          attribution={ATTRIBUTION}
        />
        {renderGridLayer()}
        {renderTileLayer()}
        <CarteSelectionLayer />
        <Markers />
      </MapContainer>
    </div>
  );
};

CarteAttractivite.propTypes = {
  benchType: PropTypes.string.isRequired,
  onMapMoveZoomHandler: PropTypes.func,
  setLeafletMapInstance: PropTypes.func,
  onGridOverHandler: PropTypes.func.isRequired,
  onGridOutHandler: PropTypes.func.isRequired,
  onGridClickHandler: PropTypes.func.isRequired
};

export default CarteAttractivite;
