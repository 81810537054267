import React, {Component} from 'react'

export default class HelixLoader extends Component {

    renderDots() {
        let dots = []
        for (let i = 0; i < 25; i++) {
            dots.push(
                <div key={i} className="dot"/>
            )
        }

        return dots
    }

    render() {
        return (
            <div className="helix-loader">
                {this.renderDots()}
            </div>
        )
    }

}
