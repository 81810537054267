import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {FeatureGroup, GeoJSON} from 'react-leaflet';
import cn from 'classnames';

class CarteSelectionLayer extends React.Component {
    renderGeoJSONLayer() {
        let geoJSONList = [];
        this.props.selectionCodes.forEach((code) => {
            if (this.props.geojson.has(code)) {
                geoJSONList.push(
                    <GeoJSON className={cn('carte-selection-path')} data={this.props.geojson.get(code).geometry}
                             key={code}/>
                );
            }
        });

        return geoJSONList;
    }

    render() {
        if (!this.props.selectionActive || this.props.hidden) {
            return null;
        }
        return (
            <FeatureGroup>
                {this.renderGeoJSONLayer()}
            </FeatureGroup>
        );
    }
}


CarteSelectionLayer.propTypes = {
    hidden: PropTypes.bool.isRequired,
    selectionCodes: PropTypes.object,
    selectionActive: PropTypes.bool,
    geojson: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        selectionCodes: state.offreSoins.selectionCodes,
        selectionActive: state.offreSoins.selectionActive,
        geojson: state.offreSoins.geojson
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CarteSelectionLayer);