import React, {Component} from "react";
import PropTypes from 'prop-types';

import {connect} from "react-redux";
import cn from 'classnames';
import {resetAll, save} from 'actions/selectorActions';
import {navigate} from 'actions/routerActions';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B
} from 'constants/selectorTypes.js';
import Trans from 'components/atoms/Format/Trans';
import selectionSavedManager from 'managers/selectionSavedManager';

class SelectorActions extends Component {

    cancelClickHandler() {
        this.props.resetAll();
    }

    submitClickHandler(needToBeSave) {
        if (needToBeSave) {
            this.props.save();
        } else {
            this.props.navigate(this.props.loadPage);
        }
    }

    render() {
        const
            empty = this.props.selector[SELECTOR_TYPE_ETS_A].isEmpty()
                && this.props.selector[SELECTOR_TYPE_ETS_B].isEmpty(),
            isNew = selectionSavedManager.isNew(),
            equals = selectionSavedManager.equals(this.props.selector)
        ;
        return (
            <div className="selecteur-actions">
                <button className={cn('cancel', {'hidden': equals})}
                        onClick={this.cancelClickHandler.bind(this)}>
                    <Trans k='general.annuler'/>
                </button>
                <button className={cn('submit', {'hidden': empty})}
                        onClick={this.submitClickHandler.bind(this, !equals || isNew)}>
                    <Trans k='selecteur.runQuery'/>
                </button>
                <button className={cn('info', {'hidden': !empty || !equals})}>
                    <Trans k='selecteur.chooseOneEts'/>
                </button>
            </div>
        );
    }
}

SelectorActions.propTypes = {
    loadPage: PropTypes.string.isRequired,
    selector: PropTypes.object,
    resetAll: PropTypes.func,
    save: PropTypes.func,
    navigate: PropTypes.func
};

function mapStateToProps(state) {
    return {
        selector: state.selector
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetAll: () => {
            dispatch(resetAll());
        },
        save: () => {
            dispatch(save());
        },
        navigate: (pageName) => {
            dispatch(navigate(pageName));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorActions);