import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {PART_DISPLAY} from 'constants/offreSoinsDisplayState';
import cn from 'classnames';
import {displayToggle} from 'actions/offreSoinsActions';

class OffreSoinsToggleDisplay extends React.Component {
    getIcon(){
        if(PART_DISPLAY === this.props.displayState){
            return  <span className="icon icon-arrow-up" />;
        }
        return  <span className="icon icon-arrow-down" />;
    }
    getClassName(){
        return cn('offres-soins-toggle-display', {
            'center-container' : PART_DISPLAY === this.props.displayState
        });
    }
    render(){
        return (
            <div className={this.getClassName()} onClick={this.props.toggleDisplay.bind(this)}>
                {this.getIcon()}
            </div>
        );
    }
}


OffreSoinsToggleDisplay.propTypes = {
    displayState: PropTypes.string.isRequired,
    toggleDisplay: PropTypes.func
};

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleDisplay: () => {
            dispatch(displayToggle());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffreSoinsToggleDisplay);