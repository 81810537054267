import React from 'react';
import PropTypes from "prop-types";
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {connect} from 'react-redux';
import Immutable from 'immutable'
import cn from 'classnames'

import {
    SECTION_NAME_SELECTEUR,
    PAGE_NAME_SELECTEUR_ETABLISSEMENT,
    PAGE_NAME_SELECTEUR_APPAREIL,
    PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE,
    PAGE_NAME_SELECTEUR_GHM,
    PAGE_NAME_SELECTEUR_AGE
} from 'constants/router'

import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
    SELECTOR_TYPE_APPAREIL,
    SELECTOR_TYPE_PRISE_EN_CHARGE,
    SELECTOR_TYPE_GHM,
    SELECTOR_TYPE_AGE
} from 'constants/selectorTypes';

import SelectorList from 'components/organisms/SelectorList/SelectorList'
import SelectorSearch from 'components/molecules/Selector/SelectorSearch.js'
import SelectorSwitch from 'components/molecules/Selector/SelectorSwitch.js'
import SelectorTitle from 'components/molecules/Selector/SelectorTitle.js'

import treeMaker from '../managers/treeMaker';
import {search} from '../actions/selectorActions.js';


class SelectorBlock extends React.Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.currentRouteName !== nextProps.currentRouteName && null !== this.props.inputTerm) {
            this.props.search('');
        }
    }

    getTreeData() {
        const tree = treeMaker.getData(this.props.currentRouteName, this.props.regionsType);
        return Immutable.List(tree);
    }

    getSelectorType() {
        switch (this.props.currentRouteName) {
            case PAGE_NAME_SELECTEUR_ETABLISSEMENT:
                return [SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B]
            case PAGE_NAME_SELECTEUR_APPAREIL:
                return SELECTOR_TYPE_APPAREIL
            case PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE:
                return SELECTOR_TYPE_PRISE_EN_CHARGE
            case PAGE_NAME_SELECTEUR_GHM:
                return SELECTOR_TYPE_GHM
            case PAGE_NAME_SELECTEUR_AGE:
                return SELECTOR_TYPE_AGE
            default:
                return [SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B]
        }
    }

    renderContent() {
        if (SECTION_NAME_SELECTEUR !== this.props.routingSection) {
            return null;
        }
        const tree = this.getTreeData()
        const selectorType = this.getSelectorType()

        return (
            <div id="selector-block" className={cn(selectorType)}>
                <div className="selector-block-content">
                    {/*REVERSE EN CSS*/}
                    <div className="list-container">
                        <SelectorList selectorType={selectorType} data={tree}/>
                    </div>

                    {PAGE_NAME_SELECTEUR_ETABLISSEMENT === this.props.currentRouteName
                        ? <SelectorSwitch/>
                        : <SelectorTitle selectorType={selectorType} data={tree}/>}

                    <SelectorSearch selectorType={selectorType} data={tree}/>
                </div>
            </div>
        );
    }

    render() {
        return (
            <TransitionGroup
                component="div">
                <CSSTransition
                    classNames="display"
                    timeout={{
                        enter: 0,
                        exit: 250,
                    }}
                >
                  <>{this.renderContent()}</>
                </CSSTransition>
            </TransitionGroup>
        )
    }
}

SelectorBlock.propTypes = {
    routingSection: PropTypes.string,
    currentRouteName: PropTypes.string,
    regionsType: PropTypes.any,
    inputTerm: PropTypes.string,
    search: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        routingSection: state.routing.section,
        currentRouteName: state.routing.currentRouteName,
        regionsType: state.mainSelector.regionsType,
        inputTerm: state.mainSelector.inputTerm
    };
}

function mapDispatchToProps(dispatch) {
    return {
        search(searchInput) {
            dispatch(search(searchInput));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorBlock);
