import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B
} from 'constants/selectorTypes';
import FormattedDataType from 'components/molecules/Format/FormattedDataType';
import persistentData from 'managers/persistentData';

import CarteInfosBenchmarkEvolution from 'components/molecules/CarteInfos/CarteInfosBenchmarkEvolution';

export default class CarteInfosBenchmarkContent extends React.Component {
    renderEvolutionRows(){
        const
            annees = persistentData.get('annees'),
            idAnnees = Object.keys(annees).sort((a, b) => {
                return parseInt(b) - parseInt(a);
            })
        ;
        return idAnnees.map((idAnnee) => {
            const
                prevIdAnnee = parseInt(idAnnee) - 1,
                anneeDatas = this.props.datas[idAnnee],
                prevAnneeDatas = this.props.datas[prevIdAnnee]
            ;
            if(undefined === anneeDatas || undefined === prevAnneeDatas){
                return false;
            }
            const lib = annees[prevIdAnnee].lib + '-' + annees[idAnnee].lib;

            return (
                <div className="row" key={idAnnee}>
                    <CarteInfosBenchmarkEvolution
                        evolution={undefined === anneeDatas[SELECTOR_TYPE_ETS_A] ? 'general.na' : anneeDatas[SELECTOR_TYPE_ETS_A].evol}
                        lib={lib}
                        active={idAnnee === this.props.selectionAnnee}
                        align="left"
                        benchType={SELECTOR_TYPE_ETS_A}
                    />
                    <CarteInfosBenchmarkEvolution
                        evolution={undefined === anneeDatas[SELECTOR_TYPE_ETS_B] ? 'general.na' : anneeDatas[SELECTOR_TYPE_ETS_B].evol}
                        lib={lib}
                        active={idAnnee === this.props.selectionAnnee}
                        align="right"
                        benchType={SELECTOR_TYPE_ETS_B}
                    />
                </div>
            );
        });
    }
    getValue(benchType){

        return undefined === this.props.datas[this.props.selectionAnnee][benchType]
            ? 0
            : this.props.datas[this.props.selectionAnnee][benchType].value
        ;

    }
    render(){
        return (
            <div className="carte-infos-benchmark-content">
                <div className="row">
                    <FormattedDataType value={this.getValue(SELECTOR_TYPE_ETS_A)} withLib={true} className={cn('align-left', 'bench-' + SELECTOR_TYPE_ETS_A)} />
                    <FormattedDataType value={this.getValue(SELECTOR_TYPE_ETS_B)} withLib={true} className={cn('align-right', 'bench-' + SELECTOR_TYPE_ETS_B)} />
                </div>
                <div className="row">
                    <div className={cn('part-bench-' + SELECTOR_TYPE_ETS_B)}>
                        <div className={cn('part-bench-' + SELECTOR_TYPE_ETS_A)} style={{width: (this.props.partBenchmark * 100) + '%'}} />
                    </div>
                </div>
                {this.renderEvolutionRows()}
            </div>
        );
    }
}


CarteInfosBenchmarkContent.propTypes = {
    datas: PropTypes.object.isRequired,
    selectionAnnee: PropTypes.string.isRequired,
    partBenchmark: PropTypes.number.isRequired
};