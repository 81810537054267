import { cleanString } from "../utils/strings";
import axios from "axios";
import {
  SELECTOR_LOAD_PENDING,
  SELECTOR_LOAD_FULFILLED,
  SELECTOR_LOAD_REJECTED,
  SELECTOR_ADD_ITEMS,
  SELECTOR_REMOVE_ITEMS,
  SELECTOR_SEARCH,
  SELECTOR_CLEAN,
  SELECTOR_CLEAN_ALL,
  SELECTOR_RESET_ALL,
  SELECTOR_SAVE_PENDING,
  SELECTOR_SAVE_FULFILLED,
  SELECTOR_SAVE_REJECTED,
  SELECTOR_REGIONS_TYPE,
  SELECTOR_SET_SELECTED_MARKER,
  SELECTOR_UNSELECT_MARKER,
} from "constants/actionTypes";

import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes.js";
import { API_URL } from "constants/config";

export const search = (inputValue) => (dispatch) => {
  let inputTerm = cleanString(inputValue);
  if (0 === inputTerm.length) {
    inputTerm = null;
  }
  dispatch({ type: SELECTOR_SEARCH, payload: { inputTerm, inputValue } });
};

export const switchRegions = (regionsType) => (dispatch) => {
  dispatch({ type: SELECTOR_REGIONS_TYPE, payload: regionsType });
};

export const load = (hash) => async (dispatch, getState) => {
  if (getState().mainSelector.hash !== hash) {
    dispatch({ type: SELECTOR_LOAD_PENDING, payload: { hash } });
    try {
      const responses = await axios.post(
        API_URL + "/selector/load",
        { hash },
        { withCredentials: true }
      );
      if (getState().mainSelector.hash === responses.data.hash) {
        dispatch({
          type: SELECTOR_LOAD_FULFILLED,
          payload: {
            hash: responses.data.hash,
            selection: responses.data.selection,
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: SELECTOR_LOAD_REJECTED, payload: error.response });
    }
  }
};

export const save = () => async (dispatch, getState) => {
  const selectorStore = getState().selector;

  dispatch({ type: SELECTOR_SAVE_PENDING });

  let params = {};
  for (let selectorType in selectorStore) {
    if (selectorStore.hasOwnProperty(selectorType)) {
      params[selectorType] = selectorStore[selectorType].toJS();
    }
  }

  if (
    0 < params[SELECTOR_TYPE_ETS_B].length &&
    0 === params[SELECTOR_TYPE_ETS_A].length
  ) {
    params[SELECTOR_TYPE_ETS_A] = params[SELECTOR_TYPE_ETS_B];
    params[SELECTOR_TYPE_ETS_B] = [];
  }

  try {
    const responses = await axios.post(API_URL + "/selector/save", params, {
      withCredentials: true,
    });
    if (null === getState().mainSelector.hash) {
      dispatch({
        type: SELECTOR_SAVE_FULFILLED,
        payload: {
          hash: responses.data.hash,
          selection: responses.data.selection,
        },
      });
    }
  } catch (error) {
    console.error(error.response);
    dispatch({ type: SELECTOR_SAVE_REJECTED, payload: error.response });
  }
};

export const add = (ids, type) => (dispatch, getState) => {
  const state = getState().mainSelector;
  if (!state.pending) {
    dispatch({ type: SELECTOR_ADD_ITEMS, payload: { ids, type } });
  }
};

export const remove = (ids, type) => (dispatch, getState) => {
  const state = getState().mainSelector;
  if (!state.pending) {
    dispatch({ type: SELECTOR_REMOVE_ITEMS, payload: { ids, type } });
  }
};

export const clean = (type) => ({ type: SELECTOR_CLEAN, payload: { type } });

export const cleanAll = () => ({ type: SELECTOR_CLEAN_ALL });

export const resetAll = () => ({ type: SELECTOR_RESET_ALL });

export const setSelectedMarker = (id) => (dispatch) => {
  dispatch({ type: SELECTOR_SET_SELECTED_MARKER, payload: id });
};

export const unselectMarker = (bool) => (dispatch) => {
  dispatch({ type: SELECTOR_UNSELECT_MARKER, payload: bool });
};
