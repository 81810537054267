export function getHaloSize(value, max){
    const defaultWidth = 6 * 50

    let size = ((value * 100) * defaultWidth) / (max * 100)

    if (size < 25) {
        size = 25
    }

    return size
}