import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {navigate} from '../actions/routerActions';
import {loadOffreSoins, clearOffreSoins} from '../actions/offreSoinsActions';
import { PARAM_VALUE_OFF, PAGE_NAME_CARTE_ATTRACTIVITE, PARAM_NAME_GEO_SELECTION } from 'constants/router';

class OffreSoinsDispatcher extends React.Component {
    /*
     5e2b35c7901ab270cf067f7a50e52edc,
     d656ede75523d6a1c9a71721729a1496
     */
    UNSAFE_componentWillUpdate(nextProps){
        const
            oldSelectorReady = this.props.ready,
            newSelectorReady = nextProps.ready
        ;
        if(!newSelectorReady){
            return;
        }
        const
            oldHashParam = this.props.selectionHashParam,
            newHashParam = nextProps.selectionHashParam,
            newHashLoaded= nextProps.selectionHashLoaded,
            oldPending = this.props.pending,
            newPending = nextProps.pending
        ;
        if(PARAM_VALUE_OFF !== oldHashParam && PARAM_VALUE_OFF === newHashParam){
            this.props.clearOffreSoins(
                PAGE_NAME_CARTE_ATTRACTIVITE !== this.props.pageName || PAGE_NAME_CARTE_ATTRACTIVITE !== nextProps.pageName
            );
        }
        if(PARAM_VALUE_OFF !== newHashParam && !oldSelectorReady && newSelectorReady){
            //On charge les donnée lorque le selecteur à terminé un chargement
            this.props.loadOffreSoins(newHashParam, true);
        }
        if(PARAM_VALUE_OFF !== newHashParam && oldHashParam !== newHashParam ){
            //On charge le données lorsque il y a un changement du hash de l'offre de soins
            this.props.loadOffreSoins(newHashParam);
        }
        if(oldPending && !newPending && newHashLoaded !== newHashParam){
            this.props.navigate({[PARAM_NAME_GEO_SELECTION]: newHashLoaded});
        }
    }
    render() {
        return null;
    }
}

OffreSoinsDispatcher.propTypes = {
    ready: PropTypes.bool,
    pageName: PropTypes.string,
    selectionHashParam: PropTypes.string,
    selectionHashLoaded: PropTypes.string,
    pending: PropTypes.bool,
    clearOffreSoins: PropTypes.func,
    loadOffreSoins: PropTypes.func,
    navigate: PropTypes.func
};

function mapStateToProps(state) {
    return {
        ready: state.mainSelector.ready,
        pageName: state.routing.currentRouteName,
        selectionHashParam: state.routing.params[PARAM_NAME_GEO_SELECTION],
        selectionHashLoaded: state.offreSoins.hash,
        pending: state.offreSoins.dataPending
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearOffreSoins: (all) => {
            dispatch(clearOffreSoins(all));
        },
        loadOffreSoins: (selectionHash, force = false) => {
            dispatch(loadOffreSoins(selectionHash, force));
        },
        navigate: (params) => {
            dispatch(navigate(null, params));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffreSoinsDispatcher);