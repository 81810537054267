import axios from 'axios'
import {API_URL} from "constants/config";

import ReactGA from 'react-ga4'
import {GA_DIMENSION_HEVA} from 'constants/analytics.js'

class TokenManager {
    token = null
    user = null

    load() {
        return new Promise((resolve, reject) => {
            axios
                .get(API_URL + '/token', {withCredentials: true})
                .then(response => {
                    if ('OK' === response.data.status) {
                        this.token = response.data.token
                        this.user = response.data.user

                        // affecte tout les appel ! Oh Yeahhhhh
                        axios.defaults.headers.common['authorization'] = this.get();

                        ReactGA.set({
                            [GA_DIMENSION_HEVA]: (this.getUser().heva) ? 'Y' : 'N',
                        });
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error.response)
                })
        })
    }

    get() {
        return this.token
    }

    getAuth() {
        return {authorization: this.get()}
    }

    getHeaders() {
        return {headers: this.getAuth()}
    }

    getUser() {
        return this.user
    }
}

const token = new TokenManager()

export default token
