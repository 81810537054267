import {DATAS_FULFILLED} from 'constants/actionTypes'
import {DATA_TYPE_FICHE_APPAREILS} from 'constants/dataType.js'

const initialState = {
    selection: null,
    appareils: null,
	organes: null
};

export default function ficheAppareilsReducer(state = initialState, action) {
    if (DATAS_FULFILLED === action.type && action.dataType === DATA_TYPE_FICHE_APPAREILS) {
        return {
            ...state,
            selection: action.payload.selection,
            appareils: action.payload.appareils,
			organes: action.payload.organes
        }
    }
    return state;
}
