import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {getOffreSoins} from 'actions/offreSoinsActions';
import cn from 'classnames';
import {
    DATA_FILTER_TYPE_COMMUNE,
    DATA_FILTER_TYPE_DEP
} from 'constants/dataFilterType.js';
import {
    SELECTION_NOT_LOADED,
} from 'constants/offreSoinsDisplayState';

import Trans from 'components/atoms/Format/Trans';

class LoadOffreSoins extends React.Component {
    loadOffreSoins(){
        if(this.props.selectionCount > 0){
            this.props.loadOffreSoins();
        }
    }
    getClassName() {
        return cn('load-offre-soins center-container', {'disabled' : 0 === this.props.selectionCount});
    }
    getLib(){
        if(0 === this.props.selectionCount){
            return (
                <Trans k="carte.selectionnezZones"  />
            );
        }
        let transKey = 'carte.chargerInformationsRegion';
        if(DATA_FILTER_TYPE_DEP === this.props.geoType){
            transKey = 'carte.chargerInformationsDepartement';
        } else if(DATA_FILTER_TYPE_COMMUNE === this.props.geoType){
            transKey = 'carte.chargerInformationsCommune';
        }

        return (
            <Trans k={transKey} count={this.props.selectionCount} />
        );
    }
    render(){
        if(!this.props.visible){
            return null;
        }
        return (
            <div className={this.getClassName()} onClick={this.loadOffreSoins.bind(this)}>
                <span className="lib">
                    {this.getLib()}
                </span>
            </div>
        );
    }
}

LoadOffreSoins.propTypes = {
    visible: PropTypes.bool,
    selectionCount: PropTypes.number,
    geoType: PropTypes.string,
    loadOffreSoins: PropTypes.func
};

function mapStateToProps(state) {
    return {
        selectionCount: state.offreSoins.selectionCodes.size,
        geoType: state.offreSoins.geoType,
        visible: state.offreSoins.displayState === SELECTION_NOT_LOADED
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadOffreSoins: () => {
            dispatch(getOffreSoins());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadOffreSoins);