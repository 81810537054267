import appRouter from 'managers/appRouter';
import ReactGA from 'react-ga4';
import {LOCATION_CHANGE} from "constants/actionTypes";

export function navigate(pageName = null, params = {}) {
    return function (dispatch, getState) {
        let path = appRouter.getPath(pageName, params, getState().routing) || window.location.hash ;
        if (null !== pageName) {
            ReactGA.send({ hitType: "pageview", page: pageName, title: pageName });
        }

        window.location.hash = '/' + path;
        dispatch({type: LOCATION_CHANGE, payload:{
            pathname: path
        }});

    };
}
