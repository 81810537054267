import React from 'react';
import PropTypes from "prop-types";
import persistentData from 'managers/persistentData';
import Trans from 'components/atoms/Format/Trans';
import cn from 'classnames';

export default class CarteInfosEtablissementLib extends React.Component {
    render(){
        const etablissements = persistentData.get('etablissements');
        if(!etablissements.hasOwnProperty(this.props.id)){
            return null;
        }
        return (
            <div className={cn('carte-infos-etablissement-lib')}>
                <p>
                    <span className="nom">
                        {etablissements[this.props.id].lib}<br />
                    </span>
                    <span className="ville">
                        {etablissements[this.props.id].ville}<br />
                    </span>
                    <span className="finess">
                        <Trans k="general.finess" /> : {etablissements[this.props.id].code}
                    </span>
                </p>
            </div>
        );
    }
}
CarteInfosEtablissementLib.propTypes = {
    id: PropTypes.number.isRequired
};