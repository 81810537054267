import {FICHE_GHM_DETAIL_SET_GHM_ID, FICHE_GHM_DETAIL_SET_GHM_DATAS} from 'constants/actionTypes'
import {DATA_TYPE_FICHE_GHM_DETAIL} from 'constants/dataType.js'

const initialState = {
    idAnnee: null,
    idGhm: null,
    datas: null,
};

export default function ficheGhmDetailReducer(state = initialState, action) {
    if(FICHE_GHM_DETAIL_SET_GHM_ID === action.type){
        return {...state,
            type: DATA_TYPE_FICHE_GHM_DETAIL,
            idAnnee: action.payload.idAnnee,
            idGhm: action.payload.idGhm,
            datas: null
        };
    }
    if(FICHE_GHM_DETAIL_SET_GHM_DATAS === action.type){
        return {...state,
            datas: action.payload.datas
        };
    }

    return state;
}
