import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import SelectorDispatcher from "dispatchers/SelectorDispatcher";
import OffreSoinsDispatcher from "dispatchers/OffreSoinsDispatcher";
import LeftNav from "containers/LeftNav";
import SelectorBlock from "containers/SelectorBlock";
import ContentBlock from "containers/ContentBlock";
import ErrorPage from "containers/ErrorPage";
import WrongSelectionPage from "./WrongSelectionPage";
import { navigate } from "actions/routerActions";

const MainLayout = ({ tokenError }, props) => {
  const routingError = useSelector((state) => state.routing.error);
  const routingStatus = useSelector((state) => state.routing.status);
  const dispatch = useDispatch();


  if (routingError || tokenError) {
    const status = tokenError ? tokenError.status : routingStatus;

    if (404 === status) {
      <WrongSelectionPage status={status} />;
    }

    return (
      <div id="app-content">
        <OffreSoinsDispatcher />
        <SelectorDispatcher />
        <ErrorPage status={status} />
      </div>
    );
  }

  dispatch(navigate(window.location.href.split("#/")[1]));

  return (
    <div id="app-content">
      <OffreSoinsDispatcher />
      <SelectorDispatcher />
      <LeftNav />
      <SelectorBlock />
      <ContentBlock />
    </div>
  );
};

MainLayout.propTypes = {
  routingError: PropTypes.bool,
  routingStatus: PropTypes.number,
  tokenError: PropTypes.object,
};

const mapStateToProps = (state) => ({
  routingError: state.routing.error,
  routingStatus: state.routing.status,
});

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
