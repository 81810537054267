export const DATA_FILTER_TYPE_SEJOUR = 's';
export const DATA_FILTER_TYPE_VALO = 'v';
export const DATA_FILTER_TYPE_SEJOUR_PERCENT = 'ps';
export const DATA_FILTER_TYPE_VALO_PERCENT = 'pv';
export const DATA_FILTER_TYPE_REG_15 = 'reg15';
export const DATA_FILTER_TYPE_REG_16 = 'reg16';
export const DATA_FILTER_TYPE_DEP = 'dep';
export const DATA_FILTER_TYPE_COMMUNE = 'commune';
export const DATA_FILTER_TYPE_FRANCE = 'fr';
export const DATA_FILTER_TYPE_ZONE_ALL = 'touteZone';
export const DATA_FILTER_TYPE_ZONE_ACCES_TIME = 'accesTime';
export const DATA_FILTER_TYPE_ZONE_CHALANDISE = 'chalandise';
export const DATA_FILTER_TYPE_ZONE_PROXIMITE = 'proximite';
export const DATA_FILTER_SELECTION_IN = 'selection-in';
export const DATA_FILTER_SELECTION_ALL = 'selection-all';
export const DATA_FILTER_CATEGORY_ALL = 'category-all';
export const DATA_FILTER_DEPARTEMENT_ALL = 'departement-all';

export const DATA_FILTER_INITIAL_STATE = {
    filterSelection: DATA_FILTER_SELECTION_ALL,
    filterCategory: DATA_FILTER_CATEGORY_ALL,
    filterDepartement: DATA_FILTER_DEPARTEMENT_ALL,
}