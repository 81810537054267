import React, {Component} from 'react'
import {connect} from 'react-redux'
import cn from 'classnames'
import PropTypes from 'prop-types';

import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE} from 'constants/router'
import {DATA_FILTER_TYPE_SEJOUR, DATA_FILTER_TYPE_VALO} from 'constants/dataFilterType'
import {BENCHMARK_LETTERS} from 'constants/selectorTypes'

import {fetchGhmDetailData, clearGhmDetailData} from 'actions/ficheGhmDetailActions';

import persistentData from 'managers/persistentData.js'

import Percent from "components/atoms/Format/Percent";
import FormattedDataType from "components/molecules/Format/FormattedDataType";
import Trans from "components/atoms/Format/Trans";
import Currency from "components/atoms/Format/Currency";


class GhmListDetail extends Component {

    UNSAFE_componentWillMount() {
        if (false !== this.props.ghmDatas) {
            this.props.fetchGhmDetailData(this.props.idAnnee, this.props.idGhm)
        }
    }

    renderGhs() {
        let ghss = []
        if (null !== this.props.datas) {
            ghss = Object.keys(this.props.datas.ghss).map(idGhs => {
                const ghsInfos = persistentData.get('ghs')[idGhs]
                const ghsDatas = this.props.datas.ghss[idGhs]
                return (
                    <div key={idGhs} className={'row'}>
                        <div className="ghs-header">{ghsInfos.code}</div>
                        <div className="tarif">
                            <Currency value={ghsDatas} maximumFractionDigits={1}/>
                        </div>
                    </div>
                )
            })
        }

        return (
            <div className="data-ghs">
                <div className="row header">
                    <Trans k="fiche.ghm.detail.tarifGhs"/>
                </div>
                {ghss}
            </div>
        )
    }

    render() {
        if (false === this.props.ghmDatas) {
            return (
                <div className="detail-ghm close"/>
            )
        }

        const ghmInfos = persistentData.get('ghm')[this.props.idGhm]
        const severite = ghmInfos.code.substr(-1)

        const libParts = ghmInfos.lib.split(' : ')
        const finalLib = libParts.join(' : ')


        return (
            <div className={cn('detail-ghm', 'open', 'severite-' + severite)}>
                <div className="infos">
                    <div className="code">{ghmInfos.code} :</div>
                    <div className="lib">{finalLib}</div>
                    <div className="close-btn" onClick={this.props.clearGhmDetailData.bind(this)}>
                        <span className="icon-plus"/>
                    </div>
                </div>
                <div className="tables">
                    <div className="data-benchmark">
                        <div className="row header">
                            <div className="selection">
                                {(DATA_FILTER_TYPE_SEJOUR === this.props.dataType) &&
                                <Trans k="fiche.ghm.detail.sejour.selection"/>}
                                {(DATA_FILTER_TYPE_VALO === this.props.dataType) &&
                                <Trans k="fiche.ghm.detail.valo.selection"/>}
                            </div>
                            <div className="total">
                                {(DATA_FILTER_TYPE_SEJOUR === this.props.dataType) &&
                                <Trans k="fiche.ghm.detail.sejour.total"/>}
                                {(DATA_FILTER_TYPE_VALO === this.props.dataType) &&
                                <Trans k="fiche.ghm.detail.valo.total"/>}
                            </div>
                        </div>
                        {Object.keys(this.props.ghmDatas).map(bench => {
                            return (
                                <div key={bench} className={'row bench bench-' + bench}>
                                    <div className="bench-header">
                                        <div className="letter">{BENCHMARK_LETTERS[bench]}</div>
                                    </div>
                                    <div className="selection">
                                        <FormattedDataType value={this.props.ghmDatas[bench][this.props.dataType]}
                                                           className="number"/>
                                        <Percent value={this.props.ghmDatas[bench]['p' + this.props.dataType]}
                                                 className="percent"/>
                                    </div>
                                    <div className="total">
                                        <FormattedDataType value={(null !== this.props.datas)
                                            ? this.props.datas.ghms[bench][this.props.dataType]
                                            : null}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {this.renderGhs()}
                </div>
            </div>
        )
    }
}

GhmListDetail.propTypes = {
    ghmDatas: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    idAnnee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataType: PropTypes.string,
    idGhm: PropTypes.number,
    datas: PropTypes.object,
    fetchGhmDetailData: PropTypes.func,
    clearGhmDetailData: PropTypes.func,
}

GhmListDetail.defaultProps = {
    ghmDatas: false,
}


function mapStateToProps(state, ownProps) {
    return {
        idAnnee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
        idGhm: state.ficheGhmDetail.idGhm,
        datas: state.ficheGhmDetail.datas,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchGhmDetailData: (idAnnee, idGhm) => {
            dispatch(fetchGhmDetailData(idAnnee, idGhm));
        },
        clearGhmDetailData: () => {
            dispatch(clearGhmDetailData());
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GhmListDetail);