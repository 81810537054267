import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from "react-redux"

import LeftNav from 'containers/LeftNav'
import Trans from "components/atoms/Format/Trans";
import ErrorPageContent from "components/organisms/ErrorPageContent/ErrorPageContent";
import TopNav from "./TopNav";
import SelectorInfo from "components/molecules/Selector/SelectorInfo";

import {PAGE_NAME_SELECTEUR_ETABLISSEMENT} from 'constants/router';
import {navigate} from '../actions/routerActions.js';

class WrongSelectionPage extends Component {

    navigateToSelector() {
        this.props.navigate(PAGE_NAME_SELECTEUR_ETABLISSEMENT);
    }

    getActionButton() {
        return (
            <button className="action" onClick={this.navigateToSelector.bind(this)}>
                <Trans k={'wrongSelection.action'}/>
            </button>
        )
    }

    render() {
        return (
            <div>
                <LeftNav />
                <div id="content-block" className="error-page-content">
                    <TopNav />
                    <div className="selector-section-error">
                        <SelectorInfo />
                    </div>
                    <div className={'error-page error-' + this.props.status}>
                        <ErrorPageContent title={'wrongSelection.title'}
                                          description={'wrongSelection.description'}
                                          actionButton={this.getActionButton()}/>
                    </div>
                </div>
            </div>
        )
    }
}

WrongSelectionPage.propTypes = {
    status: PropTypes.number.isRequired,
    navigate: PropTypes.func,
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (pageName) => {
            dispatch(navigate(pageName));
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(WrongSelectionPage);