import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell } from "recharts";

import MarkerItem from "components/organisms/CustomClusterComponent/MarkerItem";
import { useSelector } from "react-redux";

const ClusterComponent = ({ cluster, map }) => {
  const state = useSelector((state) => state);
  const { etsA, etsB } = state.selector;
  const actualZoom = map._zoom;
  const clusterMarkers = cluster.markers;

  const getClusterSelection = (markers) => {
    let nbEtsA = 0;
    let nbEtsB = 0;
    markers.forEach((marker) => {
      if (etsA.toJS().includes(marker.options.id)) {
        nbEtsA++;
      } else if (etsB.toJS().includes(marker.options.id)) {
        nbEtsB++;
      }
    });
    return { a: nbEtsA, b: nbEtsB };
  };

  const zoomOnClick = (cluster) => {
    let zoom = map._zoom;
    if (zoom === 15) {
      map.zoomIn();
    } else {
      map.setView(cluster.center, zoom + 2);
    }
  };

  const customizeCluster = (cluster) => {
    let nbEts = getClusterSelection(clusterMarkers);
    const colors = { a: "#EF632F", b: "#8A4765" };

    let percents = {};

    Object.keys(nbEts).forEach((bench) => {
      let part = nbEts[bench] / clusterMarkers.length;
      if (part < 0.1 && part > 0) {
        part = 0.1;
      }
      percents[bench] = {
        value: part,
        color: colors[bench],
      };
    });

    percents.reste = {
      value: 1 - (percents.a.value + percents.b.value),
      color: "#D8E3F0",
    };

    let data = Object.values(percents);

    return (
      <div
        className="custom-cluster-container animated"
        onClick={() => zoomOnClick(cluster)}
      >
        <PieChart
          width={38}
          height={38}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          className="custom-cluster"
        >
          <Pie
            dataKey={"value"}
            className="custom-cluster-pie"
            isAnimationActive={false}
            data={data}
            paddingAngle={0}
            startAngle={90}
            endAngle={-360}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.color}
                stroke="transparent"
              />
            ))}
          </Pie>
        </PieChart>
        <span>{clusterMarkers.length}</span>
      </div>
    );
  };

  const spiderfy = (cluster, markers) => {
    let nbMarker = clusterMarkers.length;
    let angles = [];
    let markersStyle = [];
    let radius = 25;
    let angleMarker = 360 / nbMarker;
    for (let i = 0; i <= nbMarker; i++) {
      angles.push((angleMarker / 180) * i * Math.PI);
    }
    for (let i = 0; i < nbMarker; i++) {
      let posX = Math.round(radius * Math.cos(angles[i]));
      let posY = Math.round(radius * Math.sin(angles[i]));
      let top = parseInt(posY) + "px";
      let left = parseInt(posX) + "px";
      markersStyle.push({ top, left });
    }
    return (
      <div>
        {markers.map((marker, i) => (
          <div
            className="busted-cluster"
            key={marker.options.id}
            style={markersStyle[i]}
          >
            <MarkerItem map={map} marker={marker} style={markersStyle[i]} />
          </div>
        ))}
      </div>
    );
  };

  return actualZoom >= 14 && clusterMarkers.length > 1 ? (
    spiderfy(cluster, clusterMarkers)
  ) : clusterMarkers.length === 1 ? (
    <MarkerItem map={map} marker={clusterMarkers[0]} />
  ) : (
    customizeCluster(cluster)
  );
};
ClusterComponent.propTypes = {
  cluster: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
};

export default ClusterComponent;
