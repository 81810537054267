import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';

import {PARAM_NAME_DATA_TYPE, PAGE_NAME_FICHE_GHM} from 'constants/router'
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B, SELECTOR_TYPE_FR} from 'constants/selectorTypes';

import treeMaker from 'managers/treeMaker.js'

import persistentData from 'managers/persistentData.js'
import GhmListRacine from "./GhmListRacine";


class FicheGhmListContainer extends Component {

    constructor(props) {
        super(props)

        treeMaker.getData(PAGE_NAME_FICHE_GHM);
        this.maxPercent = 0
    }

    getRacines() {
        let maxPercents = []
        const racines = Object.keys(this.props.racines).map(idRacine => {
            const racineInfos = persistentData.get('racines')[idRacine]
            const racineDatas = this.props.racines[idRacine]

            maxPercents.push(this.getDataTypePercent(racineDatas, SELECTOR_TYPE_ETS_A))
            maxPercents.push(this.getDataTypePercent(racineDatas, SELECTOR_TYPE_ETS_B))
            maxPercents.push(this.getDataTypePercent(racineDatas, SELECTOR_TYPE_FR))

            return {...racineInfos, ...racineDatas}
        })

        this.maxPercent = Math.max(...maxPercents)

        racines.sort((left, right) => {
            let leftValue = this.getDataTypePercent(left, SELECTOR_TYPE_ETS_A)
            let rightValue = this.getDataTypePercent(right, SELECTOR_TYPE_ETS_A)

            if (leftValue === rightValue) {
                leftValue = this.getDataTypePercent(left, SELECTOR_TYPE_ETS_B)
                rightValue = this.getDataTypePercent(right, SELECTOR_TYPE_ETS_B)
                if (leftValue === rightValue) return 0
                return (leftValue < rightValue) ? 1 : -1
            }
            return (leftValue < rightValue) ? 1 : -1
        })

        return racines
    }

    getDataTypePercent(data, bench) {
        if (undefined === data[bench] || undefined === data[bench]['p' + this.props.dataType]) {
            return 0
        }
        return data[bench]['p' + this.props.dataType]
    }

    render() {
        const racines = this.getRacines()

        return (
            <div className="list-racines">
                {racines.map(racine => {
                    return (
                        <GhmListRacine key={racine.id} racine={racine}
                                       maxPercent={this.maxPercent} ghms={this.props.ghms} />
                    )
                })}
            </div>

        )
    }
}

FicheGhmListContainer.propTypes = {
    dataType: PropTypes.string,
    racines: PropTypes.object,
    ghms: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
    return {
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FicheGhmListContainer);

