import { LOCATION_CHANGE } from 'constants/actionTypes';
import appRouter from 'managers/appRouter';

const routerMiddleware = (store) => (next) => (action) => {
    if (action.type === LOCATION_CHANGE) {
        if ('PUSH' === action.payload.action) {
            return null;
        }
        action.payload.routeParams = appRouter.parsePath(action.payload.pathname);
    }

    return next(action);
};

export default routerMiddleware;