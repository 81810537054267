/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as L from "leaflet";
import { useMap } from "react-leaflet";

// Taken from http://stackoverflow.com/questions/1538681/how-to-call-fromlatlngtodivpixel-in-google-maps-api-v3/12026134#12026134
// and modified to use Leaflet API
const getExtendedBounds = (map, bounds, gridSize) => {
  // Turn the bounds into latlng.
  const northEastLat =
    bounds && bounds.getNorthEast() && bounds.getNorthEast().lat;
  const northEastLng =
    bounds && bounds.getNorthEast() && bounds.getNorthEast().lng;
  const southWestLat =
    bounds && bounds.getSouthWest() && bounds.getSouthWest().lat;
  const southWestLng =
    bounds && bounds.getSouthWest() && bounds.getSouthWest().lng;

  const tr = L.latLng(northEastLat, northEastLng);
  const bl = L.latLng(southWestLat, southWestLng);

  // Convert the points to pixels and the extend out by the grid size.
  const trPix = map.latLngToLayerPoint(tr);
  trPix.x += gridSize;
  trPix.y -= gridSize;

  const blPix = map.latLngToLayerPoint(bl);
  blPix.x -= gridSize;
  blPix.y += gridSize;

  // Convert the pixel points back to LatLng
  const ne = map.layerPointToLatLng(trPix);
  const sw = map.layerPointToLatLng(blPix);

  // Extend the bounds to contain the new bounds.
  bounds.extend(ne);
  bounds.extend(sw);

  return bounds;
};

const distanceBetweenPoints = (p1, p2) => {
  if (!p1 || !p2) {
    return 0;
  }

  const R = 6371; // Radius of the Earth in km

  const degreesToRadians = (degree) => (degree * Math.PI) / 180;
  const sinDouble = (degree) => Math.pow(Math.sin(degree / 2), 2);
  const cosSquared = (point1, point2) => {
    return (
      Math.cos(degreesToRadians(point1.lat)) *
      Math.cos(degreesToRadians(point2.lat))
    );
  };

  const dLat = degreesToRadians(p2.lat - p1.lat);
  const dLon = degreesToRadians(p2.lng - p1.lng);
  const a = sinDouble(dLat) + cosSquared(p1, p2) * sinDouble(dLon);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

const ClusterLayer = ({
  markers,
  clusterComponent,
  propsForClusters,
  gridSize = 60,
  minClusterSize = 2,
}) => {
  const map = useMap();
  const containerRef = useRef();
  const [clusters, setClusters] = useState([]);

  useEffect(() => {
    const leafletElement = containerRef.current;
    map.getPanes().overlayPane.appendChild(leafletElement);

    setClusters(createClustersFor(markers));
    attachEvents();
    recalculate(); // Force le recalcul après avoir attaché les événements

    return () => {
      if (map.getPanes().overlayPane !== undefined) {
        map.getPanes().overlayPane.removeChild(leafletElement);
      }
    };
  }, [map]);

  useEffect(() => {
    setClusters(createClustersFor(markers));
  }, [markers]);

  useEffect(() => {
    map.invalidateSize();
    updatePosition();
  }, [clusters]);

  const createClustersFor = (markers) => {
    const extendedBounds = getExtendedBounds(
      map,
      map.getBounds(),
      getGridSize()
    );
    return markers
      .filter((marker) => extendedBounds.contains(L.latLng(marker.position)))
      .reduce((clusters, marker) => {
        let distance = 40000; // Some large number
        let clusterToAddTo = null;
        const pos = marker.position;

        clusters.forEach((cluster) => {
          const center = cluster.center;
          if (center) {
            const d = distanceBetweenPoints(center, pos);
            if (d < distance) {
              distance = d;
              clusterToAddTo = cluster;
            }
          }
        });

        if (clusterToAddTo && isMarkerInClusterBounds(clusterToAddTo, marker)) {
          addMarkerToCluster(clusterToAddTo, marker);
        } else {
          const cluster = {
            markers: [marker],
            center: L.latLng(pos),
            bounds: L.latLngBounds(),
          };
          calculateClusterBounds(cluster);
          clusters.push(cluster);
        }
        return clusters;
      }, []);
  };

  const recalculate = () => {
    setClusters(createClustersFor(markers));
    updatePosition();
  };

  const updatePosition = () => {
    clusters.forEach((cluster, i) => {
      const clusterElement = ReactDOM.findDOMNode(
        containerRef.current.children[i]
      );
      if (clusterElement) {
        const position = map.latLngToLayerPoint(cluster.center);
        L.DomUtil.setPosition(clusterElement, position);
      }
    });
  };

  const attachEvents = () => {
    map.on("viewreset", () => recalculate());
    map.on("moveend", () => recalculate());
  };

  const renderClusters = () => {
    const style = {
      position: "absolute",
    };
    const ClusterComponent = clusterComponent;
    return clusters.map((cluster, index) => (
      <ClusterComponent
        propsForClusters={propsForClusters}
        key={index}
        style={style}
        map={map}
        ref={containerRef[getClusterRefName(index)]}
        cluster={cluster}
      />
    ));
  };

  const getGridSize = () => {
    return gridSize || 60;
  };

  const calculateClusterBounds = (cluster) => {
    const bounds = L.latLngBounds(cluster.center, cluster.center);
    cluster.bounds = getExtendedBounds(map, bounds, getGridSize());
  };

  const isMarkerInClusterBounds = (cluster, marker) => {
    return cluster.bounds.contains(L.latLng(marker.position));
  };

  const addMarkerToCluster = (cluster, marker) => {
    const center = cluster.center;
    const markersLen = cluster.markers.length;

    if (!center) {
      cluster.center = L.latLng(marker.position);
      calculateClusterBounds(cluster);
    } else {
      const len = markersLen + 1;
      const lng = (center.lng * (len - 1) + marker.position.lng) / len;
      const lat = (center.lat * (len - 1) + marker.position.lat) / len;
      cluster.center = L.latLng({ lng, lat });
      calculateClusterBounds(cluster);
    }

    marker.isAdded = true;
    cluster.markers.push(marker);
  };

  const getClusterRefName = (index) => {
    return `cluster${index}`;
  };

  return (
    <div
      ref={containerRef}
      className={`leaflet-objects-pane leaflet-marker-pane leaflet-zoom-hide react-leaflet-cluster-layer`}
    >
      {renderClusters()}
    </div>
  );
};

export default ClusterLayer;
