import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'

import {API_URL} from "constants/config";
import {PAGE_NAME_FICHE_ETABLISSEMENTS} from 'constants/router'

import Trans from 'components/atoms/Format/Trans'

class ExportCsvButton extends Component {

    render() {
        if (false === this.props.visible)
            return null

        return (
            <div className="export-btn">
                <span className="icon icon-export"/>
                <a href={API_URL + '/fiche/etablissements/export?hash=' + this.props.hash} target="_blank" className="btn" rel="noreferrer">
                    <Trans k="fiche.etablissements.export" className="lib"/>
                </a>
                <a href="https://partage.unicancer.fr/sharing/sB06H67bs" target="_blank" className="btn" rel="noreferrer">
                    <Trans k="fiche.etablissements.tableau" className="lib"/>
                </a>
            </div>
        )
    }
}

ExportCsvButton.propTypes = {
    visible: PropTypes.bool,
    hash: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        visible: PAGE_NAME_FICHE_ETABLISSEMENTS === state.routing.currentRouteName,
        hash: state.routing.params.hash
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCsvButton)