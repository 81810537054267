import axios from "axios";
import { API_URL } from "constants/config";

import {
  DATA_FILTER_TYPE_REG_15,
  DATA_FILTER_TYPE_REG_16,
  DATA_FILTER_TYPE_DEP,
} from "constants/dataFilterType.js";

class PersistentData {
  routes = {
    annees: {
      uri: "/annees",
      data: {},
    },
    anneesGeos: {
      uri: "/annees-geos",
      data: {},
    },
    regions: {
      uri: "/regions",
      data: {},
    },
    departements: {
      uri: "/departements",
      data: {},
    },
    etablissements: {
      uri: "/etablissements",
      data: {},
    },
    cmd: {
      uri: "/cmd",
      data: {},
    },
    racines: {
      uri: "/racines",
      data: {},
    },
    priseEnCharge: {
      uri: "/prise-en-charge",
      data: {},
    },
    appareils: {
      uri: "/appareils",
      data: {},
    },
    organes: {
      uri: "/organes",
      data: {},
    },
    ghm: {
      uri: "/ghm",
      data: {},
    },
    ghs: {
      uri: "/ghs",
      data: {},
    },
    accessTime: {
      uri: "/access-time",
      data: {},
    },
    age: {
      uri: "/ages",
      data: {},
    },
  };

  load() {
    return new Promise((resolve, reject) => {
      const totalCount = Object.keys(this.routes).length;
      let loadedCount = 0;
      Object.values(this.routes).forEach((route) => {
        axios
          .get(API_URL + route.uri, { withCredentials: true })
          .then((response) => {
            route.data = response.data;
            loadedCount++;

            if (totalCount === loadedCount) {
              resolve(this.routes);
            }
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    });
  }

  get(key) {
    if (undefined === this.routes[key]) {
      console.error(`persisentData "${key}" undefined`);
      return null;
    }
    return this.routes[key].data;
  }

  getKey(constante) {
    switch (constante) {
      case DATA_FILTER_TYPE_DEP:
        return "departements";
      case DATA_FILTER_TYPE_REG_15:
        return "regions";
      case DATA_FILTER_TYPE_REG_16:
        return "regions";
      default:
        return "regions";
    }
  }
}

const persistentData = new PersistentData();

export default persistentData;
