import React, {Component}  from 'react'
import {connect} from 'react-redux'
import cn from 'classnames';
import PropTypes from 'prop-types';

import {PARAM_NAME_DATA_TYPE} from 'constants/router'
import {
    DATA_FILTER_TYPE_SEJOUR,
    DATA_FILTER_TYPE_VALO
} from 'constants/dataFilterType'

import Currency from "components/atoms/Format/Currency";
import Decimal from "components/atoms/Format/Decimal";
import Trans from "components/atoms/Format/Trans";

class FormattedDataType extends Component {
    removeProps(input, ...propNames) {
        let obj = {...input}
        propNames.forEach(propName => {
            if (obj.hasOwnProperty(propName)) {
                delete obj[propName]
            }
        })
        return obj
    }

    renderLib() {
        if (this.props.withLib) {
            return <Trans k="dataType.sejours-seances" count={this.props.value} className="unite"/>
        }
        return null
    }

    render() {
        if (DATA_FILTER_TYPE_SEJOUR === this.props.dataType) {
            return (
                <span className={cn(this.props.className)}>
                    <span className="number">
                        <Decimal className="number" {...this.props} />
                    </span> {this.renderLib()}
                </span>
            );
        }
        if (DATA_FILTER_TYPE_VALO === this.props.dataType) {
            const props = this.removeProps(this.props, 'className', 'value')

            if (true === this.props.condensed) {
                return (
                    <span className={cn('number', this.props.className)}>
                        <Decimal value={(this.props.value / 1000)} {...props} />&nbsp;<Trans k="k€"/>
                    </span>
                )
            }
            return (
                <span className={cn('number', this.props.className)}>
                    <Currency value={this.props.value} {...props} />
                </span>
            )
        }
        console.error('Can not format dataType : ' + this.props.dataType)
        return null
    }
}

FormattedDataType.defaultProps = {
    withLib: false,
    condensed: false,
};

FormattedDataType.propTypes = {
    value: PropTypes.number.isRequired,
    dataType: PropTypes.string,
    className: PropTypes.string,
    withLib: PropTypes.bool,
    condensed: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(FormattedDataType);