import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import Trans from 'components/atoms/Format/Trans'
import cn from 'classnames';
import {selectionEnabledToggle} from 'actions/offreSoinsActions';

import Switch from 'components/atoms/Swicth/Switch';

class SwitchSelection extends React.Component {
    toggleSelectionMode() {
        if(!this.props.disabled){
            this.props.toggleSelection();
        }
    }
    render(){
        return (
            <div className={cn('switch-selection', {'disabled': this.props.disabled})}>
                <div className="lib">
                    <Trans k={'carte.selection'} />
                </div>
                <Switch
                    active={this.props.selectionActive}
                    disabled={this.props.disabled}
                    clickHandler={this.toggleSelectionMode.bind(this)}
                    libActif={<Trans k="switch.on"/>}
                    libInactif={<Trans k="switch.off"/>}
                />
            </div>
        );
    }
}


SwitchSelection.propTypes = {
    disabled: PropTypes.bool,
    selectionActive: PropTypes.bool,
    toggleSelection: PropTypes.func
};
SwitchSelection.defaultProps = {
    disabled: false
};

function mapStateToProps(state) {
    return {
        selectionActive: state.offreSoins.selectionActive
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSelection : () => {
            dispatch(selectionEnabledToggle());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchSelection);