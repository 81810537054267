import React from 'react';
import PropTypes from "prop-types";
import {Set} from 'immutable';
import cn from 'classnames'

import {DATA_FILTER_TYPE_ZONE_ACCES_TIME} from 'constants/dataFilterType';
import persistentData from 'managers/persistentData';

import Checkbox from 'components/atoms/Form/Checkbox';
import Trans from 'components/atoms/Format/Trans';

export default class ZonesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.setStateFromProps(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(this.setStateFromProps(nextProps));
    }

    setStateFromProps(props) {
        let selectedZone = props.selectedZone;
        if (undefined === props.selectedZone) {
            selectedZone = this.props.zones[0].value
        }
        let selectedAccesTime = new Set();
        if (undefined !== props.selectedAccesTime) {
            selectedAccesTime = new Set(props.selectedAccesTime);
        }
        return {
            zone: selectedZone,
            accesTime: selectedAccesTime
        };
    }

    onZoneClick(zone) {
        if (true === zone.disabled) {
            return
        }
        this.setState({zone: zone.value, accesTime: new Set()});
    }

    onAccesTimeClick(id) {
        if (true === this.props.accesTimeDisabled) {
            return
        }
        let newAccessTime = null;
        let newZone = null;
        if (this.state.accesTime.includes(id)) {
            newAccessTime = this.state.accesTime.delete(id)
        } else {
            newAccessTime = this.state.accesTime.add(id)
        }

        if (0 === newAccessTime.size) {
            newZone = this.props.zones[0].value
        }
        else {
            newZone = DATA_FILTER_TYPE_ZONE_ACCES_TIME
        }

        this.setState({zone: newZone, accesTime: newAccessTime})
    }

    getSelection() {
        return {
            zone: this.state.zone,
            accesTime: this.state.accesTime.toArray()
        };
    }

    getZonesList() {
        return this.props.zones.map(zone => {
            return (
                <div key={zone.value} className={cn('row', 'item', 'radioItem', {disabled: zone.disabled})}
                     onClick={this.onZoneClick.bind(this, zone)}>
                    <Checkbox radio={true} checked={zone.value === this.state.zone}/>
                    <Trans k={zone.transKey}/>
                </div>
            )
        })
    }

    getAccesTimeList() {
        const accessTimeDatas = persistentData.get('accessTime');

        let AccessTimeList = [];
        for (let id in accessTimeDatas) {
            if (accessTimeDatas.hasOwnProperty(id)) {
                AccessTimeList.push(
                    <div key={accessTimeDatas[id].borne_inf}
                         className={cn('checkbox-btn', {checked: this.state.accesTime.includes(id)})}
                         onClick={this.onAccesTimeClick.bind(this, id)}>
                        <span>{accessTimeDatas[id].lib}</span>
                    </div>
                );
            }
        }
        AccessTimeList.sort((a, b) => {
            return a.key - b.key;
        });

        return (
            <div className={cn("row", "radioItem", {disabled: this.props.accesTimeDisabled})}>
                <div className="checkbox-group">
                    {AccessTimeList}
                </div>
            </div>
        );
    }


    render() {
        return (
            <div className={cn('zone-filter', this.props.className)}>
                {this.getZonesList()}

                <div className="row subtitle">
                    <Trans k="dataType.tempsAccesTitle"/>
                </div>

                {this.getAccesTimeList()}

                <div className="row actions">
                    <button onClick={
                        this.props.onSubmitHandler.bind(this, this.getSelection())
                        }>
                        <Trans k="general.valider"/>
                    </button>
                </div>
            </div>
        );
    }
}


ZonesFilter.propTypes = {
    zones: PropTypes.array.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    selectedZone: PropTypes.string,
    selectedAccesTime: PropTypes.array,
    accesTimeDisabled: PropTypes.array,
};

ZonesFilter.defaultProps = {
    accesTimeDisabled: false
}