import {
    DATAS_FULFILLED,
    DATAS_PENDING,
    CARTE_INFOS_SET_DATAS,
    CARTE_INFOS_SET_DEFAULT,
    CARTE_INFOS_SET_ETS_ID,
    CARTE_INFOS_SET_ETS_DATAS,
    CARTE_INFOS_CLEAR_ETS
} from 'constants/actionTypes'
import {DATA_TYPE_CARTE_INFOS_FRANCE} from 'constants/dataType'
import {
    DATA_FILTER_TYPE_VALO,
    DATA_FILTER_TYPE_SEJOUR
} from 'constants/dataFilterType';
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import {CARTE_INFOS_TYPE_ETS, CARTE_INFOS_TYPE_GEO} from 'constants/carteInfosType';
import persistentData from '../../managers/persistentData.js';


function getGeoDatasState(datas) {
    let state = {
        lib: null,
        code: null,
        population: 0,
        annees: {}
    };
    if (null === datas) {
        return state;
    }
    for (let property in datas) {
        if (datas.hasOwnProperty(property)) {
            if (state.hasOwnProperty(property)) {
                state[property] = datas[property];
            } else {
                const
                    regExpr = new RegExp('(' + SELECTOR_TYPE_ETS_A + '|' + SELECTOR_TYPE_ETS_B + ')([0-9][0-9])'),
                    matches = property.match(regExpr)
                ;
                if (matches) {
                    const benchType = matches[1], annee = matches[2];
                    if (!state['annees'].hasOwnProperty(annee)) {
                        state['annees'][annee] = {};
                    }
                    if (!state['annees'][annee].hasOwnProperty(benchType)) {
                        state['annees'][annee][benchType] = {value: 0, evol: 'general.na'};
                    }
                    state['annees'][annee][benchType]['value'] = datas[property]
                        ? parseInt(datas[property])
                        : 0
                    ;
                }
            }
        }
    }
    for (let idAnnee in state.annees) {
        if (state.annees.hasOwnProperty(idAnnee)) {
            const prevIdAnnee = parseInt(idAnnee) - 1;
            if (state.annees[idAnnee].hasOwnProperty(SELECTOR_TYPE_ETS_A)) {
                if (state.annees.hasOwnProperty(prevIdAnnee) &&
                    state.annees[prevIdAnnee].hasOwnProperty(SELECTOR_TYPE_ETS_A) &&
                    state.annees[idAnnee].hasOwnProperty(SELECTOR_TYPE_ETS_A)) {
                    const value = state.annees[idAnnee][SELECTOR_TYPE_ETS_A]['value'];
                    const prevValue = state.annees[prevIdAnnee][SELECTOR_TYPE_ETS_A]['value'];
                    state.annees[idAnnee][SELECTOR_TYPE_ETS_A]['evol'] = 0 === prevValue ? 0 : (value - prevValue) / prevValue;
                }
                if (state.annees.hasOwnProperty(prevIdAnnee) &&
                    state.annees[prevIdAnnee].hasOwnProperty(SELECTOR_TYPE_ETS_B) &&
                    state.annees[idAnnee].hasOwnProperty(SELECTOR_TYPE_ETS_B)) {
                    const value = state.annees[idAnnee][SELECTOR_TYPE_ETS_B]['value'];
                    const prevValue = state.annees[prevIdAnnee][SELECTOR_TYPE_ETS_B]['value'];
                    state.annees[idAnnee][SELECTOR_TYPE_ETS_B]['evol'] = 0 === prevValue ? 0 : (value - prevValue) / prevValue;
                }
            }
        }
    }

    return state;
}

function getEtsDataState(idEts, datas) {
    let state = {};

    const
        annees = persistentData.get('annees'),
        idsAnnee = Object.keys(annees).sort((a, b) => {
            return parseInt(b) - parseInt(a)
        });
    idsAnnee.forEach(idAnnee => {
        idAnnee = parseInt(idAnnee);
        const prevAnnee = idAnnee - 1;
        state[idAnnee] = {
            [DATA_FILTER_TYPE_SEJOUR]: {value: 0, evol: 'general.na', partBench: null},
            [DATA_FILTER_TYPE_VALO]: {value: 0, evol: 'general.na', partBench: null}
        };
        if (undefined !== datas[idAnnee]) {
            state[idAnnee][DATA_FILTER_TYPE_SEJOUR].value = datas[idAnnee][DATA_FILTER_TYPE_SEJOUR];
            state[idAnnee][DATA_FILTER_TYPE_VALO].value = datas[idAnnee][DATA_FILTER_TYPE_VALO];
            if (undefined !== annees[prevAnnee] && undefined !== datas[prevAnnee]) {
                state[idAnnee][DATA_FILTER_TYPE_SEJOUR].evol = 0 === datas[prevAnnee][DATA_FILTER_TYPE_SEJOUR]
                    ? 0
                    : (datas[idAnnee][DATA_FILTER_TYPE_SEJOUR] - datas[prevAnnee][DATA_FILTER_TYPE_SEJOUR]) / datas[prevAnnee][DATA_FILTER_TYPE_SEJOUR]
                ;
                state[idAnnee][DATA_FILTER_TYPE_VALO].evol = 0 === datas[prevAnnee][DATA_FILTER_TYPE_VALO]
                    ? 0
                    : (datas[idAnnee][DATA_FILTER_TYPE_VALO] - datas[prevAnnee][DATA_FILTER_TYPE_VALO]) / datas[prevAnnee][DATA_FILTER_TYPE_VALO]
                ;
            }
            if (null !== datas[idAnnee].partBench) {
                state[idAnnee][DATA_FILTER_TYPE_SEJOUR].partBench = datas[idAnnee].partBench[DATA_FILTER_TYPE_SEJOUR];
                state[idAnnee][DATA_FILTER_TYPE_VALO].partBench = datas[idAnnee].partBench[DATA_FILTER_TYPE_VALO];
            }
        }
    });

    return state;
}

export default function carteInfosReducer(state = {
    geoDatas: getGeoDatasState(null),
    datasFrance: null,
    etsDatas: null,
    idEts: 0,
    type: CARTE_INFOS_TYPE_GEO
}, action) {
    if (DATAS_PENDING === action.type && action.dataType === DATA_TYPE_CARTE_INFOS_FRANCE) {
        return {
            ...state,
            geoDatas: getGeoDatasState(null),
            datasFrance: null
        };
    }
    if (DATAS_FULFILLED === action.type && action.dataType === DATA_TYPE_CARTE_INFOS_FRANCE) {
        return {
            ...state,
            geoDatas: getGeoDatasState(action.payload),
            datasFrance: action.payload
        };
    }

    if (CARTE_INFOS_SET_DATAS === action.type) {
        return {
            ...state,
            geoDatas: getGeoDatasState(action.payload)
        };
    }
    if (CARTE_INFOS_SET_DEFAULT === action.type) {
        return {
            ...state,
            geoDatas: getGeoDatasState(state.datasFrance)
        };
    }
    if (CARTE_INFOS_SET_ETS_ID === action.type) {
        return {
            ...state,
            type: CARTE_INFOS_TYPE_ETS,
            idEts: action.payload.id,
            etsDatas: null
        };
    }
    if (CARTE_INFOS_SET_ETS_DATAS === action.type) {
        if (CARTE_INFOS_TYPE_ETS !== state.type) {
            return state;
        }
        return {
            ...state,
            etsDatas: getEtsDataState(action.payload.idEts, action.payload.datas)
        };
    }
    if (CARTE_INFOS_CLEAR_ETS === action.type) {
        return {
            ...state,
            idEts: 0,
            etsDatas: null,
            type: CARTE_INFOS_TYPE_GEO,
        };
    }

    return state;
}
