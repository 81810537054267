import {SECTION_NAME_SELECTEUR, PAGE_NAME_FICHE_ETABLISSEMENTS} from 'constants/router';
import {LOCATION_CHANGE} from 'constants/actionTypes';

const initialState = {
    currentRouteName: null,
    section: null,
    loadPage: null,
    params: {},
    error: false,
    status: 200
};

export default function routerReducer(state = initialState, {type, payload} = {}) {
    if (type === LOCATION_CHANGE) {
        if (!payload.routeParams.error) {
            let loadPage = state.loadPage;
            if(SECTION_NAME_SELECTEUR !== payload.routeParams.section){
                loadPage = payload.routeParams.name;
            }
            if(null === loadPage){
                loadPage = PAGE_NAME_FICHE_ETABLISSEMENTS;
            }
            return {
                ...state,
                currentRouteName: payload.routeParams.name,
                loadPage,
                section: payload.routeParams.section,
                params: {...state.params, ...payload.routeParams.params},
                error: false,
                status: 200
            };
        } else if (!state.error) {
            return {...state, ...initialState, error: true, status: 404};
        }
    }
    if(-1 !== type.indexOf('REJECTED')){
        let status = 404;
        if(payload){
            status = payload.status;
        }
        return {...state, error: true, status: status};
    }

    return state;
}