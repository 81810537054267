import {DATAS_FULFILLED} from 'constants/actionTypes'
import {DATA_TYPE_FICHE_SEXE_AGE} from 'constants/dataType.js'

const initialState = {
    selection: null,
	ages: null,
    sexes: null,
};

export default function ficheSexeAgeReducer(state = initialState, action) {
    if (DATAS_FULFILLED === action.type && action.dataType === DATA_TYPE_FICHE_SEXE_AGE) {
        return {
            ...state,
            selection: action.payload.selection,
            ages: action.payload.ages,
            sexes: action.payload.sexes,
        }
    }
    return state;
}
