import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import cn from "classnames";
import {
  loadGrid,
  setCarteInfosDatas,
  setCarteInfosDefault,
} from "actions/carteActions";
import { selectionToggle } from "actions/offreSoinsActions";
import fetchDatasManager from "managers/fetchDatasManager";
import {
  PAGE_NAME_CARTE_ATTRACTIVITE,
  PARAM_NAME_GEO_TYPE,
} from "constants/router";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes";
import { DATA_TYPE_ATTRACTIVITE_GRID } from "constants/dataType";
import {
  DATA_FILTER_TYPE_REG_15,
  DATA_FILTER_TYPE_REG_16,
  DATA_FILTER_TYPE_DEP,
  DATA_FILTER_TYPE_COMMUNE,
} from "constants/dataFilterType";
import {
  SELECTION_NOT_LOADED,
  PART_DISPLAY,
  FULL_DISPLAY,
} from "constants/offreSoinsDisplayState";

import CarteAttractivite from "components/molecules/CarteAttractivite/CarteAttractivite";
import CarteBottomNav from "components/molecules/CarteBottomNav/CarteBottomNav";
import OffreSoins from "../OffreSoins/OffreSoins";
import LoadOffreSoins from "components/molecules/CarteBottomNav/LoadOffreSoins";
import SwitchMapSynchroButton from "components/molecules/CarteAttractivite/SwitchMapSynchroButton";

class AttractivitePageContent extends React.Component {
  constructor(props) {
    super(props);
    this.leafletMap = {
      [SELECTOR_TYPE_ETS_A]: null,
      [SELECTOR_TYPE_ETS_B]: null,
    };
    this.disabledMap = {
      [SELECTOR_TYPE_ETS_A]: false,
      [SELECTOR_TYPE_ETS_B]: false,
    };
    this.state = {
      carteSynchro: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchDatas(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.fetchDatas(nextProps);
  }

  fetchDatas(props) {
    if (this.props.visible && props.selectorReady) {
      const newGridParams = fetchDatasManager.getParams(
        DATA_TYPE_ATTRACTIVITE_GRID,
        props
      );
      if (
        props.gridError ||
        fetchDatasManager.hasDifferences(props.fetchDataGrid, newGridParams)
      ) {
        props.loadGrid(newGridParams, props.hasEtsA, props.hasEtsB);
      }
    }
  }

  setLeafletMapInstance(map, benchType) {
    this.leafletMap[benchType] = map;
  }

  onMapMoveZoomHandler(center, zoom, benchType, forceSynchro = false) {
    if (
      !forceSynchro &&
      (!this.state.carteSynchro || !this.props.hasEtsA || !this.props.hasEtsB)
    ) {
      return;
    }
    const otherBenchType =
      SELECTOR_TYPE_ETS_A === benchType
        ? SELECTOR_TYPE_ETS_B
        : SELECTOR_TYPE_ETS_A;
    if (!this.disabledMap[otherBenchType]) {
      this.disabledMap[otherBenchType] = true;
      if (null !== this.leafletMap[otherBenchType]) {
        this.leafletMap[otherBenchType].setView(center, zoom, {
          animate: false,
        });
      }

      this.disabledMap[otherBenchType] = false;
    }
  }

  onGridOverHandler(geoData) {
    this.props.setCarteInfosDatas(geoData);
  }

  onGridOutHandler() {
    this.props.setCarteInfosDefault();
  }

  onGridClickHandler(geoData) {
    if (geoData && geoData.code) {
      this.props.selectionToggle(geoData.code);
    }
  }

  carteSynchroOnMapLoaded() {
    this.setState({
      carteSynchro: true,
    });
  }

  toggleCarteSynchro(messaage) {
    const newState = !this.state.carteSynchro;
    this.setState({
      carteSynchro: newState,
    });
    if (true === newState && null !== this.leafletMap[SELECTOR_TYPE_ETS_A]) {
      this.onMapMoveZoomHandler(
        this.leafletMap[SELECTOR_TYPE_ETS_A].getCenter(),
        this.leafletMap[SELECTOR_TYPE_ETS_A].getZoom(),
        SELECTOR_TYPE_ETS_A,
        true
      );
    }
  }

  renderCarteAttractivite(benchType) {
    return (
      <CarteAttractivite
        carteSynchroOnMapLoaded={this.carteSynchroOnMapLoaded.bind(this)}
        benchType={benchType}
        onMapMoveZoomHandler={this.onMapMoveZoomHandler.bind(this)}
        setLeafletMapInstance={this.setLeafletMapInstance.bind(this)}
        onGridOverHandler={this.onGridOverHandler.bind(this)}
        onGridOutHandler={this.onGridOutHandler.bind(this)}
        onGridClickHandler={this.onGridClickHandler.bind(this)}
      />
    );
  }

  getBottomNavTabs() {
    return [
      {
        linkKey: DATA_FILTER_TYPE_REG_16,
        transKey: "dataType.nouvelleRegion_plural",
        isActive: DATA_FILTER_TYPE_REG_16 === this.props.geoType,
      },
      {
        linkKey: DATA_FILTER_TYPE_REG_15,
        transKey: "dataType.ancienneRegion_plural",
        isActive: DATA_FILTER_TYPE_REG_15 === this.props.geoType,
      },
      {
        linkKey: DATA_FILTER_TYPE_DEP,
        transKey: "dataType.departement_plural",
        isActive: DATA_FILTER_TYPE_DEP === this.props.geoType,
      },
      {
        linkKey: DATA_FILTER_TYPE_COMMUNE,
        transKey: "dataType.commune_plural",
        isActive: DATA_FILTER_TYPE_COMMUNE === this.props.geoType,
      },
    ];
  }

  getBottomPanelClassName() {
    let offreSoinsExtraClassName;
    switch (this.props.offreSoinsDisplayState) {
      case SELECTION_NOT_LOADED: {
        offreSoinsExtraClassName = "selection-not-loaded";
        break;
      }
      case PART_DISPLAY: {
        offreSoinsExtraClassName = "offre-soins-part-display";
        break;
      }
      case FULL_DISPLAY: {
        offreSoinsExtraClassName = "offre-soins-full-display";
        break;
      }
      default: {
        offreSoinsExtraClassName = "selection-not-active";
        break;
      }
    }
    return cn("carte-bottom-panel", offreSoinsExtraClassName);
  }

  render() {
    if (!this.props.visible) {
      return null;
    }
    return (
      <div className="attractivite-page-content">
        <div className="carte-container">
          {this.renderCarteAttractivite(SELECTOR_TYPE_ETS_A)}
          <SwitchMapSynchroButton
            visible={this.props.hasEtsA && this.props.hasEtsB}
            active={this.state.carteSynchro}
            onClickHandler={this.toggleCarteSynchro.bind(this)}
          />
          {this.renderCarteAttractivite(SELECTOR_TYPE_ETS_B)}
          <div className={this.getBottomPanelClassName()}>
            <CarteBottomNav
              tabs={this.getBottomNavTabs()}
              selectionDisabled={false}
              hidden={FULL_DISPLAY === this.props.offreSoinsDisplayState}
              paramName={PARAM_NAME_GEO_TYPE}
            />
            <OffreSoins />
            <LoadOffreSoins />
          </div>
        </div>
      </div>
    );
  }
}

AttractivitePageContent.propTypes = {
  visible: PropTypes.bool,
  hasEtsA: PropTypes.bool,
  hasEtsB: PropTypes.bool,
  fetchDataGrid: PropTypes.object,
  selectorReady: PropTypes.bool,
  offreSoinsDisplayState: PropTypes.string,
  loadGrid: PropTypes.func,
  geoType: PropTypes.string,
  selectionToggle: PropTypes.func,
  setCarteInfosDatas: PropTypes.func,
  setCarteInfosDefault: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    visible: PAGE_NAME_CARTE_ATTRACTIVITE === state.routing.currentRouteName,
    hasEtsA: state.selector[SELECTOR_TYPE_ETS_A].size > 0,
    hasEtsB: state.selector[SELECTOR_TYPE_ETS_B].size > 0,
    fetchDataGrid: state.fetchData[DATA_TYPE_ATTRACTIVITE_GRID],
    selectorReady: state.mainSelector.ready,
    offreSoinsDisplayState: state.offreSoins.displayState,
    gridError: state.carteGrid.error,
    ...fetchDatasManager.getParams(
      DATA_TYPE_ATTRACTIVITE_GRID,
      state.routing.params
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadGrid: (params, hasEtsA, hasEtasB) => {
      dispatch(loadGrid(params, hasEtsA, hasEtasB));
    },
    selectionToggle: (code) => {
      dispatch(selectionToggle(code));
    },
    setCarteInfosDatas: (geoDatas) => {
      dispatch(setCarteInfosDatas(geoDatas));
    },
    setCarteInfosDefault: () => {
      dispatch(setCarteInfosDefault());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttractivitePageContent);
