import isEqual from 'lodash/isEqual';
import {
    DATAS_PENDING,
    DATAS_FULFILLED,
    DATAS_REJECTED
} from 'constants/actionTypes';

export default function getFetchDatasReducer(initialParams){
    return function(state = {
        pending: false,
        params: initialParams,
        error: false
    }, action){
        if(undefined === action.dataType) return state;
        const dataTypes = Array.isArray(action.dataType) ? action.dataType : [action.dataType];
        if(!isEqual(dataTypes, this.dataTypes)) return state;

        if(DATAS_PENDING === action.type){
            return {...state, pending: true, error: false, params: {...action.payload.params}}
        }
        if(DATAS_FULFILLED === action.type){
            return {...state, pending: false, error: false}
        }
        if(DATAS_REJECTED === action.type){
            return {...state, pending: false, error: false, params: {...initialParams}}
        }
        return state;
    }
}