import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux'
import cn from 'classnames'

import {isLeaf, getLeafCount} from 'utils/tree'

import {
	PAGE_NAME_SELECTEUR_ETABLISSEMENT,
} from 'constants/router'

const SelectorCounter = ({ item }) => {

	const routeName = useSelector(state => state.routing.currentRouteName);

	const getTotalSelected = (item, ets) => {
		if(isLeaf(item)) {
			return null
		} else if (item.nb_children_selected === undefined && ets !== undefined) {
			return (ets === 'etsA') ? item.nb_children_bench_A : item.nb_children_bench_B
		}
		return item.nb_children_selected
	}

	const renderCounter = (item, hasBenchmark, ets) => {
		let totalSelected = getTotalSelected(item, ets)
		let totalChildren = getLeafCount(item.children)
		let className = ""
		if(hasBenchmark) {
			className = (ets === 'etsA') ? "etsA" : "etsB"
		}
		return (
			<span className={cn('count', className, (totalSelected > 0) ? "active" : "")}>
				{totalSelected} / {totalChildren}
			</span>
		)
	}

	if (isLeaf(item)) {
		return null
	}
	return (
		<div className="item-count">
			{(routeName === PAGE_NAME_SELECTEUR_ETABLISSEMENT) ? renderCounter(item, true, 'etsA') : renderCounter(item, false)}
			{(routeName === PAGE_NAME_SELECTEUR_ETABLISSEMENT) ? renderCounter(item, true, 'etsB') : null}
		</div>
	)
}

SelectorCounter.propTypes = {
	item: PropTypes.object.isRequired,
};

export default SelectorCounter;
