//export const BASE_MAP_URL = '//1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/256/png?app_id=54lJOrsu4ZmWNQMOBlhL&app_code=RGK7NwWuoUzM-BxySeeLAg';
export const BASE_MAP_URL = '//{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}{r}.png';
export const INITIAL_LAT = 46.621978;
export const INITIAL_LNG = 2.488917;
export const INITIAL_ZOOM = 7;
export const TILE_SERVER_HOST = 'tile.hevadev.com';
export const TILE_SERVER_PORT = 443;
export const TILE_SERVER_DBNAME = 'carto';
export const MAX_TILE_LOADING_TRY = 3;
export const ATTRIBUTION = '<a href="http://osm.org/copyright">&copy; OpenStreetMap</a>, <a href="https://carto.com/attribution/">&copy; CARTO</a>, <a href="https://www.hevaweb.com">By HEVA</a>';
