import React, {PureComponent} from 'react'
import PropTypes from "prop-types";
import 'react-virtualized/styles.css'
import {List, AutoSizer} from 'react-virtualized'
import {connect} from 'react-redux'

import {searchFilter} from 'utils/searchFilter'
import {isLeaf, getItemPath, findItemFromPath} from 'utils/tree'

import SelectorItem from 'components/molecules/Selector/SelectorItem.js'

class SelectorList extends PureComponent {
    constructor(props) {
        super(props)

        this.List = null
        this.filteredList = props.data

        this.parentRowHeight = 56
        this.leafRowHeight = 40

        this.state = {
            rowCount: this.filteredList.size
        }
    }

    UNSAFE_componentWillMount() {
        this.setSelectedState(this.props.data, this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.filteredList = searchFilter(nextProps.data, nextProps.inputTerm)
        let newSize = this.filteredList.size
        this.setState({rowCount: newSize})

        if (this.props.inputTerm !== nextProps.inputTerm) {
            this.List.recomputeRowHeights()
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.setSelectedState(nextProps.data, nextProps)
        this.setSearchedState(nextProps.data, nextProps.inputTerm)
    }

    setSelectedState(tree, props) {
        let selectedCount
        if (undefined !== props.selectorTypeB) {
            selectedCount = {
                etsA: 0,
                etsB: 0
            }
        } else {
            selectedCount = 0
        }
        tree.forEach((item) => {
            if (isLeaf(item)) {
                if (undefined !== props.selectorTypeB) {
                    if (props.selectorType.includes(item.id)) {
                        item.selected = 'etsA'
                        selectedCount.etsA++
                    } else if (props.selectorTypeB.includes(item.id)) {
                        item.selected = 'etsB'
                        selectedCount.etsB++
                    } else {
                        item.selected = false
                    }
                } else {
                    if (props.selectorType.includes(item.id)) {
                        item.selected = true
                        selectedCount++
                    } else {
                        item.selected = false
                    }
                }
            } else {
                const childrenSelected = this.setSelectedState(item.children, props)
                if (undefined !== props.selectorTypeB) {
                    item.nb_children_bench_A = childrenSelected.etsA
                    item.nb_children_bench_B = childrenSelected.etsB
                    selectedCount.etsA += childrenSelected.etsA
                    selectedCount.etsB += childrenSelected.etsB
                } else {
                    item.nb_children_selected = childrenSelected
                    item.selected = (childrenSelected > 0)
                    selectedCount += childrenSelected
                }
            }
        });
        return selectedCount;
    }


    setSearchedState(tree, inputTerm) {
        tree.forEach((item) => {
            if (!isLeaf(item)) {
                if (null !== item.beingSearched) {
                    if (null === inputTerm) {
                        item.expanded = false
                    }
                    else {
                        item.expanded = item.beingSearched
                    }
                }

                this.setSearchedState(item.children, inputTerm)
            }

            if (null === inputTerm) {
                item.beingSearched = null
            }
        })

        return tree
    }

    /*
     *  Calcul la hauteur des row en fonctions des children
     */
    getItemHeight(parent) {
        let height = 0
        if (null !== this.props.inputTerm && !parent.beingSearched) {
            return height
        }
        if (isLeaf(parent) &&
            ((null !== this.props.inputTerm && parent.beingSearched) || (null === this.props.inputTerm))) {
            height += this.leafRowHeight
        }
        else {
            height += this.parentRowHeight
            if (parent.expanded) {
                parent.children.forEach(item => {
                    height += this.getItemHeight(item)
                })
            }
        }
        return height
    }

    getRowHeight(params) {
        const item = this.filteredList.get(params.index)
        return this.getItemHeight(item)
    }

    closeChildren(children) {
        children.forEach(item => {
            item.expanded = false
            if (!isLeaf(item)) {
                this.closeChildren(item.children)
            }
        })
    }

    /*
     *  Change le champs expanded (bool) des éléments de liste
     */
    toggleRow(item) {
        let path = getItemPath(item, [])
        const itemFound = findItemFromPath(this.filteredList, path)

        // close children
        if (true === item.expanded && !isLeaf(item)) {
            this.closeChildren(itemFound.children)
        }

        itemFound.expanded = !item.expanded
        this.List.recomputeRowHeights()
    }

    rowRenderer(params) {
        let {index, style} = params
        let item = this.filteredList.get(index)
        return (
            <SelectorItem
                key={params.key}
                style={style}
                {...item}
                toggleRow={this.toggleRow.bind(this)}
            />
        )
    }

    render() {
        return (
            <AutoSizer>
                {({width, height}) => (
                    <List
                        key={this.props.routing}
                        ref={ref => this.List = ref}
                        height={height}
                        overscanRowCount={10}
                        rowCount={this.state.rowCount}
                        rowHeight={this.getRowHeight.bind(this)}
                        rowRenderer={this.rowRenderer.bind(this)}
                        width={width}
                    />
                )}
            </AutoSizer>
        )
    }
}

SelectorList.propTypes = {
    data: PropTypes.object.isRequired,
    inputTerm: PropTypes.string,
    routing: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    let selecType
    let selecTypeB
    if (typeof ownProps.selectorType !== 'object') {
        selecType = ownProps.selectorType
    } else {
        selecType = ownProps.selectorType[0]
        selecTypeB = ownProps.selectorType[1]
    }

    return {
        inputTerm: state.mainSelector.inputTerm,
        routing: state.routing.currentRouteName,
        selectorType: state.selector[selecType],
        selectorTypeB: state.selector[selecTypeB]
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorList);
