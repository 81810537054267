import React, {Component}  from 'react'
import FormattedNumberWithPlaceholder from 'components/atoms/Format/FormattedNumberWithPlaceholder'
import PropTypes from 'prop-types';

export default class Percent extends Component {
    render() {
        let props = {...this.props}

        if (0 !== props.value) {
            if (props.value * 100 < .1 && props.value * 100 > -.1) {
                props.minimumFractionDigits = 2
                props.maximumFractionDigits = 2
            }
            if (props.value * 100 < .01 && props.value * 100 > -.01) {
                props.minimumFractionDigits = 3
                props.maximumFractionDigits = 3
            }
        }

        return (
            <span className={props.className}>
                {props.isEvolution && props.value > 0 && <span>+</span>}
                <FormattedNumberWithPlaceholder {...props} styleNumber="percent" />
            </span>
        );
    }
}

Percent.propTypes = {
    value: PropTypes.number.isRequired,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
    isEvolution: PropTypes.bool,
    className: PropTypes.string,
};
Percent.defaultProps = {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    isEvolution: false,
};