/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import FilterChoices from "./FilterChoices";

const FilterDropdown = ({ list, onItemClickHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(list[0].id);

  const setActiveItemOnClicked = (item) => {
    setActiveItem(item.id);
  };

  useEffect(() => {
    onItemClickHandler(activeItem);
  }, [activeItem]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleClickButton = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownClass = isOpen
    ? "filter-dropdown dropdown--active"
    : "filter-dropdown";

  return (
    <div ref={dropdownRef} className={dropdownClass}>
      <button
        className="icon icon-filter dropdown__trigger"
        onClick={handleClickButton}
      />
      {isOpen && (
        <div className="dropdown-menu">
          {list.map((item, index) => (
            <FilterChoices
              key={item.id}
              index={index}
              item={item}
              activeItem={activeItem}
              onItemClickHandler={() => setActiveItemOnClicked(item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

FilterDropdown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      lib: PropTypes.string.isRequired,
    })
  ).isRequired,
  onItemClickHandler: PropTypes.func.isRequired,
};

export default FilterDropdown;
