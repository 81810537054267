import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux"
import { MapContainer, ZoomControl, TileLayer } from 'react-leaflet';

import { SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B } from 'constants/selectorTypes';
import {
    INITIAL_LAT,
    INITIAL_LNG,
    INITIAL_ZOOM,
    BASE_MAP_URL,
    ATTRIBUTION
} from 'constants/carteConfig';

import ClusterLayer from '../CustomClusterComponent/ClusterLayer';
import ClusterComponent from '../CustomClusterComponent/ClusterComponent'

const SelectorMapContainer = ({ tree }) => {

    const etsA = useSelector(state => state.selector[SELECTOR_TYPE_ETS_A]);
    const etsB = useSelector(state => state.selector[SELECTOR_TYPE_ETS_B]);

    const setMarker = (item, type) => {
        return {
            position: {
                lat: parseFloat(item.lat),
                lng: parseFloat(item.lng),
            },
            options: {
                id: item.id,
                title: item.lib,
                selectorType: type
            }
        }
    }

    const markers = useMemo(() => {
        const newMarkers = []
        tree.forEach((item) => {
            if (etsB.size > 0) {
                if (etsA.includes(item.id)) {
                    newMarkers.push(setMarker(item, SELECTOR_TYPE_ETS_A))
                } else if (etsB.includes(item.id)) {
                    newMarkers.push(setMarker(item, SELECTOR_TYPE_ETS_B))
                } else {
                    newMarkers.push(setMarker(item, null))
                }
            } else {
                if (etsA.includes(item.id)) {
                    newMarkers.push(setMarker(item, SELECTOR_TYPE_ETS_A))
                } else {
                    newMarkers.push(setMarker(item, null))
                }
            }
        });
        return newMarkers;
    }, [tree, etsA, etsB]);

    const position = [INITIAL_LAT, INITIAL_LNG];
    return (
        <div className="carte carte-selector">
            <MapContainer
                center={position}
                zoom={INITIAL_ZOOM}
                zoomControl={false}
                maxZoom={16}
            >
                <ZoomControl position={'topright'}/>
                <ClusterLayer
                    markers={markers}
                    clusterComponent={ClusterComponent}
                    gridSize={100}
                />
                <TileLayer
                    url={BASE_MAP_URL}
                    attribution={ATTRIBUTION}
                    opacity={0.6}
                />
            </MapContainer>
        </div>
    );
}

SelectorMapContainer.propTypes = {
    tree: PropTypes.object.isRequired,
};

export default SelectorMapContainer;
