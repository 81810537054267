import {
  DATAS_FULFILLED,
  CARTE_CONCURRENCE_SET_VISIBLE_MARKERS,
  CARTE_CONCURRENCE_SET_BRONES_PDM,
} from "constants/actionTypes";
import { DATA_TYPE_CARTE_CONCURRENCE } from "constants/dataType.js";

const initialState = {
  hash: null,
  datas: null,
  visibleMarkers: null,
  bornesPdm: {},
};

export default function carteConcurrenceReducer(state = initialState, action) {
  switch (action.type) {
    case DATAS_FULFILLED:
      if (action.dataType === DATA_TYPE_CARTE_CONCURRENCE) {
        return {
          ...state,
          hash: action.payload.hash,
          datas: action.payload.datas,
        };
      }
      break;
    case CARTE_CONCURRENCE_SET_VISIBLE_MARKERS:
      return { ...state, visibleMarkers: action.payload };
    case CARTE_CONCURRENCE_SET_BRONES_PDM:
      return { ...state, bornesPdm: action.payload };
    default:
      break;
  }

  return state;
}
