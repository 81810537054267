import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';

export default class TabsMenuItem extends React.Component {
    getClassName(){
        return cn('item', {
            'active' : this.props.active
        });
    }
    render() {
        return (
            <div className={this.getClassName()}
                onClick={this.props.onClickHandler}>
                <span className="lib">{this.props.lib}</span>
            </div>
        );
    }
}

TabsMenuItem.propTypes = {
    active: PropTypes.bool.isRequired,
    lib: PropTypes.any.isRequired,
    onClickHandler: PropTypes.func.isRequired
};
