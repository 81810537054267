import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {SortDirection} from 'react-virtualized'
import cn from 'classnames'

import SortIndicator from 'components/atoms/List/SortIndicator'
import Trans from "components/atoms/Format/Trans";

export default class SortableHeader extends Component {

    renderLabel() {
        if (undefined !== this.props.labelComponent && null !== this.props.labelComponent) {
            return (
                <span className="label">
                    {this.props.labelComponent}
                </span>
            )
        }
        return (
            <Trans k={this.props.label} className="label label-title"/>
        )
    }

    renderDataDesc() {
        if (undefined !== this.props.dataDesc) {
            return (
                <div className="data-desc">
                    {this.renderDataDescAnnee()}
                    {this.renderDataDescGeo()}
                </div>
            )
        }
        return null
    }

    renderDataDescAnnee() {
        if (undefined !== this.props.dataDesc.annee) {
            return (
                <div className="annee">{this.props.dataDesc.annee.lib}</div>
            )
        }
        return null
    }

    renderDataDescGeo() {
        if (undefined !== this.props.dataDesc.filterGeoTransKey) {
            return (
                <Trans k={this.props.dataDesc.filterGeoTransKey} className="geo"/>
            )
        }
        return null
    }


    renderSortIndicator() {
        const sort = this.props.sortBy === this.props.dataKey ? true : this.props.initialSortBy === this.props.dataKey ? true : false
        if (this.props.disableSort) {
            return null
        }
        return (
            <SortIndicator
                sortActive={sort}
                sortDirection={this.props.sortDirection || this.props.initialDirection}/>
        )
    }

    sort() {
        if (this.props.disableSort) {
            return false
        }
        const sortBy = this.props.dataKey

        let sortDirection = SortDirection.ASC
        if (this.props.dataKey === this.props.sortBy) {
            sortDirection = (SortDirection.ASC === this.props.sortDirection) ? SortDirection.DESC : SortDirection.ASC
        }

        this.props.sort({sortBy, sortDirection})
    }

    render() {

        return (
            <div className={cn('list-header', 'cell', {'disable-sort': this.props.disableSort})}>
                {this.props.filterDropdown}
                <div className="sort-action-container" onClick={this.sort.bind(this)}>
                    <div className="label-and-desc">
                        {this.renderLabel()}
                        {this.renderDataDesc()}
                    </div>
                    {this.renderSortIndicator()}
                </div>
            </div>
        )
    }
}

SortableHeader.propTypes = {
    disableSort: PropTypes.bool,
    dataKey: PropTypes.string,
    label: PropTypes.string,
    dataDesc: PropTypes.object,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    sort: PropTypes.func,
    filterDropdown: PropTypes.element,
    labelComponent: PropTypes.element,
}
