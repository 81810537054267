import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'

import {fetch} from 'actions/dataActions';
import fetchDatasManager from 'managers/fetchDatasManager';

import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE, PAGE_NAME_FICHE_GHM} from 'constants/router'
import {DATA_TYPE_FICHE_GHM} from 'constants/dataType'

import FicheSummary from "components/molecules/FicheSummary/FicheSummary";
import FicheGhmListContainer from "components/molecules/FicheGhm/FicheGhmListContainer";
import HelixLoader from "components/atoms/Loader/HelixLoader";


class GhmPageContent extends Component {
    UNSAFE_componentWillMount() {
        this.fetchDatas(this.props)
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.fetchDatas(nextProps)
    }

    fetchDatas(props) {
        if (props.visible && props.selectorReady) {
            const newParams = fetchDatasManager.getParams(DATA_TYPE_FICHE_GHM, props.routingParams);
            if (fetchDatasManager.hasDifferences(props.fetchData, newParams)) {
                props.fetch(newParams);
            }
        }
    }

    render() {
        if (false === this.props.visible) {
            return null
        }

        if (this.props.fetchData.pending || null === this.props.datas.selection) {
            return <HelixLoader />
        }

        return (
            <div>
                <FicheSummary titleKeys={{title: 'fiche.ghm.title'}}
                              selection={this.props.datas.selection}/>

                <div className="fiche-container">
                    <FicheGhmListContainer {...this.props.datas} />
                </div>
            </div>
        )
    }
}


GhmPageContent.propTypes = {
    visible: PropTypes.bool,
    fetchData: PropTypes.object,
    routingParams: PropTypes.object,
    annee: PropTypes.string,
    dataType: PropTypes.string,
    selectorReady: PropTypes.bool,
    datas: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        visible: PAGE_NAME_FICHE_GHM === state.routing.currentRouteName,
        fetchData: state.fetchData[DATA_TYPE_FICHE_GHM],
        routingParams: fetchDatasManager.getParams(DATA_TYPE_FICHE_GHM, state.routing.params),
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
        selectorReady: state.mainSelector.ready,
        datas: state.ficheGhm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetch: (params) => {
            dispatch(fetch(params, DATA_TYPE_FICHE_GHM, '/fiche/ghm'));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GhmPageContent);