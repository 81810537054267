import React from 'react';
import PropTypes from "prop-types";

import {PARAM_VALUE_ACCES_TIME_INF} from 'constants/router';
import {useTranslation} from 'react-i18next';

import {formatAccessTimeParam} from 'managers/appRouter';

import Trans from 'components/atoms/Format/Trans';
import persistentData from 'managers/persistentData';

const AccesTimeLib = (props) => {

    const {t} = useTranslation();

    if (undefined === props.accesTime || null === props.accesTime) {
        return (
            <span>
                    {(props.pdmPrefix)
                        ? <span><Trans k="general.pdm"/>&nbsp;</span>
                        : null}
                <Trans k="dataType.tempsAcces"/>
                </span>
        );
    }

    let accesTimeIds = formatAccessTimeParam(props.accesTime);
    let accesTimeArr = [];
    const accesTimeDatas = persistentData.get('accessTime');
    accesTimeIds.forEach((id) => {
        if (accesTimeDatas.hasOwnProperty(id)) {
            accesTimeArr.push(accesTimeDatas[id].borne_inf);
            accesTimeArr.push(accesTimeDatas[id].borne_sup);
        }
    });
    accesTimeArr.sort((a, b) => {
        return parseInt(a) - parseInt(b);
    });
    let intervals = [];
    for (let i = 0, j = 0, l = accesTimeArr.length, flag = true; i < l; i++) {
        let current = accesTimeArr[i],
            next = accesTimeArr[i + 1]
        ;
        if (0 === i) {
            intervals[j] = {inf: current, sup: 0};
        } else if (current + 1 === next) {
            flag = true;
            i++;
        } else {
            if (flag) {
                intervals[j].sup = current;
            } else {
                j++;
                intervals[j] = {inf: current, sup: 0};
            }
            flag = !flag;
        }
    }

    if (1 === intervals.length && 0 === intervals[0].inf && PARAM_VALUE_ACCES_TIME_INF === intervals[0].sup) {
        return (
            <span>
                    {(props.pdmPrefix)
                        ? <span><Trans k="general.pdm"/>&nbsp;</span>
                        : null}
                <Trans k="dataType.tempsAcces"/> : <Trans k="general.tous"/>
                </span>
        );
    }
    let accesTimeLib = '';
    intervals.forEach((interval, i) => {
        if (i > 0) {
            accesTimeLib += ' ' + t('general.et') + ' ';
        }
        if (PARAM_VALUE_ACCES_TIME_INF === interval.sup) {
            accesTimeLib += '>' + (interval.inf - 1);
        } else {
            accesTimeLib += interval.inf + '-' + interval.sup;
        }
    });

    return (
        <span>
                {(props.pdmPrefix)
                    ? <span><Trans k="general.pdm"/>&nbsp;</span>
                    : null}
            <Trans k="dataType.tempsAcces"/> : {accesTimeLib} <Trans
            k="abreviations.minute"/>
            </span>
    );
}


AccesTimeLib.propTypes = {
    accesTime: PropTypes.string,
    pdmPrefix: PropTypes.bool,
    t: PropTypes.func,
};

AccesTimeLib.defaultProps = {
    pdmPrefix: false
}

export default AccesTimeLib;