export const SELECTOR_TYPE_ETS_A = 'etsA';
export const SELECTOR_TYPE_ETS_B = 'etsB';
export const SELECTOR_TYPE_FR = 'fr';
export const SELECTOR_TYPE_APPAREIL = 'appareil';
export const SELECTOR_TYPE_PRISE_EN_CHARGE = 'priseEnCharge';
export const SELECTOR_TYPE_GHM = 'ghm';
export const SELECTOR_TYPE_AGE = 'age';



export const BENCHMARK_LETTERS = {
    [SELECTOR_TYPE_ETS_A]: 'a',
    [SELECTOR_TYPE_ETS_B]: 'b',
    [SELECTOR_TYPE_FR]: 'fr',
}