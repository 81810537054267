import React from "react";
import PropTypes from "prop-types";
import Trans from "components/atoms/Format/Trans";

const GeoLib = ({ tradKey, className = "", lib }) => {
  const formatLib = () => {
    const split = lib.split("|");
    const library = split[0]?.trim() || "";
    const count = split.slice(1).filter((item) => item.trim() !== "").length;

    if (count > 0) {
      return (
        <span>
          {library} + <br />
          {count} <Trans k={tradKey} count={count} />
        </span>
      );
    }

    return lib;
  };

  return <span className={className}>{formatLib()}</span>;
};

GeoLib.propTypes = {
  lib: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default GeoLib;
