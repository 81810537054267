/* eslint-disable react/style-prop-object */
import React, {Component}  from 'react'
import FormattedNumberWithPlaceholder from 'components/atoms/Format/FormattedNumberWithPlaceholder'
import PropTypes from 'prop-types';

export default class Currency extends Component {
    render() {
        return <FormattedNumberWithPlaceholder {...this.props} styleNumber="currency" currency="EUR" />
    }
}

Currency.propTypes = {
    value: PropTypes.number.isRequired,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
};
Currency.defaultProps = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};