import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {MapContainer, TileLayer} from 'react-leaflet'

import {navigate} from '../actions/routerActions.js'

import {LOGIN_URL} from "constants/config";
import ReactGA from 'react-ga4'

import {PAGE_NAME_SELECTEUR_ETABLISSEMENT, PARAM_NAME_HASH, PARAM_VALUE_OFF} from 'constants/router.js'
import {
    INITIAL_LAT,
    INITIAL_LNG,
    INITIAL_ZOOM,
    BASE_MAP_URL,
    ATTRIBUTION
} from 'constants/carteConfig.js';

import Trans from 'components/atoms/Format/Trans';
import ErrorPageContent from "components/organisms/ErrorPageContent/ErrorPageContent";

class ErrorPage extends Component {

    navigateToHomepage() {
        this.props.navigate(PAGE_NAME_SELECTEUR_ETABLISSEMENT, {
            [PARAM_NAME_HASH]: PARAM_VALUE_OFF
        })
    }

    getActionButton() {
        let actionButton = (
            <button className="action" onClick={this.navigateToHomepage.bind(this)}>
                <Trans k={'errorPage.retournerAccueil'}/>
            </button>
        )
        if (401 === this.props.status) {
            actionButton = (
                <a className="action" href={LOGIN_URL} target="_blank" rel="noreferrer">
                    <Trans k={'errorPage.connectezVous'}/>
                </a>
            )
        }

        return actionButton
    }

    render() {

        ReactGA.send({ hitType: "pageview", page: 'error/error-' + this.props.status, title: "error page" });

        const position = [INITIAL_LAT, INITIAL_LNG]

        return (
            <div className={'error-page error-fixed error-' + this.props.status}>
                <MapContainer
                    center={position}
                    zoom={(INITIAL_ZOOM - 1)}
                    zoomControl={false}
                    maxZoom={16}>
                    <TileLayer
                        url={BASE_MAP_URL}
                        attribution={ATTRIBUTION}
                        opacity={0.6}
                    />
                </MapContainer>
                <ErrorPageContent title={'errorPage.error' + this.props.status + 'Title'}
                                  description={'errorPage.error' + this.props.status + 'Description'}
                                  actionButton={this.getActionButton()}/>
            </div>
        );
    }
}

ErrorPage.propTypes = {
    navigate: PropTypes.func,
    status: PropTypes.number.isRequired,
}

function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (pageName, params) => {
            dispatch(navigate(pageName, params));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);