import {
    DATAS_PENDING,
    DATAS_FULFILLED,
    DATAS_REJECTED,
    CARTE_ATTRACTIVITE_LOAD_ERROR
} from 'constants/actionTypes';

import {
    DATA_TYPE_ATTRACTIVITE_GRID
} from 'constants/dataType';
import {getUftGridUrl} from '../../managers/windshaftUtilities';

export default function carteGridReducer(state = {
    url: null,
    error: false,
}, action) {
    switch (action.type) {
        case DATAS_PENDING :
        case DATAS_REJECTED : {
            if (DATA_TYPE_ATTRACTIVITE_GRID !== action.dataType) break;

            return {...state, url: null, error: false};
        }
        case DATAS_FULFILLED : {
            if (DATA_TYPE_ATTRACTIVITE_GRID !== action.dataType) break;

            return {...state, url: getUftGridUrl(action.payload.response.layergroupid, action.payload.backend)};
        }
        case CARTE_ATTRACTIVITE_LOAD_ERROR : {
            return {...state, error: true};
        }
        default: break;
    }

    return state;
}