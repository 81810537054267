import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import cn from 'classnames'

import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE} from 'constants/router'
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import Trans from "components/atoms/Format/Trans";
import Percent from 'components/atoms/Format/Percent'
import FormattedDataType from 'components/molecules/Format/FormattedDataType';

class FicheTotalSelection extends Component {

	renderBlockChart(bench, isModeBenchmark, isLeft) {
		const hasAData = this.props.selection[bench] &&
            this.props.selection[bench][this.props.dataType];

		let percentType = "p" + [this.props.dataType]
        const percentValue = hasAData ? this.props.selection[bench][percentType] : 0;
		const value = hasAData ? this.props.selection[bench][this.props.dataType] : 0
		let defaultWidth = (isModeBenchmark) ? 225 : 545
		let width = ((percentValue * 100) * defaultWidth) / 100
		let barStyle = {'width': width}

		if(isLeft) {
			return (
				<div className="fiche-col-barChart left benchmark">
					<div className="barChart-info">
						<div className="percent">
							<Percent value={percentValue} />
						</div>
						<div className="value">
							<FormattedDataType value={value}/>
						</div>
					</div>
					<div className="barChart" style={barStyle}></div>
				</div>
			)
		}

		return (
			<div className={cn((isModeBenchmark)? 'benchmark' : '', "fiche-col-barChart")}>
				<div className="barChart" style={barStyle}></div>
				<div className="barChart-info">
					<div className="percent">
						<Percent value={percentValue} />
					</div>
					<div className="value">
						<FormattedDataType value={value}/>
					</div>
				</div>
			</div>
		)
	}

    render() {
		const etsAVisible = this.props[SELECTOR_TYPE_ETS_A].size > 0
        const etsBVisible = this.props[SELECTOR_TYPE_ETS_B].size > 0
        const isModeBenchmark = etsAVisible && etsBVisible;

        return (
            <div className="fiche-total-selection fiche-accordeon">
				<div className="accordeon-bg">
					<div className="accordeon-container">
						{(isModeBenchmark) ? this.renderBlockChart(SELECTOR_TYPE_ETS_A, true, true) : null}
						<div className="fiche-col-lib">
							<div className="lib">
								<Trans k='fiche.total' />
							</div>
						</div>
						{(etsAVisible && !isModeBenchmark) ? this.renderBlockChart(SELECTOR_TYPE_ETS_A, false, false) : null }
						{(isModeBenchmark) ? this.renderBlockChart(SELECTOR_TYPE_ETS_B, true, false) : null }

					</div>
				</div>
            </div>
        )
    }
}


FicheTotalSelection.propTypes = {
    annee: PropTypes.string,
    dataType: PropTypes.string,
	selection: PropTypes.object
}

function mapStateToProps(state) {
    return {
		[SELECTOR_TYPE_ETS_A]: state.selector[SELECTOR_TYPE_ETS_A],
        [SELECTOR_TYPE_ETS_B]: state.selector[SELECTOR_TYPE_ETS_B],
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(FicheTotalSelection);