import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SelectorNav from 'components/molecules/Selector/SelectorNav';
import { SECTION_NAME_SELECTEUR } from 'constants/router';

class LeftNav extends React.Component {
    render() {
        return (
            <nav id="left-nav">
                <img className="logo" src="assets/images/logo.png" alt="Unicancer"/>
                <SelectorNav
                    isRemoveEnabled={this.props.routing.section === SECTION_NAME_SELECTEUR}
                    activePage={this.props.routing.currentRouteName}
                />
            </nav>
        )
    }
}

LeftNav.propTypes = {
    routing: PropTypes.object
};

function mapStateToProps(state) {
    return {
        routing: state.routing
    };
}

export default connect(mapStateToProps)(LeftNav);
