import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames'
import {useTranslation} from "react-i18next";

const Trans = (props) => {
    const {t} = useTranslation();
    let extraAttributes = {};

    let isCountError = false;
    if (props.hasOwnProperty('count')) {
        isCountError = null === props.count || !isFinite(props.count)
    }

    if (props.hasOwnProperty('title')) {
        if (true === props.title) {
            extraAttributes.title = t(props.k, {...props})
        } else {
            extraAttributes.title = t(props.title, {...props})
        }
    }

    return (
        <span className={cn(props.className, {placeholder: isCountError})}
              {...extraAttributes}>
                {t(props.k, {...props})}
            </span>
    );
};

Trans.propTypes = {
    k: PropTypes.string.isRequired,
    t: PropTypes.func,
    className: PropTypes.string,
    count: PropTypes.number,
    title: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
};

export default Trans;