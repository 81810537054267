import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';

import Trans from 'components/atoms/Format/Trans';

import {
    PAGE_NAME_SELECTEUR_APPAREIL,
    PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE,
    PAGE_NAME_SELECTEUR_GHM,
    PAGE_NAME_SELECTEUR_AGE
} from 'constants/router.js'

import SelectorAddAllBtn from 'components/molecules/Selector/SelectorAddAllBtn'

class SelectorTitle extends PureComponent {

    getTitle() {
        switch (this.props.currentRouteName) {
            case PAGE_NAME_SELECTEUR_APPAREIL:
                return <Trans k="selecteur.titles.appareil"/>
            case PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE:
                return <Trans k="navigation.priseEnCharge"/>
            case PAGE_NAME_SELECTEUR_GHM:
                return <Trans k="navigation.ghm"/>
            case PAGE_NAME_SELECTEUR_AGE:
                return <Trans k="selecteur.titles.age"/>
            default: break;
        }
    }

    render() {
        return (
            <div className="selector-title">
                <div className="title-content">
                    {this.getTitle()}
                </div>
                <SelectorAddAllBtn selectorType={this.props.selectorType} data={this.props.data} />
            </div>
        )

    }
}

SelectorTitle.propTypes = {
    selectorType: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    currentRouteName: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    return {
        currentRouteName: state.routing.currentRouteName
    };
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorTitle);
