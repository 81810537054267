import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'

import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE, PAGE_NAME_FICHE_APPAREIL} from 'constants/router'
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';

import treeMaker from 'managers/treeMaker';
import FicheAppareilItemList from 'components/molecules/FicheAppareils/FicheAppareilsItemList'

class FicheAppareilList extends Component {
	constructor(props) {
		super(props)
		this.percentArrayA = []
		this.percentArrayB = []
	}

	UNSAFE_componentWillMount() {
		this.getTreeData(this.props)
	}

	UNSAFE_componentWillUpdate(nextProps) {
		this.getTreeData(nextProps)
	}

	setSelected(tree, props) {
		let selectedCount = 0
		tree.forEach((item) => {
			if(undefined === item.children){
				if(props.selectorType.includes(item.id)) {
					item.selected = true
					selectedCount++
				} else {
					item.selected = false
				}
			} else {
				const childrenSelected = this.setSelected(item.children, props)
				item.nb_children_selected = childrenSelected
				item.selected = (childrenSelected > 0)
				selectedCount += childrenSelected
			}
		})
		return selectedCount;
	}

	setTreeDatas(tree, props) {
		let dataPage
		let dataPageChildren = null
		let etsAChildDatas = null
		let etsBChildDatas = null

		if(props.route === PAGE_NAME_FICHE_APPAREIL) {
			dataPage = 'appareils'
			dataPageChildren = 'organes'
		} else {
			dataPage = 'priseEnCharge'
		}

		const etsBVisible = props[SELECTOR_TYPE_ETS_B].size > 0
		let etsADatas = props.datas[dataPage].etsA
		let etsBDatas = (etsBVisible)? props.datas[dataPage].etsB : undefined
		let emptyValues = {ps: 0, pv: 0, s: 0, v:0}

		if(null !== dataPageChildren) {
			etsAChildDatas = props.datas[dataPageChildren].etsA
			etsBChildDatas = props.datas[dataPageChildren].etsB
		}
		let percentType = "p" + [props.dataType]

		tree.forEach(item => {
			let datas = item.datas = {}
			let selectedCount = 0
			if(undefined !== etsADatas[item.id]) {
				datas[SELECTOR_TYPE_ETS_A] = etsADatas[item.id]
				this.percentArrayA.push(etsADatas[item.id][percentType])
				if(etsBVisible) {
					if(undefined !== etsBDatas && undefined !== etsBDatas[item.id]) {
						datas[SELECTOR_TYPE_ETS_B] = etsBDatas[item.id]
						this.percentArrayB.push(etsBDatas[item.id][percentType])
					}
				} else {
					datas[SELECTOR_TYPE_ETS_B] = undefined
				}
				if(null !== dataPageChildren) {
					item.children.forEach(child => {
						if(props.selectorType.includes(child.id)) {
							child.selected = true
							selectedCount++
						} else {
							child.selected = false
						}
						let childDatas = child.datas = {}
						if(undefined !== etsAChildDatas[child.id]) {
							childDatas[SELECTOR_TYPE_ETS_A] = etsAChildDatas[child.id]
							childDatas[SELECTOR_TYPE_ETS_B] = (etsBVisible && undefined !== etsBChildDatas[child.id])? etsBChildDatas[child.id] : undefined
						} else {
							childDatas[SELECTOR_TYPE_ETS_A] = emptyValues
							childDatas[SELECTOR_TYPE_ETS_B] = emptyValues
						}
					})
					item.nb_children_selected = selectedCount
					item.selected = (selectedCount > 0)
				} else {
					if(props.selectorType.includes(item.id)) {
						item.selected = true
					} else {
						item.selected = false
					}
				}
			} else {
				datas[SELECTOR_TYPE_ETS_A] = emptyValues
				datas[SELECTOR_TYPE_ETS_B] = emptyValues
				if(null !== dataPageChildren) {
					item.children.forEach(child => {
						if(props.selectorType.includes(child.id)) {
							child.selected = true
							selectedCount++
						} else {
							child.selected = false
						}
						let childDatas = child.datas = {}
						childDatas[SELECTOR_TYPE_ETS_A] = emptyValues
						childDatas[SELECTOR_TYPE_ETS_B] = emptyValues
					})
					item.nb_children_selected = selectedCount
					item.selected = (selectedCount > 0)
				} else {
					if(props.selectorType.includes(item.id)) {
						item.selected = true
					} else {
						item.selected = false
					}
				}
			}
		})
	}

	getTreeData(props) {
        this.tree = treeMaker.getData(props.route)
		this.setTreeDatas(this.tree, props)
    }

    render() {
		let max = {}
		let percentType = "p" + [this.props.dataType]
		const etsBVisible = this.props[SELECTOR_TYPE_ETS_B].size > 0

		let sortingEtsADatas = this.tree.sort((itemA, itemB) => {
			return itemB.datas[SELECTOR_TYPE_ETS_A][percentType] - itemA.datas[SELECTOR_TYPE_ETS_A][percentType]
		})
		// Sort plus haut pourcentage de A et de B
		let sortPercentArrayA = this.percentArrayA.sort((percentA, percentB) => {
			return percentB - percentA
		})
		let maxA = (undefined !== sortPercentArrayA[0]) ? sortPercentArrayA[0] : 0
		max.bench = 'a'
		max.value = maxA
		if (etsBVisible) {
			let sortPercentArrayB = this.percentArrayB.sort((percentA, percentB) => {
				return percentB - percentA
			})
			let maxB = (undefined !== sortPercentArrayB[0]) ? sortPercentArrayB[0] : 0

			if(maxB > maxA) {
				max.bench = 'b'
				max.value = maxB
			}
		}

		let treeItem = sortingEtsADatas.map((item, i) => {
			return (
				<FicheAppareilItemList
					item={item}
					datas={this.props.datas}
					key={item.id}
					classNumber={i + 1}
					max={max}
				/>
			)
		})
        return (
            <div className="fiche-list">
				<ul className={(PAGE_NAME_FICHE_APPAREIL !== this.props.route)? 'prise-en-charge-list' : ''}>
					{treeItem}
				</ul>
            </div>
        )
    }
}

FicheAppareilList.propTypes = {
    annee: PropTypes.string,
    dataType: PropTypes.string,
	datas: PropTypes.object,
	selectorType: PropTypes.object.isRequired,
	route: PropTypes.string.isRequired
}

function mapStateToProps(state, ownProps) {
	let selectedType
	if(state.routing.currentRouteName === PAGE_NAME_FICHE_APPAREIL) {
		selectedType = 'appareil'
	} else {
		selectedType = 'priseEnCharge'
	}
    return {
		[SELECTOR_TYPE_ETS_A]: state.selector[SELECTOR_TYPE_ETS_A],
        [SELECTOR_TYPE_ETS_B]: state.selector[SELECTOR_TYPE_ETS_B],
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
		route: state.routing.currentRouteName,
		selectorType: state.selector[selectedType]
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(FicheAppareilList);