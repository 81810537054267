import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import cn from 'classnames'
import difference from 'lodash/difference'

import {add, remove} from 'actions/selectorActions';

import {isLeaf} from '../../../utils/tree'
import {
    PAGE_NAME_SELECTEUR_ETABLISSEMENT,
} from 'constants/router.js';

import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
} from 'constants/selectorTypes.js';

class SelectorAddAllBtn extends PureComponent {

    constructor(props) {
        super(props)

        this.visibleLeafIds = [];
        this.allSelected = false;
    }

    getVisibleLeafIds(tree, ids = []) {
        tree.forEach(item => {
            if (isLeaf(item)) {
                if ((null !== this.props.inputTerm && item.beingSearched) || (null === this.props.inputTerm)) {
                    ids.push(item.id)
                }
            }
            else {
                this.getVisibleLeafIds(item.children, ids)
            }
        })
        return ids
    }

    toggleSelectorItem(selectorType) {
        if (this.props.currentRouteName === PAGE_NAME_SELECTEUR_ETABLISSEMENT) {
            const otherType = this.getSelectorBenchOtherType(selectorType)
            if (this.allSelected) {
                this.props.removeSelectorItem(this.visibleLeafIds, selectorType)
            } else {
                this.props.removeSelectorItem(this.visibleLeafIds, otherType)
                this.props.addSelectorItem(this.visibleLeafIds, selectorType)
            }

            return true
        }

        if (this.allSelected) {
            this.props.removeSelectorItem(this.visibleLeafIds, selectorType)
        } else {
            this.props.addSelectorItem(this.visibleLeafIds, selectorType)
        }
    }

    getSelectorBenchOtherType(selectorType) {
        const otherType = {
            [SELECTOR_TYPE_ETS_A]: SELECTOR_TYPE_ETS_B,
            [SELECTOR_TYPE_ETS_B]: SELECTOR_TYPE_ETS_A,
        }
        return otherType[selectorType]
    }

    isAllSelected() {
        this.visibleLeafIds = this.getVisibleLeafIds(this.props.data)
        const selectorTypeIds = this.props.selectorTypeIds.toJS()
        const diff = difference(this.visibleLeafIds, selectorTypeIds)
        return (0 === diff.length)
    }

    renderIcon() {
        if (null !== this.props.customIcon) {
            return this.props.customIcon
        }
        if (this.allSelected) {
            return <div className="icon icon-moins"/>
        }
        else {
            return <div className="icon icon-plus"/>
        }
    }

    render() {
        this.allSelected = this.isAllSelected()
        return (
            <div className={cn('title-btn', 'btn', 'btn-' + this.props.selectorType, {selected: this.allSelected})}
                 onClick={this.toggleSelectorItem.bind(this, this.props.selectorType)}>
                {this.renderIcon()}
            </div>
        )
    }
}

SelectorAddAllBtn.propTypes = {
    data: PropTypes.object.isRequired,
    selectorType: PropTypes.string.isRequired,
    customIcon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
            //PropTypes.component,
    ]
    ),
    inputTerm: PropTypes.string,
    selectorTypeIds: PropTypes.object,
    addSelectorItem: PropTypes.func,
    removeSelectorItem: PropTypes.func,
    currentRouteName: PropTypes.string
};

SelectorAddAllBtn.defaultProps = {
    customIcon: null
}

function mapStateToProps(state, ownProps) {
    let selecType = ownProps.selectorType
    return {
        inputTerm: state.mainSelector.inputTerm,
        selectorTypeIds: state.selector[selecType],
        currentRouteName: state.routing.currentRouteName
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addSelectorItem: (ids, type) => {
            dispatch(add(ids, type));
        },
        removeSelectorItem: (ids, type) => {
            dispatch(remove(ids, type));
        }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(SelectorAddAllBtn);