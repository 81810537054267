import {connect} from 'react-redux'
import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames'

import {isLeaf} from '../../../utils/tree.js'

import {add, remove} from 'actions/selectorActions';
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
    SELECTOR_TYPE_APPAREIL,
    SELECTOR_TYPE_PRISE_EN_CHARGE,
    SELECTOR_TYPE_GHM,
    SELECTOR_TYPE_AGE
} from 'constants/selectorTypes.js';

import {
    PAGE_NAME_SELECTEUR_ETABLISSEMENT,
    PAGE_NAME_SELECTEUR_APPAREIL,
    PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE,
    PAGE_NAME_SELECTEUR_GHM,
    PAGE_NAME_SELECTEUR_AGE
} from 'constants/router.js';

import SelectorCounter from 'components/atoms/Selector/SelectorCounter';

class SelectorItem extends React.Component {

    throwAction(item, selectorType, e) {
        let ids = this.getChildrenIds(item)

        // hack switch A/B
        if (this.props.routeName === PAGE_NAME_SELECTEUR_ETABLISSEMENT) {
            const otherType = this.getSelectorBenchOtherType(selectorType)
            if (otherType === item.selected) {
                this.props.removeSelectorItem(ids, otherType)
                this.props.addSelectorItem(ids, selectorType)
                return true
            }
        }

        if (false === item.selected) {
            this.props.addSelectorItem(ids, selectorType)
        } else {
            this.props.removeSelectorItem(ids, selectorType)
        }
    }

    getSelectorBenchOtherType(selectorType) {
        const otherType = {
            [SELECTOR_TYPE_ETS_A]: SELECTOR_TYPE_ETS_B,
            [SELECTOR_TYPE_ETS_B]: SELECTOR_TYPE_ETS_A,
        }
        return otherType[selectorType]
    }

    getChildrenIds(item, ids = []) {
        if (isLeaf(item)) {
            ids.push(item.id)
        }
        else {
            item.children.forEach(item => {
                this.getChildrenIds(item, ids)
            })
        }

        return ids
    }

    renderAccordeonBtn(item) {
        if (isLeaf(item))
            return null
        return (
            <div className="accordeon-btn">
                <div className="icon icon-arrow-down"/>
            </div>
        )
    }

    renderAccordeon(item) {
        if (isLeaf(item) || false === item.expanded)
            return null

        let childItem = item.children.map(item => {
            // Si recherche active et item.beingSearched à false
            if (this.props.inputTerm !== null) {
                if (item.beingSearched === true) {
                    return this.renderItem(item)
                } else {
                    return null
                }
            } else {
                return this.renderItem(item)
            }
        })
        return (
            <div className="accordeon-content">
                {childItem}
            </div>
        )
    }

    renderBenchmarkBtn(item) {
        if (this.props.routeName === PAGE_NAME_SELECTEUR_ETABLISSEMENT && isLeaf(item)) {
            return (
                <div className="benchmark-btn">
                    <div
                        className={cn('btn', 'btn-' + SELECTOR_TYPE_ETS_A, {selected: (SELECTOR_TYPE_ETS_A === item.selected)})}
                        onClick={this.throwAction.bind(this, item, SELECTOR_TYPE_ETS_A)}>
                        <span>A</span>
                    </div>
                    <div
                        className={cn('btn', 'btn-' + SELECTOR_TYPE_ETS_B, {selected: (SELECTOR_TYPE_ETS_B === item.selected)})}
                        onClick={this.throwAction.bind(this, item, SELECTOR_TYPE_ETS_B)}>
                        <span>B</span>
                    </div>
                </div>
            )
        }

        return null
    }

    renderAddBtn(item) {
        let listType = this.props.routeName
        let selectorType = ''
        if (listType === PAGE_NAME_SELECTEUR_ETABLISSEMENT)
            return null
        switch (listType) {
            case PAGE_NAME_SELECTEUR_APPAREIL : {
                selectorType = SELECTOR_TYPE_APPAREIL
                break
            }
            case PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE: {
                selectorType = SELECTOR_TYPE_PRISE_EN_CHARGE
                break
            }
            case PAGE_NAME_SELECTEUR_GHM : {
                selectorType = SELECTOR_TYPE_GHM
                break
            }
            case PAGE_NAME_SELECTEUR_AGE : {
                selectorType = SELECTOR_TYPE_AGE
                break
            }
            default : {
                selectorType = this.props.routeName
            }
        }
        return (
            <div
                className={cn("add-btn", (item.selected === true) ? "selected" : "")}
                onClick={this.throwAction.bind(this, item, selectorType)}
            >
                {(item.selected === false) ? <div className="icon icon-plus"/> : <div className="icon icon-moins"/>}
            </div>
        )
    }

    /*
     *  Affiche le parent, ou le child possedant lui même des children
     */
    renderItem(item) {
        let mainClassName = (isLeaf(item)) ? "selector-item-last" : "selector-item-main"

        return (
            <div
                className={cn(mainClassName, {
                    active: item.expanded,
                    'current-search': (true === item.beingSearched),
                })}
                style={item.style}
                key={item.id}>
                <div
                    className={"selector-item"}
                    key={item.id}>
                    <div
                        className={cn("selector-item-clickable", {
                            'has-benchmark': (this.props.routeName === PAGE_NAME_SELECTEUR_ETABLISSEMENT && isLeaf(item)),
                            'full': (this.props.routeName === PAGE_NAME_SELECTEUR_ETABLISSEMENT && !isLeaf(item)),
                        })}
                        onClick={this.props.toggleRow.bind(this, item)}>

                        {this.renderAccordeonBtn(item)}

                        <div className={"item-content"}>
                            <div className="item-lib" title={item.lib}>
                                {item.lib}
                            </div>
                            <SelectorCounter item={item}/>
                        </div>
                    </div>
                    {this.renderBenchmarkBtn(item)}
                    {this.renderAddBtn(item)}
                </div>

                {this.renderAccordeon(item)}

            </div>
        )
    }

    render() {
        return this.renderItem(this.props)
    }
}

SelectorItem.propTypes = {
    toggleRow: PropTypes.func.isRequired,
    inputTerm: PropTypes.string,
    routeName: PropTypes.any,
    addSelectorItem: PropTypes.func,
    removeSelectorItem: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        routeName: state.routing.currentRouteName,
        selector: state.selector,
        inputTerm: state.mainSelector.inputTerm
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addSelectorItem: (ids, type) => {
            dispatch(add(ids, type));
        },
        removeSelectorItem: (ids, type) => {
            dispatch(remove(ids, type));
        }
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(SelectorItem);