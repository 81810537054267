export const PARAM_VALUE_OFF = 'off';
export const PARAM_VALUE_ON = 'on';
export const PARAM_VALUE_ACCES_TIME_INF = 9999;

export const PARAM_NAME_HASH = 'hash';
export const PARAM_NAME_ANNEE = 'annee';
export const PARAM_NAME_DATA_TYPE = 'dataType';
export const PARAM_NAME_GEO_TYPE = 'geoType';
export const PARAM_NAME_ACCES_TIME = 'accesTime';
export const PARAM_NAME_ZONE = 'zone';
export const PARAM_NAME_GEO_SELECTION = 'geoSelection';
export const PARAM_NAME_PDM = 'geoPdm'

export const SECTION_NAME_SELECTEUR = 'selecteur';
export const SECTION_NAME_FICHE = 'fiche';
export const SECTION_NAME_CARTE = 'carte';

export const PAGE_NAME_SELECTEUR_ETABLISSEMENT = 'selecteur/etablissement';
export const PAGE_NAME_SELECTEUR_APPAREIL = 'selecteur/appareil';
export const PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE = 'selecteur/prise-en-charge';
export const PAGE_NAME_SELECTEUR_GHM = 'selecteur/ghm';
export const PAGE_NAME_SELECTEUR_AGE = 'selecteur/age';
export const PAGE_NAME_FICHE_ETABLISSEMENTS = 'fiche/etablissements';
export const PAGE_NAME_FICHE_APPAREIL = 'fiche/appareils';
export const PAGE_NAME_FICHE_PRISE_EN_CHARGE = 'fiche/prise-en-charge';
export const PAGE_NAME_FICHE_GHM = 'fiche/ghm';
export const PAGE_NAME_FICHE_SEXE_AGE = 'fiche/sexe-age';
export const PAGE_NAME_CARTE_ATTRACTIVITE = 'carte/attractivite';
export const PAGE_NAME_CARTE_CONCURENCE = 'carte/concurence';
