import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { IntlProvider } from "react-intl";
import ReactGA from "react-ga4";

import reportWebVitals from "./reportWebVitals";
import configureAppStore from "./store/configureStore";
import initI18n from "./i18n";
import { GA_TRACKING_ID } from "./constants/analytics.js";
import MainLayout from "./containers/MainLayout";
import token from "./managers/tokenManager.js";
import persistentData from "./managers/persistentData";

import "./styles/styles.styl";

const root = ReactDOM.createRoot(document.getElementById("app"));

const store = configureAppStore();

ReactGA.initialize(GA_TRACKING_ID, {
  debug: false,
});

const renderApp = async (i18n) => {
  try {
    await token.load();
    await persistentData.load();
    root.render(
      <I18nextProvider i18n={i18n}>
        <IntlProvider locale="fr">
          <Provider store={store}>
            <MainLayout />
          </Provider>
        </IntlProvider>
      </I18nextProvider>
    );
  } catch (error) {
    root.render(
      <I18nextProvider i18n={i18n}>
        <IntlProvider locale="fr">
          <Provider store={store}>
            <MainLayout tokenError={error} />
          </Provider>
        </IntlProvider>
      </I18nextProvider>
    );
  }
};

initI18n().then(renderApp);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
