import React, { useState } from "react";
import Trans from "components/atoms/Format/Trans";
import token from "managers/tokenManager.js";

const UserDropdown = () => {
  const user = token.getUser();
  let username = "01100111011010";
  if (user !== null) {
    username = user.prenom + " " + user.nom;
  }

  const [isOpened, setIsOpened] = useState(false);

  const handleDropdownClick = () => {
    setIsOpened(!isOpened);
  };

  const handleOptionClick = (value) => {
    window.open(value, "_blank");
    setIsOpened(false);
  };

  const menuItemsToOptions = () => {
    return [
      {
        value: "https://partage.unicancer.fr/sharing/44o9jsWcu",
        label: (
          <div className="options doc">
            <span className="icon icon-info" />
            <Trans k="navigation.documentation" title={true} />
          </div>
        ),
      },
      {
        value: "mailto:pdm@unicancer.fr",
        label: (
          <div className="options contact">
            <Trans k="navigation.contact" title={true} />
          </div>
        ),
      },
      {
        value: "https://www.hevaweb.com",
        label:
(        <div className="options by-heva">
          By Heva
        </div>)
      },
    ];
  };

  const options = menuItemsToOptions();

  return (
    <div className={`user-dropdown ${isOpened ? "dropdown--active" : ""}`}>
      <div className="dropdown__trigger" onClick={handleDropdownClick}>
        <span className="icon icon-user" />
        <span className="username">{username}</span>
        <span className="icon icon-arrow-down" />
      </div>
      {isOpened && (
        <div className="dropdown__content">
          {options.map((option) => (
            <div
              key={option.value}
              className={`${
                option.className ? option.className : ""
              }`}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
