export function sortRegion(left, right) {
    const sortAlpha = sortLibAsc(left, right)
    const isLeftDom = '0' === left.code.substr(0,1)
    const isRightDom = '0' === right.code.substr(0,1)

    if (isLeftDom && isRightDom) return sortAlpha
    if (isLeftDom) return 1
    if (isRightDom) return -1
    return sortAlpha
}

export function sortLibAsc(left, right) {
    return sortAlphaAsc(left.lib, right.lib)
}

export function sortAlphaAsc(left, right) {
    return left.localeCompare(right)
}
