import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import * as L from "leaflet";

import {
  add,
  remove,
  setSelectedMarker,
  unselectMarker,
} from "actions/selectorActions";
import {
  PAGE_NAME_CARTE_CONCURENCE,
  PARAM_NAME_ANNEE,
  PARAM_NAME_DATA_TYPE,
  PARAM_NAME_PDM,
} from "constants/router";
import { toggleActiveEtablissement } from "actions/carteActions";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
} from "constants/selectorTypes";

const MarkerItem = ({ map, marker, style }) => {
  const dispatch = useDispatch();

  const {
    route,
    selectedMarker,
    etabs,
    hasCluster,
    selectedMarkerConcurrence,
    pdmMax,
    annee,
    geoPdm,
    dataType,
    etsA,
    etsB,
  } = useSelector((state) => {
    if (state.routing.currentRouteName !== PAGE_NAME_CARTE_CONCURENCE) {
      return {
        etsA: state.selector[SELECTOR_TYPE_ETS_A],
        etsB: state.selector[SELECTOR_TYPE_ETS_B],
        route: state.routing.currentRouteName,
        selectedMarker: state.mainSelector.selectedMarker,
      };
    } else {
      return {
        etabs: state.carteConcurrence.datas,
        etsA: state.selector[SELECTOR_TYPE_ETS_A],
        etsB: state.selector[SELECTOR_TYPE_ETS_B],
        route: state.routing.currentRouteName,
        hasCluster: state.carteConcurrence.hasCluster,
        selectedMarkerConcurrence: state.carteConcurrence.selectedMarker,
        selectedMarker: state.mainSelector.selectedMarker,
        pdmMax: state.carteConcurrence.pdmMax,
        annee: state.routing.params[PARAM_NAME_ANNEE],
        geoPdm: state.routing.params[PARAM_NAME_PDM],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
      };
    }
  });

  const [markerIsSelected, setMarkerIsSelected] = useState(null);
  const [etsIsSelected, setEtsIsSelected] = useState([]);

  useEffect(() => {
    let nouveauTableau = [];

    etsA.forEach((valeur) => {
      if (!nouveauTableau.find((item) => item.id === valeur)) {
        nouveauTableau.push({ id: valeur, type: "etsA" });
      }
    });

    etsB.forEach((valeur) => {
      if (!nouveauTableau.find((item) => item.id === valeur)) {
        nouveauTableau.push({ id: valeur, type: "etsB" });
      }
    });

    setEtsIsSelected(nouveauTableau);
  }, [etsA, etsB, map]);

  const handleAddSelectorItem = (ids, type) => {
    dispatch(add(ids, type));
    setEtsIsSelected((prev) => prev.concat({ id: ids, type: type }));
  };

  const handleRemoveSelectorItem = (ids, type) => {
    dispatch(remove(ids, type));
    setEtsIsSelected((prev) => prev.filter((item) => item.id !== ids));
  };

  const handleSetSelectedMarker = (id) => {
    dispatch(setSelectedMarker(id));
    setMarkerIsSelected(id);
  };

  const handleUnselectMarker = (bool) => {
    dispatch(unselectMarker(bool));
    setMarkerIsSelected(null);
  };

  const handleToggleActiveEtablissement = (etsId, benchType) => {
    if (route === PAGE_NAME_CARTE_CONCURENCE) {
      dispatch(toggleActiveEtablissement(etsId, benchType));
    }
  };

  const selectedMarkerFromRoute =
    route === PAGE_NAME_CARTE_CONCURENCE
      ? selectedMarkerConcurrence
      : selectedMarker;
  const benchType = marker.options.selectorType || etsIsSelected.find((item) => item.id === marker.options.id)?.type;
  const benchClassName =
    null === benchType ? "bench-default" : "bench-" + benchType;
  const hasStyle = undefined === style ? {} : style;
  let haloOptions = null;
  const hasHalo = haloOptions === null ? "" : haloOptions;
  let mapUnselectMarker = false;

  useEffect(() => {
    addMapEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addMapEvents = () => {
    if (route === PAGE_NAME_CARTE_CONCURENCE) {
      map.off("click").on("click", () => {
        if (selectedMarkerConcurrence !== null) {
          mapUnselectMarker = selectedMarkerConcurrence;
          handleToggleActiveEtablissement(false);
        } else {
          mapUnselectMarker = false;
        }
      });
    }
    if (route !== PAGE_NAME_CARTE_CONCURENCE) {
      map.off("zoomstart click").on("zoomstart click", () => {
        if (selectedMarker !== null) {
          map.closePopup();
          handleUnselectMarker(null);
          setMarkerIsSelected(null);
        }
      });
      map.off("popupclose").on("popupclose", () => {
        if (selectedMarker !== null || markerIsSelected !== null) {
          handleUnselectMarker(null);
          setMarkerIsSelected(null);
        }
      });
    }
  };

  const removeAction = (item, selectorType, e) => {
    map.closePopup();
    handleUnselectMarker(null);
    setMarkerIsSelected(null);
    let ids = [];
    ids.push(item.options.id);

    handleRemoveSelectorItem(ids, selectorType);
    setEtsIsSelected((prev) => prev.filter((item) => item.id !== ids));
  };

  const addAction = (item, selectorType, e) => {
    map.closePopup();
    handleUnselectMarker(null);
    setMarkerIsSelected(null);
    let ids = [];
    ids.push(item.options.id);
    if (
      etsIsSelected.find(
        (ets) => ets.id === item.options.id && ets.type !== selectorType
      )
    ) {
      return;
    }
    handleAddSelectorItem(ids, selectorType);
    setEtsIsSelected((prev) => prev.concat({ id: ids, type: selectorType }));
  };

  const renderPopup = (item) => {
    let classA = cn("popup-btn", "btn-group-A", {
      selected:
        etsIsSelected &&
        etsIsSelected.find(
          (ets) =>
            ets.id === item.options.id && ets.type === SELECTOR_TYPE_ETS_A
        ),
    });
    let classB = cn("popup-btn", "btn-group-B", {
      selected:
        etsIsSelected &&
        etsIsSelected.find(
          (ets) =>
            ets.id === item.options.id && ets.type === SELECTOR_TYPE_ETS_B
        ),
    });

    return (
      <div className="custom-popup-container">
        <div className="popup-etab">
          <div className="icon" />
          <div className="etab-lib">{item.options.title}</div>
        </div>
        <div className="popup-btn-group">
          <div className={classA}>
            <div
              className="btn-a"
              onClick={() => addAction(item, SELECTOR_TYPE_ETS_A)}
            >
              A
            </div>
            <div
              className="icon-delete"
              onClick={() => removeAction(item, SELECTOR_TYPE_ETS_A)}
            />
          </div>
          <div className={classB}>
            <div
              className="btn-b"
              onClick={() => addAction(item, SELECTOR_TYPE_ETS_B)}
            >
              B
            </div>
            <div
              className="icon-delete"
              onClick={() => removeAction(item, SELECTOR_TYPE_ETS_B)}
            />
          </div>
        </div>
      </div>
    );
  };

  const setPopup = (item, style) => {
    const popupContent = renderPopup(item);
    const div = document.createElement("div");
    ReactDOM.createRoot(div).render(popupContent);

    let latlng = item.position;
    let options = {
      offset: L.point(0, -40),
    };
    if (Object.keys(style).length !== 0) {
      options.offset = L.point(parseInt(style.left), -15 + parseInt(style.top));
    }
    map.openPopup(div, latlng, options);
  };

  const handleClick = (item, style) => {
    if (route === PAGE_NAME_CARTE_CONCURENCE) {
      if (item.options.id !== mapUnselectMarker) {
        handleToggleActiveEtablissement(
          item.options.id,
          item.options.selectorType
        );
      }
    } else {
      if (selectedMarker !== null && selectedMarker === item.options.id) {
        handleUnselectMarker(null);
        return;
      }
      setPopup(item, style);
      handleSetSelectedMarker(item.options.id);
    }
    return false;
  };

  const renderSelectedMarker = (benchClassName, number, style, haloOptions) => {
    return (
      <div
        className={"leaflet-div-icon-selected " + haloOptions.class}
        onClick={() => handleClick(marker, style)}
      >
        <svg width="38px" height="48px" viewBox="0 0 38 48">
          <defs>
            <path
              d="M13.6019873,37.859325 C13.0830979,37.4722852 0.897,28.2433373 0.897,16.0020799 C0.897,7.6642235 7.76545699,0.6205 15.897,0.6205 C24.0275677,0.6205 30.897,7.6642235 30.897,16.0020799 C30.897,28.2433373 18.7099267,37.4722852 18.1920127,37.859325 L15.897,39.5705008 L13.6019873,37.859325 Z"
              id="marker-selected-iocn-path-1"
            />
            <filter
              x="-50%"
              y="-50%"
              width="200%"
              height="200%"
              filterUnits="objectBoundingBox"
              id="marker-selected-icon-filter"
            >
              <feOffset
                dx="0"
                dy="0"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                stdDeviation="2"
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                type="matrix"
                in="shadowBlurOuter1"
              />
            </filter>
          </defs>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(3.000000, 4.000000)">
              <g>
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#marker-selected-icon-filter)"
                  xlinkHref="#marker-selected-iocn-path-1"
                />
              </g>
              <path
                className={benchClassName}
                d="M13.6019873,37.859325 C13.0830979,37.4722852 0.897,28.2433373 0.897,16.0020799 C0.897,7.6642235 7.76545699,0.6205 15.897,0.6205 C24.0275677,0.6205 30.897,7.6642235 30.897,16.0020799 C30.897,28.2433373 18.7099267,37.4722852 18.1920127,37.859325 L15.897,39.5705008 L13.6019873,37.859325 L13.6019873,37.859325 Z"
                stroke="#FFFFFF"
                strokeWidth="3"
              />
              <path
                d="M24,16 C24,20.4187983 20.4187983,24 16,24 C11.5812017,24 8,20.4187983 8,16 C8,11.5812017 11.5812017,8 16,8 C20.4187983,8 24,11.5812017 24,16"
                fill="#FFFFFF"
              />
              <text
                className={benchClassName}
                fontFamily="SourceSansPro-Bold, Source Sans Pro"
                fontSize="12"
                fontWeight="bold"
              >
                <tspan x="13" y="20">
                  {number}
                </tspan>
              </text>
            </g>
          </g>
        </svg>
        <div className="halo" style={haloOptions.style} />
      </div>
    );
  };

  const renderUnselectedMarker = (
    benchClassName,
    number,
    style,
    haloOptions
  ) => {
    return (
      <div
        className={"leaflet-div-icon " + haloOptions.class}
        onClick={() => handleClick(marker, style)}
      >
        <svg width="38px" height="48px" viewBox="0 0 38 48">
          <defs>
            <path
              d="M22.0200195,11.379 C22.0200195,21.724 11.0205029,30 11.0205029,30 C11.0205029,30 0.0200195312,21.724 0.0200195312,11.379 C0.0200195312,5.094 5.20899971,0 11.0205029,0 C16.8310393,0 22.0200195,5.094 22.0200195,11.379"
              id="marker-unselected-icon-path-1"
            />
            <filter
              x="-50%"
              y="-50%"
              width="200%"
              height="200%"
              filterUnits="objectBoundingBox"
              id="marker-unselected-icon-filter"
            >
              <feOffset
                dx="0"
                dy="0"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                stdDeviation="2"
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
                type="matrix"
                in="shadowBlurOuter1"
              />
            </filter>
          </defs>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(7.000000, 9.000000)">
              <g>
                <use
                  fill="black"
                  fillOpacity="1"
                  filter="url(#marker-unselected-icon-filter)"
                  xlinkHref="#marker-unselected-icon-path-1"
                />
                <use
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  xlinkHref="#marker-unselected-icon-path-1"
                />
              </g>
              <path
                className={benchClassName}
                d="M19,11 C19,15.4187983 15.4187983,19 11,19 C6.58120167,19 3,15.4187983 3,11 C3,6.58120167 6.58120167,3 11,3 C15.4187983,3 19,6.58120167 19,11"
                fill="#D8E3F0"
              />
              <text
                className={benchClassName}
                fontFamily="SourceSansPro-Bold, Source Sans Pro"
                fontSize="12"
                fontWeight="bold"
                fill="#FFFFFF"
              >
                <tspan x="8" y="15">
                  {number}
                </tspan>
              </text>
            </g>
          </g>
        </svg>
        <div className="halo" style={haloOptions.style} />
      </div>
    );
  };

  const setHaloOptions = (marker) => {
    const defaultSize = 240;
    const classes = {
      Privé: "type-private",
      CLCC: "type-clcc",
      CHU: "type-chu",
      ESPIC: "type-espic",
    };
    const etabPdm = etabs[marker.id]["pdm_" + geoPdm][annee][dataType];
    let size;
    if (pdmMax === etabPdm) {
      size = defaultSize;
    } else {
      size = (etabPdm * 100 * defaultSize) / (pdmMax * 100);
    }
    return {
      style: { width: size, height: size },
      class: classes[marker.cat],
    };
  };

  if (route === PAGE_NAME_CARTE_CONCURENCE) {
    if (hasCluster === false) {
      haloOptions = setHaloOptions(marker.options);
    }
  }

  return selectedMarkerFromRoute === marker.options.id 
    ? renderSelectedMarker(benchClassName, "", hasStyle, hasHalo)
    : renderUnselectedMarker(benchClassName, "", hasStyle, hasHalo);
};

MarkerItem.propTypes = {
  map: PropTypes.object.isRequired,
  marker: PropTypes.object.isRequired,
  style: PropTypes.object,
};

export default MarkerItem;
