import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames'

export default class SelectorNavCounter extends React.Component {
    onClickHandler(e){
        if(this.props.onClickHandler){
            this.props.onClickHandler(e);
        }
    }
    getClassName(){
        return cn('counter', this.props.type, {
            'empty': this.props.isEmpty,
            'remove-enabled': this.props.isRemoveEnabled,
        });
    }
    render() {

        return (
            <div className={this.getClassName()}
                 onClick={this.onClickHandler.bind(this)}>
                 <span className="lib">
                     {this.props.lib}
                 </span>
                <span className="icon icon-delete" />
            </div>
        );
    }
}

SelectorNavCounter.propTypes = {
    type: PropTypes.string.isRequired,
    lib: PropTypes.any.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    isRemoveEnabled: PropTypes.bool.isRequired,
    onClickHandler: PropTypes.func
};
