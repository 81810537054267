import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Set } from "immutable";

import { AutoSizer, Column, SortDirection, Table } from "react-virtualized";
import "react-virtualized/styles.css";

import persistentData from "managers/persistentData.js";

import {
  DATA_FILTER_CATEGORY_ALL,
  DATA_FILTER_DEPARTEMENT_ALL,
  DATA_FILTER_INITIAL_STATE,
  DATA_FILTER_SELECTION_IN,
  DATA_FILTER_TYPE_SEJOUR,
} from "constants/dataFilterType";

import SortableHeader from "components/atoms/List/SortableHeader";
import FormattedDataType from "components/molecules/Format/FormattedDataType";
import Percent from "components/atoms/Format/Percent";
import { sortAlphaAsc } from "../../../utils/sort";

export default class ListEtablissements extends Component {
  constructor(props, context) {
    super(props, context);

    this.etablissementsList = null;
    this.diplayedList = null;

    this.state = {
      sortBy: "value_" + this.props.annee,
      sortDirection: SortDirection.DESC,
      ...DATA_FILTER_INITIAL_STATE,
    };
  }

  getEtablissementsList() {
    return this.etablissementsList;
  }

  setEtablissementsList(etablissementsList) {
    this.etablissementsList = etablissementsList;

    this.categoriesList = new Set();
    this.departementsList = new Set();

    if (null !== this.etablissementsList) {
      this.etablissementsList.forEach((ets) => {
        this.categoriesList = this.categoriesList.add(ets.cat);
        this.departementsList = this.departementsList.add(ets.lib_dep);
      });

      this.categoriesList = this.categoriesList.sort(sortAlphaAsc);
      this.departementsList = this.departementsList.sort(sortAlphaAsc);
    }
  }

  getFilteredEtablissementsList() {
    const sortByUpdate = this.state.sortBy || "value_" + this.props.annee;
    const sortDirectionUpdate = this.state.sortDirection || SortDirection.DESC;
    let filteredList = this.getEtablissementsList();

    filteredList
      .sortBy((item) => -item["value_" + this.props.annee])
      .forEach((item, index) => {
        item.rank = index + 1;
      });

    if (DATA_FILTER_SELECTION_IN === this.state.filterSelection) {
      filteredList = filteredList.filter((item) => null !== item.bench);
    }
    if (DATA_FILTER_CATEGORY_ALL !== this.state.filterCategory) {
      filteredList = filteredList.filter(
        (item) => item.cat === this.state.filterCategory
      );
    }
    if (DATA_FILTER_DEPARTEMENT_ALL !== this.state.filterDepartement) {
      filteredList = filteredList.filter(
        (item) => item.lib_dep === this.state.filterDepartement
      );
    }

    const sortedList = filteredList
      .sortBy((item) => {
        if (undefined === item[sortByUpdate]) return -1;
        return item[sortByUpdate];
      })
      .update((list) => {
        return sortDirectionUpdate === SortDirection.DESC 
          ? list.reverse()
          : list;
      });

    this.diplayedList = sortedList;
    return sortedList;
  }

  getColumns() {
    return this.getColumnsConf().map((column) => {
      return (
        <Column
          key={column.dataKey}
          className={cn({ active: column.dataKey === this.state.sortBy })}
          {...column}
        />
      );
    });
  }

  getColumnDefault(extraParams = {}) {
    return {
      width: 100,
      flexGrow: 1,
      disableSort: false,
      headerRenderer: this.sortableHeaderRenderer.bind(this, extraParams),
    };
  }

  getColumnGeo() {
    return {
      ...this.getColumnDefault(),
      width: 100,
      flexGrow: 2,
      dataKey: "lib_dep",
      label: "fiche.etablissements.header.departement",
    };
  }

  getColumnsValue() {
    const dataTypeTranKey =
      DATA_FILTER_TYPE_SEJOUR === this.props.dataType
        ? "dataType.sejours-seances_plural"
        : "dataType.valorisation";

    return Object.values(persistentData.get("annees"))
      .reverse()
      .map((annee, index) => {
        const isFirst = 0 === index;
        return {
          ...this.getColumnDefault({
            dataDesc: { annee },
          }),
          width: 88,
          flexGrow: 0,
          dataKey: "value_" + annee.id,
          label: dataTypeTranKey,
          cellRenderer: this.cellValueRenderer.bind(this),
          headerClassName:
            "header-multiline header-value " + (isFirst ? "first" : "other"),
        };
      });
  }

  sortableHeaderRenderer(params, extraParams) {
    return (
      <SortableHeader
        {...params}
        {...extraParams}
        sort={this.sort.bind(this)}
        initialSortBy={this.state.sortBy || "value_" + this.props.annee}
        initialDirection={this.state.sortDirection || "DESC"}
      />
    );
  }

  sort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  cellValueRenderer(params) {
    return (
      <div className="cell-number">
        <FormattedDataType value={params.cellData} />
      </div>
    );
  }

  cellPdmRenderer(params) {
    return (
      <div className="cell-number">
        <Percent value={params.cellData} />
      </div>
    );
  }

  rowClassName({ index }) {
    const item = this.diplayedList.get(index);
    if (undefined === item) return false;
    const benchClass = null !== item.bench ? "bench-" + item.bench : null;
    return cn(benchClass, {
      even: index % 2 === 0,
      odd: index % 2 !== 0,
    });
  }

  renderTable() {
    const etablissementsList = this.getFilteredEtablissementsList();

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={60}
            rowHeight={48}
            rowClassName={this.rowClassName.bind(this)}
            rowCount={etablissementsList.size}
            rowGetter={({ index }) => etablissementsList.get(index)}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
          >
            {this.getColumns()}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

ListEtablissements.propTypes = {
  annee: PropTypes.string,
  dataType: PropTypes.string,
};
