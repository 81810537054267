import React from 'react';
import PropTypes from "prop-types";
import Percent from "components/atoms/Format/Percent";
import {DATA_FILTER_TYPE_VALO} from 'constants/dataFilterType';
import {SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import Trans from "components/atoms/Format/Trans";
import FormattedDataType from "components/molecules/Format/FormattedDataType";

export default class CarteInfosEtablissementBenchmark extends React.Component {
    getLibTrans(){
        let groupe = 'A';
        if(SELECTOR_TYPE_ETS_B === this.props.benchType){
            groupe = 'B';
        }
        let transKey = "carteInfos.pourcentage-des-sejours-du-groupe";
        if(DATA_FILTER_TYPE_VALO === this.props.dataType) {
            transKey = "carteInfos.pourcentage-de-la-valorisation-du-groupe";
        }
        return (
            <Trans
                k={transKey}
                groupe={groupe}
            />
        );
    }
    render(){
        return (
            <div className="carte-infos-etablissement-values carte-infos-etablissement-benchmark">
                <div className="part-bench-value">
                    <Percent value={this.props.datas.partBench} isEvolution={true} />
                </div>
                <div className="content">
                    <div className="lib">
                        {this.getLibTrans()}
                    </div>
                    <div className="part-other-bench">
                        <div className="part-bench" style={{width: (this.props.datas.partBench * 100) + '%'}} />
                    </div>
                    <div className="value-evol">
                        <div className="value">
                            <FormattedDataType value={this.props.datas.value} withLib={true} />
                        </div>
                        <div className="evol">
                            <Trans k="dataType.evolution" /> : <Percent value={this.props.datas.evol} isEvolution={true} className="number" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
CarteInfosEtablissementBenchmark.propTypes = {
    datas: PropTypes.object.isRequired,
    benchType: PropTypes.string.isRequired,
    dataType: PropTypes.string.isRequired
};