export const DATA_TYPE_ATTRACTIVITE_GRID = 'carteGrid';
export const DATA_TYPE_ATTRACTIVITE_TILES = 'carteTiles';
export const DATA_TYPE_CARTE_INFOS_FRANCE = 'carteInfosFrance';
export const DATA_TYPE_FICHE_ETABLISSEMENTS = 'ficheEtablissements';
export const DATA_TYPE_FICHE_ETABLISSEMENTS_PDM = 'ficheEtablissementsPdm';
export const DATA_TYPE_FICHE_APPAREILS = 'ficheAppareils';
export const DATA_TYPE_FICHE_SEXE_AGE = 'ficheSexeAge';
export const DATA_TYPE_FICHE_GHM = 'ficheGhm';
export const DATA_TYPE_FICHE_GHM_DETAIL = 'ficheGhmDetail';
export const DATA_TYPE_FICHE_PRISE_EN_CHARGE = 'fichePriseEnCharge';
export const DATA_TYPE_CARTE_CONCURRENCE = 'carteConcurrence'
