import React from "react";
import PropTypes from 'prop-types';
import cn from 'classnames';
import FormattedDataType from 'components/molecules/Format/FormattedDataType';

export default class Legende extends React.Component {
    renderTopLegend() {
        let list = [];
        let classesLenght = this.props.classes.length
        if(classesLenght === 1) {
            const value = this.props.classes;
            list.push(
                <div key={classesLenght} className="top-legend-content legend-content">
                    <FormattedDataType value={value} withLib={classesLenght} className={cn('align-left', 'class-c' + (5))} />
                </div>
            );
        } else {
            for (let i = 1; i < classesLenght; i++) {
                if(i ===  (classesLenght - 1)) {
                    list.push(
                        <div key={i} order={1} className="top-legend-content legend-content">
                            <FormattedDataType value={this.props.classes[i]} withLib={true} className={cn('align-left', 'class-c' + (classesLenght + (6 - classesLenght) - i))} />
                            <FormattedDataType value={this.props.classes[i - 1]} className={cn('align-right', 'class-c' + (classesLenght + (6 - classesLenght) - i))} />
                        </div>
                    )
                } else {
                    list.push(
                        <div key={i} order={classesLenght + (6 - classesLenght) - i} className="top-legend-content legend-content">
                            {/* <FormattedDataType value={value} className={cn('align-left', 'class-c' + (classesLenght + (6 - classesLenght) - i))} /> */}
                            <FormattedDataType value={this.props.classes[i - 1]} className={cn('align-right', 'class-c' + (classesLenght + (6 - classesLenght) - i))} />
                        </div>
                    );
                }
            }
            list.sort((a, b) => {
                return a.props.order - b.props.order
            })
        }

        return list;
    }
    renderBottomLegend() {
        let list = [];
        let classesLenght = this.props.classes.length
        if(classesLenght === 1) {
            list.push(
                <div key={classesLenght} className={cn('bottom-legend-content legend-content', 'class-c' + (classesLenght + 4))} />
            );
        }  else {
            for (let i = 1; i < classesLenght; i++) {
                list.push(
                    <div key={i} order={classesLenght + (6 - classesLenght) - i} className={cn('bottom-legend-content legend-content', 'class-c' + (classesLenght + (6 - classesLenght) - i))} />
                );
            }
            list.sort((a, b) => {
                return a.props.order - b.props.order
            })
        }

        return list;
    }
    render() {
        if(null == this.props.classes){
            return <div className="carte-legend carte-attarctivite-legend" />;
        }
        return (
            <div className={cn('carte-legend carte-attarctivite-legend', 'bench-' + this.props.benchType)}>
                <div className="top-legend">
                    {this.renderTopLegend()}
                </div>
                <div className="bottom-legend">
                    {this.renderBottomLegend()}
                </div>
            </div>
        );
    }
}
Legende.propTypes = {
    classes: PropTypes.array.isRequired,
    benchType: PropTypes.string.isRequired
};
