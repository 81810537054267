import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Trans from "components/atoms/Format/Trans";

const FilterChoices = ({ item, index, activeItem, onItemClickHandler }) => {
  const handleClick = () => {
    onItemClickHandler(item);
  };

  return (
    <ul className="choices-list">
      <li
        key={item.id}
        className={cn("row", "item", { active: item.id === activeItem })}
        onClick={handleClick}
      >
        <Trans k={item.lib} />
      </li>
    </ul>
  );
};

FilterChoices.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lib: PropTypes.string.isRequired,
  }).isRequired,
  activeItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onItemClickHandler: PropTypes.func.isRequired,
};

export default FilterChoices;
