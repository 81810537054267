import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  PARAM_VALUE_OFF,
  PARAM_NAME_ZONE,
  PARAM_NAME_ACCES_TIME,
  PAGE_NAME_CARTE_ATTRACTIVITE,
} from "constants/router";
import {
  DATA_FILTER_TYPE_ZONE_ALL,
  DATA_FILTER_TYPE_ZONE_CHALANDISE,
  DATA_FILTER_TYPE_ZONE_PROXIMITE,
} from "constants/dataFilterType";
import { navigate } from "actions/routerActions";

import Trans from "components/atoms/Format/Trans";
import AccesTimeLib from "../ZonesFilter/AccesTimeLib";
import { formatAccessTimeParam } from "managers/appRouter";
import ZonesFilter from "../ZonesFilter/ZonesFilter";

class SwitchZone extends React.Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  submitChange(selection) {
    const zone = selection.zone,
      accesTime =
        selection.accesTime.length > 0
          ? selection.accesTime.join(",")
          : PARAM_VALUE_OFF;
    if (
      this.props.selectedZone !== zone ||
      this.props.selectedAccesTime !== accesTime
    ) {
      this.props.navigate(zone, accesTime);
    }
    this.setState({ dropdownOpen: false });
  }

  handleClickOutside = (event) => {
    if (
      this.state.dropdownOpen &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ dropdownOpen: false });
    }
  };

  selectionLib() {
    if (DATA_FILTER_TYPE_ZONE_ALL === this.props.selectedZone) {
      return <Trans k="dataType.touteZone" />;
    }
    if (DATA_FILTER_TYPE_ZONE_CHALANDISE === this.props.selectedZone) {
      return <Trans k="dataType.zoneChalandise" />;
    }
    if (DATA_FILTER_TYPE_ZONE_PROXIMITE === this.props.selectedZone) {
      return <Trans k="dataType.zoneProximite" />;
    }
    if (PARAM_VALUE_OFF !== this.props.selectedAccesTime) {
      return <AccesTimeLib accesTime={this.props.selectedAccesTime} />;
    }

    return (
      <span>
        <Trans k="dataType.zonesAffichees" /> : <Trans k="general.toutes" />
      </span>
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    const zones = [
      { value: DATA_FILTER_TYPE_ZONE_ALL, transKey: "dataType.touteZone" },
      {
        value: DATA_FILTER_TYPE_ZONE_CHALANDISE,
        transKey: "dataType.zoneChalandise",
      },
      {
        value: DATA_FILTER_TYPE_ZONE_PROXIMITE,
        transKey: "dataType.zoneProximite",
      },
    ];

    return (
      <>
        <div
          className="filter-dropdown data-filter"
          ref={(dropdown) => {
            this.filterPdmDropdown = dropdown;
          }}
          id="filter-dropdown"
        >
          <button
            className="dropdown__trigger btn filter-btn"
            onClick={() => {
              this.setState({ dropdownOpen: !this.state.dropdownOpen });
            }}
          >
            <span className="icon icon-edit" />
            <span className="lib">{this.selectionLib()}</span>
          </button>
          {this.state.dropdownOpen && (
            <ul className="dropdown-menu" id="dropdown-menu">
              <ZonesFilter
                zones={zones}
                className="choices-list form-content zone"
                selectedZone={this.props.selectedZone}
                selectedAccesTime={formatAccessTimeParam(
                  this.props.selectedAccesTime
                )}
                onSubmitHandler={(selection) => {
                  this.setState({ dropdownOpen: !this.state.dropdownOpen });
                  this.submitChange(selection);
                }}
              />
            </ul>
          )}
        </div>
      </>
    );
  }
}

SwitchZone.propTypes = {
  visible: PropTypes.bool,
  selectedZone: PropTypes.string,
  selectedAccesTime: PropTypes.string,
  navigate: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    routing: state.routing,
    visible: PAGE_NAME_CARTE_ATTRACTIVITE === state.routing.currentRouteName,
    selectedZone: state.routing.params[PARAM_NAME_ZONE],
    selectedAccesTime: state.routing.params[PARAM_NAME_ACCES_TIME],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navigate: (zone, accesTime) => {
      dispatch(
        navigate(null, {
          [PARAM_NAME_ZONE]: zone,
          [PARAM_NAME_ACCES_TIME]: accesTime,
        })
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchZone);
