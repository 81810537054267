import RouteRecognizer from 'route-recognizer';
import {
    DATA_FILTER_TYPE_SEJOUR,
    DATA_FILTER_TYPE_VALO,
    DATA_FILTER_TYPE_REG_15,
    DATA_FILTER_TYPE_REG_16,
    DATA_FILTER_TYPE_DEP,
    DATA_FILTER_TYPE_COMMUNE,
    DATA_FILTER_TYPE_ZONE_ALL,
    DATA_FILTER_TYPE_ZONE_ACCES_TIME,
    DATA_FILTER_TYPE_ZONE_CHALANDISE,
    DATA_FILTER_TYPE_ZONE_PROXIMITE
} from 'constants/dataFilterType';
import {
    PARAM_VALUE_OFF,
    PARAM_NAME_HASH,
    PARAM_NAME_ANNEE,
    PARAM_NAME_DATA_TYPE,
    PARAM_NAME_GEO_TYPE,
    PARAM_NAME_PDM,
    PARAM_NAME_ACCES_TIME,
    PARAM_NAME_ZONE,
    PARAM_NAME_GEO_SELECTION,
    SECTION_NAME_SELECTEUR,
    SECTION_NAME_FICHE,
    SECTION_NAME_CARTE,
    PAGE_NAME_SELECTEUR_ETABLISSEMENT,
    PAGE_NAME_SELECTEUR_APPAREIL,
    PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE,
    PAGE_NAME_SELECTEUR_GHM,
    PAGE_NAME_SELECTEUR_AGE,
    PAGE_NAME_FICHE_ETABLISSEMENTS,
    PAGE_NAME_FICHE_PRISE_EN_CHARGE,
    PAGE_NAME_FICHE_APPAREIL,
    PAGE_NAME_FICHE_GHM,
    PAGE_NAME_FICHE_SEXE_AGE,
    PAGE_NAME_CARTE_ATTRACTIVITE,
    PAGE_NAME_CARTE_CONCURENCE
} from 'constants/router';
import isArray from 'lodash/isArray'


//import persistentData from 'persistentData'

export function formatAccessTimeParam(accesTime) {
    if (PARAM_VALUE_OFF === accesTime || undefined === accesTime || null === accesTime) {
        return []
    }
    if (isArray(accesTime)) {
        return accesTime
    }

    return accesTime.split(',')
}



class AppRouter {
    params = {
        [PARAM_NAME_HASH]: {
            default: PARAM_VALUE_OFF,
            pattern: '^([a-f0-9]{32})|' + PARAM_VALUE_OFF + '$',
            persistent: true
        },
        [PARAM_NAME_ANNEE]: {
            default: 22, // TODO Object.keys(persistentData.get('annees')).sort((a, b) => a - b).slice(-1)
            pattern: '^[0-9]{2}$',
            persistent: true
        },
        [PARAM_NAME_DATA_TYPE]: {
            default: DATA_FILTER_TYPE_SEJOUR,
            pattern: '^' + DATA_FILTER_TYPE_SEJOUR + '$'
            + '|^' + DATA_FILTER_TYPE_VALO + '$',
            persistent: true
        },
        [PARAM_NAME_GEO_TYPE]: {
            default: DATA_FILTER_TYPE_COMMUNE,
            pattern: '^' + DATA_FILTER_TYPE_REG_15 + '$'
            + '|^' + DATA_FILTER_TYPE_REG_16 + '$'
            + '|^' + DATA_FILTER_TYPE_DEP + '$'
            + '|^' + DATA_FILTER_TYPE_COMMUNE + '$',
            persistent: true
        },
        // NEW
        [PARAM_NAME_PDM]: {
            default: DATA_FILTER_TYPE_REG_16,
            pattern: '^' + DATA_FILTER_TYPE_REG_15 + '$'
            + '|^' + DATA_FILTER_TYPE_REG_16 + '$',
            persistent: true
        },
        [PARAM_NAME_ACCES_TIME]: {
            default: PARAM_VALUE_OFF,
            pattern: '^' + PARAM_VALUE_OFF + '$' +
            '|([0-9](?:,[0-9]){0,4})?',
            persistent: false
        },
        [PARAM_NAME_ZONE]: {
            default: DATA_FILTER_TYPE_ZONE_ALL,
            pattern: '^' + DATA_FILTER_TYPE_ZONE_ALL + '$'
            + '|^' + DATA_FILTER_TYPE_ZONE_ACCES_TIME + '$'
            + '|^' + DATA_FILTER_TYPE_ZONE_CHALANDISE + '$'
            + '|^' + DATA_FILTER_TYPE_ZONE_PROXIMITE + '$',
            persistent: false
        },
        [PARAM_NAME_GEO_SELECTION]: {
            default: PARAM_VALUE_OFF,
            pattern: '^([a-f0-9]{32})|' + PARAM_VALUE_OFF + '$',
            persistent: false
        }
    };
    routes = {
        [PAGE_NAME_SELECTEUR_ETABLISSEMENT]: {
            section: SECTION_NAME_SELECTEUR,
            params: [PARAM_NAME_HASH]
        },
        [PAGE_NAME_SELECTEUR_APPAREIL]: {
            section: SECTION_NAME_SELECTEUR,
            params: [PARAM_NAME_HASH]
        },
        [PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE]: {
            section: SECTION_NAME_SELECTEUR,
            params: [PARAM_NAME_HASH]
        },
        [PAGE_NAME_SELECTEUR_GHM]: {
            section: SECTION_NAME_SELECTEUR,
            params: [PARAM_NAME_HASH]
        },
        [PAGE_NAME_SELECTEUR_AGE]: {
            section: SECTION_NAME_SELECTEUR,
            params: [PARAM_NAME_HASH]
        },
        [PAGE_NAME_FICHE_ETABLISSEMENTS]: {
            section: SECTION_NAME_FICHE,
            params: [PARAM_NAME_HASH, PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE]
        },
        [PAGE_NAME_FICHE_APPAREIL]: {
            section: SECTION_NAME_FICHE,
            params: [PARAM_NAME_HASH, PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE]
        },
        [PAGE_NAME_FICHE_PRISE_EN_CHARGE]: {
            section: SECTION_NAME_FICHE,
            params: [PARAM_NAME_HASH, PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE]
        },
        [PAGE_NAME_FICHE_GHM]: {
            section: SECTION_NAME_FICHE,
            params: [PARAM_NAME_HASH, PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE]
        },
        [PAGE_NAME_FICHE_SEXE_AGE]: {
            section: SECTION_NAME_FICHE,
            params: [PARAM_NAME_HASH, PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE]
        },
        [PAGE_NAME_CARTE_ATTRACTIVITE]: {
            section: SECTION_NAME_CARTE,
            params: [
                PARAM_NAME_HASH,
                PARAM_NAME_ANNEE,
                PARAM_NAME_DATA_TYPE,
                PARAM_NAME_GEO_TYPE,
                PARAM_NAME_ZONE,
                PARAM_NAME_ACCES_TIME,
                PARAM_NAME_GEO_SELECTION
            ]
        },
        [PAGE_NAME_CARTE_CONCURENCE]: {
            section: SECTION_NAME_CARTE,
            params: [
                PARAM_NAME_HASH,
                PARAM_NAME_ANNEE,
                PARAM_NAME_DATA_TYPE,
                PARAM_NAME_GEO_TYPE,
                PARAM_NAME_PDM
            ]
        },
    };
    defaultRoute = PAGE_NAME_SELECTEUR_ETABLISSEMENT;

    getRouteParams(routeName, routePath, path, defaultParam = false) {
        const router = new RouteRecognizer();
        router.add([{path: routePath, handler: {}}]);
        const recognizeResult = router.recognize(path);
        if (undefined !== recognizeResult) {
            let params = recognizeResult[0].params;
            if (defaultParam) {
                params = {};
                this.routes[routeName].params.forEach((param) => {
                    params[param] = this.params[param].default;
                });
            }
            return {
                error: false,
                name: routeName,
                section: this.routes[routeName].section,
                params
            };
        }

        return {error: true, name: null, params: null};
    }

    resetNotPersistentparams(routeParams) {
        for (let routeName in this.routes) {
            if (this.routes.hasOwnProperty(routeName) && routeName !== routeParams.name) {
                this.routes[routeName].params.forEach((paramName) => {
                    if (this.params.hasOwnProperty(paramName) && !this.params[paramName].persistent) {
                        routeParams.params[paramName] = this.params[paramName].default;
                    }
                });
            }
        }

        return routeParams;
    }

    checkRouteConsistency(routeParams) {
        let error = false;
        if ((PAGE_NAME_SELECTEUR_ETABLISSEMENT !== routeParams.name
            && PAGE_NAME_SELECTEUR_APPAREIL !== routeParams.name
            && PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE !== routeParams.name
            && PAGE_NAME_SELECTEUR_GHM !== routeParams.name
            && PAGE_NAME_SELECTEUR_AGE !== routeParams.name)
            && PARAM_VALUE_OFF === routeParams.params[PARAM_NAME_HASH]) {

            error = true;
            console.error('Router : Le hash ne peux pas être vide.');
        }

        if (undefined !== routeParams.params[PARAM_NAME_ACCES_TIME]
            && undefined !== routeParams.params[PARAM_NAME_ZONE]
            && PARAM_VALUE_OFF !== routeParams.params[PARAM_NAME_ACCES_TIME]
            && DATA_FILTER_TYPE_ZONE_ACCES_TIME !== routeParams.params[PARAM_NAME_ZONE]) {

            error = true;
            console.error('Router :  Les paramètres ' + PARAM_NAME_ZONE + ' et ' + PARAM_NAME_ACCES_TIME + ' ne peuvent pas être actifs en même temps.');
        }


        if (error) {
            return {error: true, name: null, params: null};
        }

        return routeParams;
    }

    checkRouteParameters(routeParams) {
        let error = false;
        for (let param in routeParams.params) {
            if (routeParams.params.hasOwnProperty(param)) {
                if (this.params.hasOwnProperty(param)) {
                    const regExpr = new RegExp(this.params[param].pattern);
                    if (null === routeParams.params[param].match(regExpr)) {
                        error = true;
                        console.error('Router : La valeur du paramètre ' + param + ' ( ' + routeParams.params[param] + ' ) n\'est pas valide.');
                        break;
                    }
                } else {
                    error = true;
                    console.error('Router : Le paramètre ' + param + ' n\'est pas défini.');
                    break;
                }
            }
        }
        if (error) {
            return {error: true, name: null, params: null};
        }

        return routeParams;
    }

    parsePath(path) {
        let routeParams = null;

        for (let routeName in this.routes) {
            if (this.routes.hasOwnProperty(routeName)) {
                let routePath = '/' + routeName;
                this.routes[routeName].params.forEach(function (paramName) {
                    routePath += '/:' + paramName;
                });
                routeParams = this.getRouteParams(routeName, routePath, path);
                if (!routeParams.error) break;
            }
        }
        if (routeParams.error) {
            routeParams = this.getRouteParams(this.defaultRoute, '/', path, true);
        }
        if (routeParams.error) {
            console.error('Router : La page demandée n\'existe pas.');
        }
        if (!routeParams.error) {
            routeParams = this.checkRouteParameters(routeParams);
        }
        if (!routeParams.error) {
            routeParams = this.checkRouteConsistency(routeParams);
        }
        if (!routeParams.error) {
            routeParams = this.resetNotPersistentparams(routeParams);
        }
        return routeParams;
    }

    getPath(routeName, params, currentState) {
        if (null === routeName) {
            routeName = currentState.currentRouteName;
        }
        if (null === routeName) {
            routeName = this.defaultRoute;
        }
        let path = routeName;
        if (this.routes.hasOwnProperty(routeName)) {
            this.routes[routeName].params.forEach((paramName) => {
                let paramValue = this.params[paramName].default;
                if (params.hasOwnProperty(paramName)) {
                    paramValue = params[paramName];
                } else if (currentState.params.hasOwnProperty(paramName)) {
                    paramValue = currentState.params[paramName];
                }
                path += '/' + paramValue;
            });
        }

        return path;
    }
}

const appRouter = new AppRouter();
export default appRouter;
