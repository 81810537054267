import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import cn from 'classnames';
import {
    PART_DISPLAY,
    FULL_DISPLAY
} from 'constants/offreSoinsDisplayState';

import OffreSoinsToggleDisplay from 'components/molecules/OffreSoins/OffreSoinsToggleDisplay';
import OffreSoinsListContainer from 'components/molecules/OffreSoins/OffreSoinsListContainer';

class OffreSoins extends React.Component {
    render(){
        if(PART_DISPLAY !== this.props.displayState && FULL_DISPLAY !== this.props.displayState){
            return null;
        }
        return (
            <div className={cn('offre-soins-container', {
                'reverse' :  FULL_DISPLAY === this.props.displayState
            })}>
                <OffreSoinsListContainer />
                <OffreSoinsToggleDisplay displayState={this.props.displayState} />
            </div>
        );
    }
}


OffreSoins.propTypes = {
    displayState: PropTypes.string
};

function mapStateToProps(state) {
    return {
        displayState:  state.offreSoins.displayState
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OffreSoins);