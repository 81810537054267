class SelectionSavedManager {
    savedSelection = {};

    save(selection, type) {
        if (undefined !== this.savedSelection[type]) {
            delete this.savedSelection[type];
        }
        this.savedSelection[type] = selection;
    }

    get(type) {
        if (!this.savedSelection.hasOwnProperty(type)) {
            return null;
        }


        return this.savedSelection[type];
    }

    isNew() {
        return 0 === Object.keys(this.savedSelection).length;
    }

    equals(newSelection) {
        if (0 === Object.keys(this.savedSelection).length) {
            return true;
        }

        let same = true;
        for (let type in newSelection) {
            if (newSelection.hasOwnProperty(type)) {
                if (!this.savedSelection.hasOwnProperty(type)) {
                    same = false;
                } else if (!this.savedSelection[type].equals(newSelection[type])) {
                    same = false;
                }
            }
        }

        return same;
    }
}

const selectionSavedManager = new SelectionSavedManager();

export default selectionSavedManager;
