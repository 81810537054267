import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';
import Percent from 'components/atoms/Format/Percent';

export default class CarteInfosBenchTitle extends React.Component {
    render(){
        return (
            <div className={cn('bench-title', 'align-' + this.props.align, 'bench-' + this.props.benchType)}>
                <div className="bench-type">
                    {this.props.title}
                </div>
                <div className="bench-percent">
                    <Percent value={this.props.part} />
                </div>
            </div>
        );
    }
}


CarteInfosBenchTitle.propTypes = {
    title: PropTypes.string.isRequired,
    align: PropTypes.string.isRequired,
    benchType: PropTypes.string.isRequired,
    part: PropTypes.number.isRequired
};
