import {Set, Map} from 'immutable';
import {
    PARAM_NAME_GEO_TYPE
} from 'constants/router';
import {
    LOCATION_CHANGE,
    OFFRE_SOINS_ENABLE_SELECTION,
    OFFRE_SOINS_DISABLE_SELECTION,
    OFFRE_SOINS_SELECTION_ADD,
    OFFRE_SOINS_SELECTION_REMOVE,
    OFFRE_SOINS_GEOJSON_FULFILLED,
    OFFRE_SOINS_GEOJSON_REJECTED,
    OFFRE_SOINS_SAVE_PENDING,
    OFFRE_SOINS_SAVE_FULFILLED,
    OFFRE_SOINS_SAVE_REJECTED,
    OFFRE_SOINS_LOAD_PENDING,
    OFFRE_SOINS_LOAD_FULFILLED,
    OFFRE_SOINS_LOAD_REJECTED,
    OFFRE_SOINS_PART_DISPLAYED,
    OFFRE_SOINS_FULL_DISPLAYED,
    OFFRE_SOINS_CLEAR
} from 'constants/actionTypes';
import {
    SELECTION_NOT_ACTIVE,
    SELECTION_NOT_LOADED,
    PART_DISPLAY,
    FULL_DISPLAY
} from 'constants/offreSoinsDisplayState';
import {
    DATA_FILTER_TYPE_SEJOUR,
    DATA_FILTER_TYPE_VALO
} from 'constants/dataFilterType';
import persistentData from '../../managers/persistentData';

const TOP_MAX_COUNT = 5;

function initTop() {
    let top = {};
    const annees = persistentData.get('annees');
    for (let idAnnee in annees) {
        if (annees.hasOwnProperty(idAnnee)) {
            top[idAnnee] = {[DATA_FILTER_TYPE_SEJOUR]: [], [DATA_FILTER_TYPE_VALO]: []};
            for (let i = 0; i < TOP_MAX_COUNT; i++) {
                top[idAnnee][DATA_FILTER_TYPE_SEJOUR][i] = {id: null, value: 0};
                top[idAnnee][DATA_FILTER_TYPE_VALO][i] = {id: null, value: 0};
            }
        }
    }

    return top;
}

function setTopValue(top, idAnnee, dataType, id, dataValue, startIndex = 0) {
    for (let i = startIndex; i < TOP_MAX_COUNT; i++) {
        const
            currentValue = top[idAnnee][dataType][i].value,
            currentId = top[idAnnee][dataType][i].id
        ;
        if (dataValue > currentValue) {
            top[idAnnee][dataType][i].value = parseInt(dataValue);
            top[idAnnee][dataType][i].id = id;
            setTopValue(top, idAnnee, dataType, currentId, currentValue, i + 1);
            break;
        }
    }
}

function getTop(datas) {
    let top = initTop();

    for (let id in datas) {
        if (datas.hasOwnProperty(id)) {
            for (let idAnnee in datas[id].data) {
                if (datas[id].data.hasOwnProperty(idAnnee)) {
                    setTopValue(top, idAnnee, DATA_FILTER_TYPE_SEJOUR, id, datas[id].data[idAnnee][DATA_FILTER_TYPE_SEJOUR]);
                    setTopValue(top, idAnnee, DATA_FILTER_TYPE_VALO, id, datas[id].data[idAnnee][DATA_FILTER_TYPE_VALO]);
                }
            }
        }
    }
    for (let idAnnee in top) {
        if (top.hasOwnProperty(idAnnee)) {
            let ids1 = [];
            top[idAnnee][DATA_FILTER_TYPE_SEJOUR].forEach(item => {
                if (null != item.id) ids1.push(item);
            });
            top[idAnnee][DATA_FILTER_TYPE_SEJOUR] = ids1;

            let ids2 = [];
            top[idAnnee][DATA_FILTER_TYPE_VALO].forEach(item => {
                if (null != item.id) ids2.push(item);
            });
            top[idAnnee][DATA_FILTER_TYPE_VALO] = ids2;
        }
    }

    return top;
}

function getInitialState() {
    return {
        displayState: SELECTION_NOT_ACTIVE,
        selectionActive: false,
        geoType: null,
        geojson: new Map(),
        selectionCodes: new Set(),
        hash: null,
        dataCodes: new Set(),
        datas: null,
        dataPending: false,
        top: null
    };
}

export default function offreSoinsReducer(state = getInitialState(), action) {
    switch (action.type) {
        case LOCATION_CHANGE: {
            if (!action.payload.routeParams.error) {
                const routeParamGeoType = action.payload.routeParams.params[PARAM_NAME_GEO_TYPE];
                if (routeParamGeoType && null != state.geoType && routeParamGeoType != state.geoType) {
                    return getInitialState();
                }
            }
            break;
        }
        case OFFRE_SOINS_ENABLE_SELECTION : {
            let displayState = SELECTION_NOT_LOADED;
            if (state.dataCodes.size > 0 && state.selectionCodes.equals(state.dataCodes)) {
                displayState = PART_DISPLAY;
            }
            return {...state, selectionActive: true, displayState};
        }
        case OFFRE_SOINS_DISABLE_SELECTION : {
            return getInitialState();
        }
        case OFFRE_SOINS_SELECTION_ADD : {
            const code = action.payload.code,
                geoType = action.payload.geoType
            ;
            if (geoType != state.geoType) {
                return {...state, geoType, selectionCodes: new Set([code]), displayState: SELECTION_NOT_LOADED};
            }
            if (!state.selectionCodes.includes(code)) {
                const
                    selectionCodes = state.selectionCodes.add(code),
                    displayState = selectionCodes.equals(state.dataCodes) ? PART_DISPLAY : SELECTION_NOT_LOADED
                ;
                return {...state, geoType, selectionCodes, displayState};
            }
            break;
        }
        case OFFRE_SOINS_SELECTION_REMOVE :
        case OFFRE_SOINS_GEOJSON_REJECTED : {
            const code = action.payload.code,
                geoType = action.payload.geoType
            ;
            if (state.selectionCodes.includes(code)) {
                const
                    selectionCodes = state.selectionCodes.remove(code),
                    displayState = selectionCodes.equals(state.dataCodes) ? PART_DISPLAY : SELECTION_NOT_LOADED
                ;
                return {...state, geoType, selectionCodes, displayState};
            }
            break;
        }
        case OFFRE_SOINS_GEOJSON_FULFILLED : {
            return {
                ...state,
                geojson: state.geojson.set(action.payload.code, action.payload.data)
            };
        }
        case OFFRE_SOINS_SAVE_PENDING : {
            const cleanGeoJson = state.geojson.filter((item, code) => {
                return state.selectionCodes.includes(code);
            });
            return {
                ...state,
                displayState: PART_DISPLAY,
                geojson: cleanGeoJson,
                dataCodes: new Set(state.selectionCodes),
                datas: null,
                top: null,
                dataPending: true
            };
        }
        case OFFRE_SOINS_SAVE_FULFILLED : {
            const
                selection = action.payload.selection,
                datas = action.payload.datas,
                top = getTop(action.payload.datas)
            ;
            return {...state, displayState: PART_DISPLAY, hash: selection.hash, datas, top, dataPending: false};
        }
        case OFFRE_SOINS_SAVE_REJECTED :
        case OFFRE_SOINS_LOAD_REJECTED : {
            return getInitialState();
        }

        case OFFRE_SOINS_LOAD_PENDING: {
            return {
                ...state,
                displayState: PART_DISPLAY,
                dataPending: true,
                selectionActive: true,
                hash: action.payload.hash,
                geoType: action.payload.geoType
            }
        }
        case OFFRE_SOINS_LOAD_FULFILLED: {
            const datas = action.payload.datas,
                top = getTop(action.payload.datas),
                geojson = action.payload.geojson,
                selection = action.payload.selection
            ;
            return {
                ...state,
                dataPending: false,
                selectionCodes: new Set(selection.codes),
                dataCodes: new Set(selection.codes),
                geojson: new Map(geojson),
                datas,
                top
            }
        }

        case OFFRE_SOINS_FULL_DISPLAYED : {
            return {...state, displayState: FULL_DISPLAY}
        }
        case OFFRE_SOINS_PART_DISPLAYED : {
            return {...state, displayState: PART_DISPLAY}
        }
        case OFFRE_SOINS_CLEAR: {
            const clearAll = action.payload.all;
            if (clearAll) {
                return getInitialState();
            }
            let displayState = SELECTION_NOT_ACTIVE;
            if (state.selectionCodes.size > 0) {
                displayState = SELECTION_NOT_LOADED;
            }
            return {
                ...state,
                displayState: displayState,
                hash: null,
                dataCodes: new Set(),
                datas: null,
                top: null,
                dataPending: false
            }
        }
    }

    return state;
}