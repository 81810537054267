import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'

import {fetch} from 'actions/dataActions';
import fetchDatasManager from 'managers/fetchDatasManager';

import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE, PAGE_NAME_FICHE_APPAREIL} from 'constants/router'
import {DATA_TYPE_FICHE_APPAREILS, DATA_TYPE_FICHE_PRISE_EN_CHARGE} from 'constants/dataType'

import FicheSummary from "components/molecules/FicheSummary/FicheSummary";
import FicheTotalSelection from 'components/molecules/FicheTotalSelection/FicheTotalSelection';
import FicheAppareilList from 'components/molecules/FicheAppareils/FicheAppareilList'
import HelixLoader from "components/atoms/Loader/HelixLoader";
import {DATA_FILTER_TYPE_SEJOUR} from "constants/dataFilterType";

class AppareilsPageContent extends Component {
    UNSAFE_componentWillMount() {
        this.fetchDatas(this.props)
    }

    UNSAFE_componentWillUpdate(nextProps) {
        this.fetchDatas(nextProps)
    }

    fetchDatas(props) {
		let url = (PAGE_NAME_FICHE_APPAREIL === this.props.route)? '/fiche/appareils' : '/fiche/prise-en-charge'
		let dataType = (PAGE_NAME_FICHE_APPAREIL === this.props.route) ? DATA_TYPE_FICHE_APPAREILS : DATA_TYPE_FICHE_PRISE_EN_CHARGE
        if (props.selectorReady) {
            const newParams = fetchDatasManager.getParams(dataType, props.routingParams);
            if (fetchDatasManager.hasDifferences(props.fetchData, newParams)) {
                props.fetch(newParams, dataType, url);
            }
        }
    }

    getFicheTitle() {
        return {
            subtitle: (DATA_FILTER_TYPE_SEJOUR === this.props.dataType)
                ? 'fiche.subtitle-sejour'
                : 'fiche.subtitle-valo',

			title: (PAGE_NAME_FICHE_APPAREIL === this.props.route)
                ? 'fiche.appareils.title'
                : 'fiche.priseEnCharge.title'
        }
    }

    render() {
        if (this.props.fetchData.pending || null === this.props.datas.selection) {
            return <HelixLoader />
        }

        return (
            <div>
                <FicheSummary titleKeys={this.getFicheTitle()} selection={this.props.datas.selection} />
                <div className="fiche-container">
					<FicheTotalSelection key={'FicheTotalSelection' + this.props.route} selection={this.props.datas.selection}/>
					<FicheAppareilList key={'FicheAppareilList' + this.props.route} {...this.props} />
                </div>
            </div>
        )
    }
}


AppareilsPageContent.propTypes = {
    fetchData: PropTypes.object,
    routingParams: PropTypes.object,
    annee: PropTypes.string,
    dataType: PropTypes.string,
    selectorReady: PropTypes.bool,
    datas: PropTypes.object,
	route: PropTypes.string
}

function mapStateToProps(state) {
	let dataType = (PAGE_NAME_FICHE_APPAREIL === state.routing.currentRouteName) ? DATA_TYPE_FICHE_APPAREILS : DATA_TYPE_FICHE_PRISE_EN_CHARGE
    return {
        fetchData: state.fetchData[dataType],
        routingParams: fetchDatasManager.getParams(dataType, state.routing.params),
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
		route: state.routing.currentRouteName,
        selectorReady: state.mainSelector.ready,
        datas: state[dataType]
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetch: (params, dataType, url) => {
            dispatch(fetch(params, dataType, url));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AppareilsPageContent);