import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SelectorNavItem from 'components/molecules/Selector/SelectorNavItem';

import { SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
    SELECTOR_TYPE_APPAREIL,
    SELECTOR_TYPE_PRISE_EN_CHARGE,
    SELECTOR_TYPE_GHM,
    SELECTOR_TYPE_AGE
} from 'constants/selectorTypes';
import { PAGE_NAME_SELECTEUR_ETABLISSEMENT,
    PAGE_NAME_SELECTEUR_APPAREIL,
    PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE,
    PAGE_NAME_SELECTEUR_GHM,
    PAGE_NAME_SELECTEUR_AGE
} from 'constants/router';

class SelectorNav extends React.Component {
    render(){
        return (
            <ul className="selector-nav">
                <SelectorNavItem
                    reducers={[ SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B ]}
                    isDefaultAll={false}
                    isRemoveEnabled={!this.props.mainSelector.pending && this.props.isRemoveEnabled}
                    destPage={PAGE_NAME_SELECTEUR_ETABLISSEMENT}
                    active={this.props.activePage === PAGE_NAME_SELECTEUR_ETABLISSEMENT}
                    type={SELECTOR_TYPE_ETS_A}
                />
                <SelectorNavItem
                    reducers={[ SELECTOR_TYPE_APPAREIL ]}
                    isDefaultAll={true}
                    isRemoveEnabled={!this.props.mainSelector.pending && this.props.isRemoveEnabled}
                    destPage={PAGE_NAME_SELECTEUR_APPAREIL}
                    active={this.props.activePage === PAGE_NAME_SELECTEUR_APPAREIL}
                    type={SELECTOR_TYPE_APPAREIL}
                />
                <SelectorNavItem
                    reducers={[ SELECTOR_TYPE_PRISE_EN_CHARGE ]}
                    isDefaultAll={true}
                    isRemoveEnabled={!this.props.mainSelector.pending && this.props.isRemoveEnabled}
                    destPage={PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE}
                    active={this.props.activePage === PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE}
                    type={SELECTOR_TYPE_PRISE_EN_CHARGE}
                />
                <SelectorNavItem
                    reducers={[ SELECTOR_TYPE_GHM ]}
                    isDefaultAll={true}
                    isRemoveEnabled={!this.props.mainSelector.pending && this.props.isRemoveEnabled}
                    destPage={PAGE_NAME_SELECTEUR_GHM}
                    active={this.props.activePage === PAGE_NAME_SELECTEUR_GHM}
                    type={SELECTOR_TYPE_GHM}
                />
                <SelectorNavItem
                    reducers={[ SELECTOR_TYPE_AGE ]}
                    isDefaultAll={true}
                    isRemoveEnabled={!this.props.mainSelector.pending && this.props.isRemoveEnabled}
                    destPage={PAGE_NAME_SELECTEUR_AGE}
                    active={this.props.activePage === PAGE_NAME_SELECTEUR_AGE}
                    type={SELECTOR_TYPE_AGE}
                />
            </ul>
        );
    }
}

SelectorNav.propTypes = {
    isRemoveEnabled: PropTypes.bool.isRequired,
    activePage: PropTypes.string.isRequired,
    mainSelector: PropTypes.object
};

function mapStateToProps(state) {
    return {
        mainSelector: state.mainSelector
    };
}

export default connect(mapStateToProps)(SelectorNav);
