import React, {Component} from 'react'
import PropTypes from 'prop-types';


import {connect} from 'react-redux'
import cn from 'classnames'


import {
	PARAM_NAME_ANNEE,
	PARAM_NAME_DATA_TYPE,
	PAGE_NAME_FICHE_APPAREIL,
} from 'constants/router'
import {SELECTOR_TYPE_ETS_A, SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';
import treeMaker from 'managers/treeMaker';
import FormattedDataType from 'components/molecules/Format/FormattedDataType';
import Percent from 'components/atoms/Format/Percent'

import SelectorCounter from 'components/atoms/Selector/SelectorCounter';

class FicheAppareilItemList extends Component {

	constructor(props) {
        super(props)
        this.getTreeData(props)

		this.state = {
			expanded: false
		}
    }

	getTreeData(props) {
        this.tree = treeMaker.getData(props.route)
    }

	renderBlockChart(bench, item, level, isModeBenchmark, isLeft) {
		let index = item.id
		let percentType = "p" + [this.props.dataType]
		const hasData = level[bench][index]
        const percentValue = (hasData !== undefined) ? level[bench][index][percentType] : 0
		const value = (hasData !== undefined) ? level[bench][index][this.props.dataType] : 0
		let defaultWidth = (isModeBenchmark) ? 225 : 545
		let width = (0 !== this.props.max.value)
			? defaultWidth * (percentValue / this.props.max.value)
			: 0;

		let barStyle = {'width': width}
		let bgBarStyle = {'width': width + 4}

		if(isLeft) {
			return (
				<div className="fiche-col-barChart left benchmark">
					<div className="barChart-info">
						<div className="percent">
							{(percentValue === 0) ? '0%' : <Percent value={percentValue}/>}

						</div>
						<div className="value">
							<FormattedDataType value={value}/>
						</div>
					</div>
					<div className="barChart" style={barStyle}>
						{(item.children === undefined && item.selected === true && this.props.route === PAGE_NAME_FICHE_APPAREIL)
                            ? <div className="barChart-bg" style={bgBarStyle} />
                            : null}
					</div>
				</div>
			)
		}

		return (
			<div className={cn('fiche-col-barChart', {benchmark: isModeBenchmark})}>
				<div className="barChart" style={barStyle}>
					{(item.children === undefined && item.selected === true && this.props.route === PAGE_NAME_FICHE_APPAREIL)
                        ? <div className="barChart-bg" style={bgBarStyle} />
                        : null}
				</div>
				<div className="barChart-info">
					<div className="percent">
						{(percentValue === 0) ? '0%' : <Percent value={percentValue}/>}
					</div>
					<div className="value">
						<FormattedDataType value={value}/>
					</div>
				</div>
			</div>
		)
	}

	renderAccordeonBtn(item) {
		if (undefined === item.children)
			return null
		return (
			<div className="accordeon-btn">
				<div className="icon icon-arrow-down" />
			</div>
		)
	}

	renderAccordeonContent(children) {
		if(children !== null) {
			let expanded = this.state.expanded
			this.setState({expanded: !expanded})
		}
	}

	renderItemList(treeItem, props) {
		let percentType = "p" + [this.props.dataType]
		let child,
			parent
		if(this.props.route === PAGE_NAME_FICHE_APPAREIL) {
			child = props.organes
			parent = props.appareils
		} else {
			child = props.priseEnCharge
		}
		const etsAVisible = this.props[SELECTOR_TYPE_ETS_A].size > 0
        const etsBVisible = this.props[SELECTOR_TYPE_ETS_B].size > 0
		const isModeBenchmark = etsAVisible && etsBVisible;
		let children = null
		if(undefined !== treeItem.children) {
			let sortingEtsAChildren = treeItem.children.sort((childA, childB) => {
				return childB.datas[SELECTOR_TYPE_ETS_A][percentType] - childA.datas[SELECTOR_TYPE_ETS_A][percentType]
			})

			children = sortingEtsAChildren.map((item, i) => {
				return this.renderItemList(item, props)
			})
		}
		let level = (null === children) ? child : parent
		return (
			<li
				className={cn("fiche-accordeon", "chart-colorD" + this.props.classNumber, {
					'last-child': (children === null && this.props.route === PAGE_NAME_FICHE_APPAREIL),
					'selected': (children === null && treeItem.selected === true),
                    'active': this.state.expanded
                })}
				key={this.props.route + treeItem.id}
			>
				<div className={cn({'accordeon-bg': (children !== null || this.props.route !== PAGE_NAME_FICHE_APPAREIL)})}>
					<div className="accordeon-container">
						{(isModeBenchmark) ? this.renderBlockChart(SELECTOR_TYPE_ETS_A, treeItem, level, true, true) : null}
						<div
							className="fiche-col-lib"
							onClick={this.renderAccordeonContent.bind(this, children)}
						>
							{this.renderAccordeonBtn(treeItem)}
							<div className="lib">
								{treeItem.lib}
							</div>
							<div style={{'width': '40px'}}>
								{(treeItem.selected && treeItem.children !== undefined)
                                    ? <SelectorCounter item={treeItem} />
                                    : null}
							</div>
						</div>
						{(etsAVisible && !isModeBenchmark) ? this.renderBlockChart(SELECTOR_TYPE_ETS_A, treeItem, level, false, false) : null }
						{(isModeBenchmark) ? this.renderBlockChart(SELECTOR_TYPE_ETS_B, treeItem, level, true, false) : null }
					</div>
				</div>
				{(treeItem.children !== undefined) ? this.renderAccordeonContainer(children) : null}

			</li>
		)
	}

	renderAccordeonContainer(children) {
		if(!this.state.expanded) {
			return null
		}
		return (
			<ul className="accordeon-content">
				{children}
			</ul>
		)
	}

    render() {
        return (
            this.renderItemList(this.props.item, this.props.datas)
        )
    }
}


FicheAppareilItemList.propTypes = {
    annee: PropTypes.string,
    dataType: PropTypes.string,
	datas: PropTypes.object,
	max: PropTypes.object.isRequired,
	classNumber: PropTypes.number.isRequired,
	sortedOrganes: PropTypes.array,
	item: PropTypes.object.isRequired,
	route: PropTypes.string
}

function mapStateToProps(state) {
    return {
		[SELECTOR_TYPE_ETS_A]: state.selector[SELECTOR_TYPE_ETS_A],
        [SELECTOR_TYPE_ETS_B]: state.selector[SELECTOR_TYPE_ETS_B],
        annee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
		route: state.routing.currentRouteName
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(FicheAppareilItemList);