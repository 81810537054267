import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import cn from 'classnames'

import {PARAM_NAME_DATA_TYPE} from 'constants/router'
import Percent from "components/atoms/Format/Percent";
import Trans from "components/atoms/Format/Trans";
import FormattedDataType from "components/molecules/Format/FormattedDataType";

export const SEXE_HOMME = 1
export const SEXE_FEMME = 2
export const SEXE_INCONNU = 3

class SexeChart extends Component {

    render() {

        let value = {}
        let part = {}

        for (let sexe of [SEXE_HOMME, SEXE_FEMME, SEXE_INCONNU]) {
            value[sexe] = 0
            part[sexe] = 0
            if (undefined !== this.props.datas[sexe]) {
                value[sexe] = this.props.datas[sexe][this.props.dataType]
                part[sexe] = this.props.datas[sexe]['p' + this.props.dataType]
            }
        }

        return (
            <div className={cn('sexe-chart', 'bench-' + this.props.bench)}>

                <div className="top-percent">
                    <div className="homme">
                        <div className="image"/>
                        <Percent value={part[SEXE_HOMME]} className="percent"/>
                    </div>
                    <div className="femme">
                        <Percent value={part[SEXE_FEMME]} className="percent"/>
                        <div className="image"/>
                    </div>
                </div>

                <div className="bare">
                    <div className="homme" style={{flex: part[SEXE_HOMME]}}/>
                    <div className="femme" style={{flex: part[SEXE_FEMME]}}/>
                </div>

                <div className="bottom-detail">
                    <div className="homme">
                        <Trans k="fiche.sexeAge.sexe.homme" className="lib"/>
                        <FormattedDataType value={value[SEXE_HOMME]} withLib={true}/>
                    </div>
                    <div className="femme">
                        <Trans k="fiche.sexeAge.sexe.femme" className="lib"/>
                        <FormattedDataType value={value[SEXE_FEMME]} withLib={true}/>
                    </div>
                </div>
                {0 !== value[SEXE_INCONNU]
                    ? <div className="sexe-inconnu">
                        <FormattedDataType value={value[SEXE_INCONNU]} withLib={true}/>
                        &nbsp;
                        <Trans k="fiche.sexeAge.inconnu"/>
                    </div>
                    : null
                }
            </div>
        )
    }
}

SexeChart.propTypes = {
    dataType: PropTypes.string,
    bench: PropTypes.string,
    datas: PropTypes.object
}

function mapStateToProps(state, ownProps) {
    return {
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}


export default connect(mapStateToProps, mapDispatchToProps)(SexeChart);