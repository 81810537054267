import React from 'react';
import PropTypes from "prop-types";
import Trans from 'components/atoms/Format/Trans';
import cn from 'classnames';
import FormattedDataType from "components/molecules/Format/FormattedDataType";
import Percent from "components/atoms/Format/Percent";

export default class CarteInfosEtablissementSimple extends React.Component {
    getClassName() {
        return cn('carte-infos-etablissement-values', 'carte-infos-etablissement-simple');
    }
    renderEvolution(){
        if(null === this.props.datas.evol){
            return <div className="evol" />;
        }
        return (
            <div className="evol">
                <div className="center">
                    <Percent value={this.props.datas.evol} isEvolution={true} className="number" />
                    <Trans k="dataType.d-evolution" />
                </div>
            </div>
        );
    }
    render(){
        if(null === this.props.datas){
            return <div className={this.getClassName()} />
        }
        return (
            <div className={this.getClassName()}>
                <div className="value">
                    <div className="center">
                        <FormattedDataType value={this.props.datas.value} withLib={true} />
                    </div>
                </div>
                {this.renderEvolution()}
            </div>
        );
    }
}
CarteInfosEtablissementSimple.propTypes = {
    datas: PropTypes.object.isRequired
};