import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux"
import Immutable from 'immutable'
import {fetch} from 'actions/dataActions';
import fetchDatasManager from 'managers/fetchDatasManager';

import {PARAM_NAME_DATA_TYPE, PARAM_NAME_PDM, PAGE_NAME_CARTE_CONCURENCE} from 'constants/router'
import {DATA_TYPE_CARTE_CONCURRENCE} from 'constants/dataType'

import {
    DATA_FILTER_TYPE_REG_15,
    DATA_FILTER_TYPE_REG_16
} from 'constants/dataFilterType';
import persistentData from 'managers/persistentData'

import CarteBottomNav from 'components/molecules/CarteBottomNav/CarteBottomNav';
import CarteConcurrence from 'components/molecules/CarteConcurrence/CarteConcurrence'
import HelixLoader from "components/atoms/Loader/HelixLoader"

class ConcurrencePageContent extends React.Component {

	UNSAFE_componentWillMount() {
        this.fetchDatas(this.props)
        this.getTreeData(this.props)
	}

    UNSAFE_componentWillUpdate(nextProps) {
        this.fetchDatas(nextProps)
    }

    getTreeData(props) {
		let datas = persistentData.get('etablissements')
		let toArray = Object.values(datas)
		this.tree = Immutable.List(toArray)
    }

    fetchDatas(props) {
		if (props.selectorReady) {
            const newParams = fetchDatasManager.getParams(DATA_TYPE_CARTE_CONCURRENCE, props.routingParams);
            if (fetchDatasManager.hasDifferences(props.fetchData, newParams)) {
                props.fetch(newParams, DATA_TYPE_CARTE_CONCURRENCE, '/carte/concurrence');
            }
        }
    }

    getBottomNavTabs(){
        return [
            {
                linkKey: DATA_FILTER_TYPE_REG_16,
                transKey: 'dataType.PdmNouvelleRegion',
                isActive: DATA_FILTER_TYPE_REG_16 === this.props.pdmType,
            },
            {
                linkKey: DATA_FILTER_TYPE_REG_15,
                transKey: 'dataType.PdmAncienneRegion',
                isActive: DATA_FILTER_TYPE_REG_15 === this.props.pdmType,
            },
        ]
    }
    render(){
        if (this.props.fetchData.pending || null === this.props.etabs.datas) {
            return <HelixLoader />
        } else if (!this.props.fetchData.pending && null !== this.props.etabs.datas){
            return (
                <div className="concurrence-page-content">
                    <div className="carte-container">
                        <CarteConcurrence />
                        <div className="carte-bottom-panel selection-not-active">
                            <CarteBottomNav tabs={this.getBottomNavTabs()} selectionDisabled={true} paramName={PARAM_NAME_PDM}/>
                        </div>
                    </div>

                </div>
            );
        } else {
            return null
        }
    }
}


ConcurrencePageContent.propTypes = {
    visible: PropTypes.bool,
    pdmType: PropTypes.string,
    fetchData: PropTypes.object,
    routingParams: PropTypes.object,
    dataType: PropTypes.string,
    selectorReady: PropTypes.bool,
    etabs: PropTypes.object,
	route: PropTypes.string
};

function mapStateToProps(state) {
    return {
        visible: PAGE_NAME_CARTE_CONCURENCE === state.routing.currentRouteName,
        pdmType: state.routing.params[PARAM_NAME_PDM],
        fetchData: state.fetchData[DATA_TYPE_CARTE_CONCURRENCE],
        routingParams: fetchDatasManager.getParams(DATA_TYPE_CARTE_CONCURRENCE, state.routing.params),
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
		route: state.routing.currentRouteName,
        selectorReady: state.mainSelector.ready,
        etabs: state[DATA_TYPE_CARTE_CONCURRENCE]
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetch: (params, dataType, url) => {
            dispatch(fetch(params, dataType, url));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConcurrencePageContent);
