import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {navigate} from 'actions/routerActions';
import {clean} from 'actions/selectorActions';
import cn from 'classnames'

import {SELECTOR_TYPE_ETS_B} from 'constants/selectorTypes';

import SelectorNavCounter from 'components/molecules/Selector/SelectorNavCounter';
import Trans from 'components/atoms/Format/Trans';

class SelectorNavItem extends React.Component {

    onClickHandler(e) {
        this.props.navigate(this.props.destPage);
    }

    onCounterClickHandler(isRemoveEnabled, type, e) {
        if (isRemoveEnabled) {
            this.props.clean(type);
            e.stopPropagation();
        }
    }

    getSelecteurCounters() {
        return this.props.reducers.map((propsName, i) => {
            let count = this.props[propsName].size,
                isEmpty = !this.props.isDefaultAll && 0 == count,
                type = SELECTOR_TYPE_ETS_B != propsName ? 'bench-a' : 'bench-b',
                isRemoveEnabled = this.props.isRemoveEnabled && this.props[propsName].size > 0
                ;
            if (this.props.isDefaultAll && 0 == count) {
                count = <Trans k='selecteur.tous'/>;
                type = 'tous';
            }
            return (
                <SelectorNavCounter
                    key={propsName}
                    lib={count}
                    type={type}
                    isEmpty={isEmpty}
                    isRemoveEnabled={isRemoveEnabled}
                    onClickHandler={this.onCounterClickHandler.bind(this, isRemoveEnabled, propsName)}
                />
            );
        });
    }

    render() {
        return (
            <li className={cn('item', {active: this.props.active})}
                 onClick={this.onClickHandler.bind(this)}>
                <div className={'illustration ' + this.props.type}>
                    <div className="icon" />
                </div>
                { this.getSelecteurCounters() }
            </li>
        )
    }
}

SelectorNavItem.propTypes = {
    reducers: PropTypes.array.isRequired,
    isDefaultAll: PropTypes.bool.isRequired,
    isRemoveEnabled: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    destPage: PropTypes.string.isRequired,
    navigate: PropTypes.func,
    clean: PropTypes.func,
    type: PropTypes.string.isRequired
};

function mapStateToProps(state, ownProps) {
    let newProps = {};
    ownProps.reducers.forEach((reducerName) => {
        newProps[reducerName] = state.selector[reducerName];
    });

    return newProps;
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (pageName) => {
            dispatch(navigate(pageName));
        },
        clean: (type) => {
            dispatch(clean(type));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorNavItem);
