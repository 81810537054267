import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';

export default class SwitchMapSynchroButton extends React.Component {
    getIcon(){
        if(this.props.active){
            return <span className="icon icon-verrou-close" />;
        }
        
        return <span className="icon icon-verrou-open" />;
    }
    render(){
        if(!this.props.visible){
            return null;
        }
        return (
            <div className="switch-carte-synchro">
                <div className={cn('action', {active: this.props.active})} onClick={this.props.onClickHandler}>
                    {this.getIcon()}
                </div>
            </div>
        );
    }
}

SwitchMapSynchroButton.defaultProps = {
    visible: false,
    active: true,
    onClickHandler: () => {}
};
SwitchMapSynchroButton.propTypes = {
    visible: PropTypes.bool,
    active: PropTypes.bool,
    onClickHandler: PropTypes.func
};