import React, {Component} from 'react'
import {connect} from 'react-redux'
import cn from 'classnames'
import PropTypes from 'prop-types';

import {PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE} from 'constants/router'
import {
    SELECTOR_TYPE_ETS_A,
    SELECTOR_TYPE_ETS_B,
    SELECTOR_TYPE_FR,
    BENCHMARK_LETTERS
} from 'constants/selectorTypes';

import {fetchGhmDetailData, clearGhmDetailData} from 'actions/ficheGhmDetailActions';

import Percent from "components/atoms/Format/Percent";
import FormattedDataType from "components/molecules/Format/FormattedDataType";
import GhmListDetail from "./GhmListDetail";


class GhmListRacine extends Component {

    constructor(props) {
        super(props)

        this.maxHeight = 90
        this.minHeight = 15
    }

    renderBench(bench) {
        const benchPercent = this.getBenchValue(this.props.racine, bench, 'p')
        if (null === benchPercent) {
            return null
        }

        const ratio = benchPercent / this.props.maxPercent
        const height = ((this.maxHeight - this.minHeight) * ratio) + this.minHeight

        return (
            <div className={'bench-' + bench} style={{height}}>
                <div className="bench-header bordered">
                    <div className="letter">{BENCHMARK_LETTERS[bench]}</div>
                    <div className={cn('values', {
                        multiline: (height > 30),
                        smaller: (height < 30),
                    })}>
                        <Percent value={benchPercent} className="percent"/>
                        <FormattedDataType value={this.getBenchValue(this.props.racine, bench)}
                                           className="number"
                                           condensed={true} maximumFractionDigits={0}/>
                    </div>
                </div>
                {this.renderListGhm(bench)}
            </div>
        )
    }

    toogleGhmDetail(idGhm) {
        if (this.props.expandedGhm !== idGhm) {
            this.props.fetchGhmDetailData(this.props.idAnnee, idGhm)
        }
        else {
            this.props.clearGhmDetailData()
        }
    }

    renderListGhm(bench) {
        const ghms = this.getSortedListGhm()

        return (
            <div className="list-ghm">
                {ghms.map(ghm => {
                    const ghmValue = this.getBenchValue(ghm, bench)
                    if (null !== ghmValue) {
                        const isSelected = this.props.expandedGhm === ghm.id
                        return (
                            <div key={ghm.code} style={{flexGrow: ghmValue}}
                                 className={cn('ghm', 'severite-' + ghm.severite, {selected: isSelected})}
                                 onClick={this.toogleGhmDetail.bind(this, ghm.id)}>
                                {ghm.severite}
                            </div>
                        )
                    }
                return null
                })}
            </div>
        )
    }

    getSortedListGhm() {
        const ghms = []
        this.props.racine.children.forEach(ghmInfos => {
            const severite = ghmInfos.code.substr(-1)
            const ghmDatas = this.props.ghms[ghmInfos.id]
            ghms.push({
                ...ghmInfos,
                ...ghmDatas,
                severite,
            })
        })

        const severiteOrder = ['J', 'T', 1, 2, 3, 4, 'Z', 'E']
        let sortValues = {}
        severiteOrder.forEach((severite, order) => {
            sortValues[severite] = order
        })

        ghms.sort((left, right) => {
            return (sortValues[left.severite] > sortValues[right.severite]) ? 1 : -1
        })

        return ghms
    }

    hasExpandedGhm() {
        return (0 < this.getSortedListGhm().filter(ghm => ghm.id === this.props.expandedGhm).length)
    }

    getBenchValue(item, bench, key = '') {
        if (undefined === item[bench] || undefined === item[bench][key + this.props.dataType]) {
            return null
        }
        return item[bench][key + this.props.dataType]
    }

    render() {
        const parts = this.props.racine.code.split('_')
        const hasExpandedGhm = this.hasExpandedGhm()

        const infosRacineA = this.renderBench(SELECTOR_TYPE_ETS_A)
        let infosRacineB = this.renderBench(SELECTOR_TYPE_ETS_B)
        if (null === infosRacineB) {
            infosRacineB = this.renderBench(SELECTOR_TYPE_FR)
        }

        const isInfosBenchmark = (null !== infosRacineA && null !== infosRacineB)

        const ghmListDetail = (hasExpandedGhm)
            ? <GhmListDetail ghmDatas={this.props.ghms[this.props.expandedGhm]} />
            : <GhmListDetail />


        return (
            <div className={cn('racine-background', {selected: hasExpandedGhm})}>
                <div className="center-container racine">
                    <div className="code-racine" title={this.props.racine.lib}>
                        {parts.map((part, index) => {
                            return <div key={index} className={'part-' + index}>{part}</div>
                        })}
                    </div>
                    <div className={cn('infos-racine', {benchmark: isInfosBenchmark})}>
                        {infosRacineA}
                        {ghmListDetail}
                        {infosRacineB}
                    </div>
                </div>
            </div>
        )
    }
}

GhmListRacine.propTypes = {
    idAnnee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataType: PropTypes.string,
    expandedGhm: PropTypes.number,
    fetchGhmDetailData: PropTypes.func,
    clearGhmDetailData: PropTypes.func,
    racine: PropTypes.object.isRequired,
    ghms: PropTypes.object.isRequired,
    maxPercent: PropTypes.number.isRequired,
}


function mapStateToProps(state, ownProps) {
    return {
        idAnnee: state.routing.params[PARAM_NAME_ANNEE],
        dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
        expandedGhm: state.ficheGhmDetail.idGhm,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchGhmDetailData: (idAnnee, idGhm) => {
            dispatch(fetchGhmDetailData(idAnnee, idGhm));
        },
        clearGhmDetailData: () => {
            dispatch(clearGhmDetailData());
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(GhmListRacine);