import {
    PAGE_NAME_SELECTEUR_ETABLISSEMENT,
    PAGE_NAME_SELECTEUR_APPAREIL,
    PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE,
    PAGE_NAME_SELECTEUR_GHM,
    PAGE_NAME_SELECTEUR_AGE,
    PAGE_NAME_FICHE_ETABLISSEMENTS,
    PAGE_NAME_FICHE_APPAREIL,
    PAGE_NAME_FICHE_PRISE_EN_CHARGE,
    PAGE_NAME_FICHE_GHM,
    PAGE_NAME_FICHE_SEXE_AGE
} from 'constants/router'

import {DATA_FILTER_TYPE_REG_15, DATA_FILTER_TYPE_REG_16} from 'constants/dataFilterType.js'
import {sortRegion, sortLibAsc} from '../utils/sort.js'

import cloneDeep from 'lodash/cloneDeep'

import persistentData from 'managers/persistentData'

class TreeMaker {
    etablissement15Data = null
    etablissement16Data = null
    appareilData = null
    chargeData = null
    ghmData = null
    ageData = null

    setEtablissementData(regionType) {
        let etablissements = cloneDeep(persistentData.get('etablissements'))
        let departements = cloneDeep(persistentData.get('departements'))
        let regions = cloneDeep(persistentData.get('regions'))

        departements = Object.values(departements).map(departement => {
            departement.children = Object.values(etablissements)
                .filter(etablissement => {
                    return etablissement.id_parent === departement.id
                })
                .sort(sortLibAsc)
                .map(etablissement => {
                    etablissement.expanded = false
                    etablissement.selected = false
                    etablissement.beingSearched = null
                    etablissement.parent = departement
                    return etablissement
                })
            return departement
        })

        regions = Object.values(regions)
            .filter(region => {
                if (999 === region.id) return false
                if (DATA_FILTER_TYPE_REG_15 === regionType && region.is15) return true
                if (DATA_FILTER_TYPE_REG_16 === regionType && region.is16) return true
                return false
            })
            .sort(sortRegion)
            .map(region => {
                region.expanded = false
                region.beingSearched = null
                region.nb_children_bench_A = 0
                region.nb_children_bench_B = 0

                region.children = departements
                    .filter(departement => {
                        return departement['id_' + regionType] === region.id
                    })
                    .map(departement => {
                        departement.id_parent = departement['id_' + regionType]
                        departement.expanded = false
                        departement.beingSearched = null
                        departement.nb_children_bench_A = 0
                        departement.nb_children_bench_B = 0
                        departement.parent = region
                        return departement
                    })
                return region
            })

        return regions //(DATA_FILTER_TYPE_REG_16 === regionType) ? regions16 : regions15
    }

    setAppareilData() {
        let appareils = persistentData.get('appareils')
        let organes = persistentData.get('organes')

        return Object.values(appareils).sort(sortLibAsc).map(appareil => {
            appareil.expanded = false
            appareil.selected = false
            appareil.beingSearched = null
            appareil.nb_children_selected = 0
            appareil.children = Object.values(organes)
                .filter(organe => {
                    return organe.id_parent === appareil.id
                })
                .sort(sortLibAsc)
                .map(organe => {
                    organe.parent = appareil
                    organe.beingSearched = null
                    organe.selected = false
                    return organe
                })
            return appareil
        })
    }

    setChargeData() {
        let priseEnCharges = persistentData.get('priseEnCharge')

        return Object.values(priseEnCharges)
            .sort(sortLibAsc)
            .map(priseEnCharge => {
                priseEnCharge.selected = false
                priseEnCharge.beingSearched = null
                return priseEnCharge
            })
    }

    setGhmData() {
        let cmdList = persistentData.get('cmd')
        let racines = persistentData.get('racines')
        let ghmList = persistentData.get('ghm')
        let ghsList = persistentData.get('ghs')

        ghsList = Object.values(ghsList)
            .sort(sortLibAsc)
            .map(item => {
                item.expanded = false
                item.selected = false
                item.beingSearched = null
                return item
            })

        ghmList = this.mergeTree(ghmList, ghsList)
        racines = this.mergeTree(racines, ghmList)
        cmdList = this.mergeTree(cmdList, racines)

        return cmdList
    }

    mergeTree(parents, children) {
        return Object.values(parents).map(item => {
            item.expanded = false
            item.selected = false
            item.beingSearched = null
            item.children = Object.values(children)
                .filter(child => child.id_parent === item.id)
                .sort(sortLibAsc)
                .map(child => {
                    child.parent = item
                    return child
                })
            return item
        })
    }

    setAgeData() {
        const ages = Object.values(persistentData.get('age'))
            .sort((left, right) => {
                return (left.borne_inf > right.borne_inf) ? 1 : -1;
            })
        return ages.map(age => {
            let leaf = age
            leaf.selected = false
            leaf.beingSearched = null
            return leaf
        })
    }


    getData(currentRoute, regionType = null) {
        switch (currentRoute) {
            case PAGE_NAME_SELECTEUR_ETABLISSEMENT:
            case PAGE_NAME_FICHE_ETABLISSEMENTS: {
                if (DATA_FILTER_TYPE_REG_15 === regionType) {
                    if (null === this.etablissement15Data || undefined === this.etablissement15Data) {
                        this.etablissement15Data = this.setEtablissementData(regionType)
                    }
                    return this.etablissement15Data
                } else if (DATA_FILTER_TYPE_REG_16 === regionType) {
                    if (null === this.etablissement16Data || undefined === this.etablissement16Data) {
                        this.etablissement16Data = this.setEtablissementData(regionType)
                    }
                    return this.etablissement16Data
                }
                break
            }
            case PAGE_NAME_SELECTEUR_APPAREIL:
            case PAGE_NAME_FICHE_APPAREIL: {
                if (null === this.appareilData) {
                    this.appareilData = this.setAppareilData()
                }
                return this.appareilData
            }
            case PAGE_NAME_SELECTEUR_PRISE_EN_CHARGE:
            case PAGE_NAME_FICHE_PRISE_EN_CHARGE: {
                if (null === this.chargeData) {
                    this.chargeData = this.setChargeData()
                }
                return this.chargeData
            }
            case PAGE_NAME_SELECTEUR_GHM:
            case PAGE_NAME_FICHE_GHM: {
                if (null === this.ghmData) {
                    this.ghmData = this.setGhmData()
                }
                return this.ghmData
            }
            case PAGE_NAME_SELECTEUR_AGE:
            case PAGE_NAME_FICHE_SEXE_AGE: {
                if (null === this.ageData) {
                    this.ageData = this.setAgeData()
                }
                return this.ageData
            }
        }
    }
}

const treeMaker = new TreeMaker();

export default treeMaker;
