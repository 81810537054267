import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";
import SelectorInfoItem from "components/molecules/Selector/SelectorInfoItem";
import {
  SELECTOR_TYPE_ETS_A,
  SELECTOR_TYPE_ETS_B,
  BENCHMARK_LETTERS,
  SELECTOR_TYPE_APPAREIL,
  SELECTOR_TYPE_PRISE_EN_CHARGE,
  SELECTOR_TYPE_GHM,
  SELECTOR_TYPE_AGE,
} from "constants/selectorTypes";

import persistentData from "managers/persistentData";

class SelectorInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  getItems(type) {
    let item = {};
    switch (type) {
      case SELECTOR_TYPE_ETS_A:
      case SELECTOR_TYPE_ETS_B: {
        item.persistDatas = persistentData.get("etablissements");
        break;
      }
      case SELECTOR_TYPE_APPAREIL: {
        item.persistDatas = persistentData.get("organes");
        // item.tree = treeMaker.getData(PAGE_NAME_SELECTEUR_APPAREIL, this.props.mainSelector);
        break;
      }
      case SELECTOR_TYPE_PRISE_EN_CHARGE: {
        item.persistDatas = persistentData.get("priseEnCharge");
        break;
      }
      case SELECTOR_TYPE_GHM: {
        item.persistDatas = persistentData.get("ghs");
        // item.tree = treeMaker.getData(PAGE_NAME_SELECTEUR_GHM, this.props.mainSelector);
        break;
      }
      case SELECTOR_TYPE_AGE: {
        item.persistDatas = persistentData.get("age");
        break;
      }
      default:
        break;
    }
    return item;
  }

  isSelectorEmpty() {
    return !Object.keys(this.props.selector).some((selectorType) => {
      return !this.props.selector[selectorType].isEmpty();
    });
  }

  detailAction() {
    this.setState({
      active: !this.state.active,
    });
  }

  renderItems() {
    return Object.keys(this.props.selector).map((selectorType) => {
      const isEtablissement =
        SELECTOR_TYPE_ETS_A === selectorType ||
        SELECTOR_TYPE_ETS_B === selectorType;
      const selector = this.props.selector[selectorType];
      let prefix = null;

      if (isEtablissement) {
        prefix = (
          <div className="prefix">
            <span className="letter">{BENCHMARK_LETTERS[selectorType]}</span>
            <span className="separator">-</span>
          </div>
        );
      }

      if (!selector.isEmpty()) {
        return (
          <SelectorInfoItem
            key={selectorType}
            type={selectorType}
            prefix={prefix}
            active={this.state.active}
            items={this.getItems(selectorType)}
          />
        );
      }
      return null;
    });
  }

  render() {
    if (this.isSelectorEmpty()) {
      return null;
    }
    return (
      <div>
        <div
          className={cn("center-container selector-info", {
            active: this.state.active,
          })}
        >
          <div className="scroll-container">{this.renderItems()}</div>
        </div>
        <div
          onClick={this.detailAction.bind(this)}
          className={cn("selector-liste-btn", { active: this.state.active })}
        >
          <span className="icon icon-arrow-down" />
        </div>
      </div>
    );
  }
}

SelectorInfo.propTypes = {
  selector: PropTypes.object,
  mainSelector: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    selector: state.selector,
    mainSelector: state.mainSelector.regionsType,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectorInfo);
