import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import cn from "classnames";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
  Label,
} from "recharts";
import { useTranslation } from "react-i18next";

import persistentData from "managers/persistentData";

import { SELECTOR_TYPE_AGE } from "constants/selectorTypes";
import { PARAM_NAME_DATA_TYPE } from "constants/router";

import Percent from "components/atoms/Format/Percent";
import FormattedDataType from "components/molecules/Format/FormattedDataType";
import {
  DATA_FILTER_TYPE_SEJOUR,
  DATA_FILTER_TYPE_VALO,
} from "constants/dataFilterType";

const $A = "#EF632F";
const $B = "#8A4765";
const $G2 = "#7397A8";

export const benchColor = {
  etsA: $A,
  etsB: $B,
  fr: $G2,
};

const AgesChart = ({ datas }) => {
  const [isAnimationActive, setIsAnimationActive] = useState(true);
  const { t } = useTranslation();
  const dataType = useSelector(
    (state) => state.routing.params[PARAM_NAME_DATA_TYPE]
  );
  const selectorAge = useSelector((state) => state.selector[SELECTOR_TYPE_AGE]);

  useEffect(() => {
    setIsAnimationActive(false);
  }, []);

  const formatDatas = () => {
    const chartDatas = {};
    Object.keys(datas).forEach((bench) => {
      Object.keys(persistentData.get("age")).forEach((idAge) => {
        const value = datas[bench][idAge]
          ? datas[bench][idAge]["p" + dataType] * 100
          : 0;

        chartDatas[idAge] = {
          ...chartDatas[idAge],
          ...persistentData.get("age")[idAge],
          ["data-" + bench]: { ...datas[bench][idAge] },
          [bench]: value,
        };
      });
    });

    return Object.values(chartDatas).sort((left, right) => {
      return left.borne_inf > right.borne_inf ? 1 : -1;
    });
  };

  const getTooltipContent = (params) => {
    const { label, payload } = params;
    return (
      <div className="tooltip">
        <p className="label-tooltip">{label}</p>
        <ul className="ul-tooltip">
          {payload.map((bench) => {
            const data = bench.payload["data-" + bench.name];
            if (data.hasOwnProperty(dataType)) {
              return (
                <li className={"bench-" + bench.name} key={bench.name}>
                  <Percent value={data["p" + dataType]} className="percent" />
                  <FormattedDataType value={data[dataType]} withLib={true} />
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    );
  };

  const lineDotActive = (param) => {
    const { cx, cy, dataKey } = param;
    return (
      <g fill={benchColor[dataKey]} className={"recharts-line-chart-dot"}>
        <circle cx={cx} cy={cy} r={10} />
      </g>
    );
  };

  const renderSelectedClasse = () => {
    return Object.values(persistentData.get("age")).map((age) => {
      if (undefined !== selectorAge.get(age.id)) {
        return <ReferenceLine key={age.id} x={age.lib} stroke="red" />;
      }
      if (0 === age.borne_inf) {
        return <ReferenceLine key={age.id} x={age.lib} stroke="grey" />;
      }
      return null;
    });
  };

  const renderLines = () => {
    return Object.keys(datas).map((bench) => {
      return (
        <Line
          key={bench}
          type="monotone"
          dataKey={bench}
          stroke={benchColor[bench]}
          animationDuration={250}
          isAnimationActive={isAnimationActive}
          strokeWidth={2}
          strokeDasharray="6 2"
          dot={{
            fill: benchColor[bench],
            r: 3,
            strokeOpacity: 0,
            strokeWidth: 10,
          }}
          activeDot={lineDotActive}
        />
      );
    });
  };

  let labelTransKey = "";
  switch (dataType) {
    case DATA_FILTER_TYPE_SEJOUR:
      labelTransKey = "fiche.sexeAge.YAxisLabel.sejour";
      break;
    case DATA_FILTER_TYPE_VALO:
      labelTransKey = "fiche.sexeAge.YAxisLabel.valo";
      break;
    default:
      labelTransKey = "AXE_Y_LABEL";
  }

  return (
    <div className="ages-container">
      <div
        className={cn("center-container", {
          benchmark: Object.keys(datas).length > 1,
        })}
      >
        <ResponsiveContainer width="100%" height={370 + 40}>
          <LineChart
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            data={formatDatas()}
          >
            <XAxis
              tickLine={false}
              tickMargin={5}
              height={40}
              padding={{ right: 20 }}
              dataKey="lib"
              style={{
                fill: "#adc2cf",
                fontSize: "12px",
                textTransform: "uppercase",
              }}
              axisLine={{
                stroke: "#adc2cf",
                strokeWidth: 0, 
              }}
            >
              <Label
                value={t("fiche.sexeAge.XAxisLabel")}
                position="insideLeft"
                dx={-10}
                dy={10}
                style={{
                  textAnchor: "start",
                  fill: "#adc2cf",
                  fontSize: "12px",
                  textTransform: "uppercase",
                }}
              />
            </XAxis>
            <YAxis
              width={50}
              padding={{ top: 20 }}
              allowDecimals={false}
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#adc2cf", fontSize: "12px", transform: "translate(-5, 0)" }}
            >
              <Label
                value={t(labelTransKey)}
                position="insideBottom"
                angle={-90}
                dy={-10}
                dx={-10}
                style={{
                  textAnchor: "start",
                  fill: "#adc2cf",
                  fontSize: "12px",
                  textTransform: "uppercase",
                }}
              />
            </YAxis>
            <Tooltip content={getTooltipContent} offset={15} />

            <CartesianGrid vertical={false} />

            {renderSelectedClasse()}

            {renderLines()}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

AgesChart.propTypes = {
  datas: PropTypes.object,
};

export default AgesChart;
