import isEqual from 'lodash/isEqual';
import {
    DATAS_PENDING,
    DATAS_FULFILLED,
    DATAS_REJECTED,
    CARTE_ATTRACTIVITE_LOAD_ERROR
} from 'constants/actionTypes';

import {
    DATA_TYPE_ATTRACTIVITE_TILES
} from 'constants/dataType';
import {getTileURL} from '../../managers/windshaftUtilities';

export default function carteTilesReducer(state = {
    url: null,
    classes: null,
    error: false,
}, action) {
    switch (action.type) {
        case DATAS_PENDING :
        case DATAS_REJECTED : {
            if (!isEqual([DATA_TYPE_ATTRACTIVITE_TILES, this.type], action.dataType)) break;

            return {...state, url: null, classes: null, error: false};
        }
        case DATAS_FULFILLED : {
            if (!isEqual([DATA_TYPE_ATTRACTIVITE_TILES, this.type], action.dataType)) break;

            return {
                ...state,
                url: getTileURL(action.payload.data.response.layergroupid, action.payload.data.backend),
                classes: action.payload.classes
            };
        }
        case CARTE_ATTRACTIVITE_LOAD_ERROR : {
            return {...state, error: true};
        }
        default: break;
    }

    return state;
}