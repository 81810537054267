const UNSELECTED_ICON_WITH = 31;
const UNSELECTED_ICON_HEIGHT = 38;
const SELECTED_ICON_WITH = 39;
const SELECTED_ICON_HEIGHT = 48;

function getUnselectedIcon(benchClassName, number) {
    return `
        <svg width="31px" height="38px" viewBox="0 0 31 38">
            <defs>
                <path d="M22.7600002,11.379 C22.7600002,21.724 11.3805001,30 11.3805001,30 C11.3805001,30 0,21.724 0,11.379 C0,5.094 5.36823592,0 11.3805001,0 C17.3917643,0 22.7600002,5.094 22.7600002,11.379" id="marker-unselected-icon-path-1"></path>
                <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="marker-unselected-icon-filter">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(4.000000, 4.000000)">
                    <g>
                        <use fill="black" fill-opacity="1" filter="url(#marker-unselected-icon-filter)" xlink:href="#marker-unselected-icon-path-1"></use>
                        <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#marker-unselected-icon-path-1"></use>
                    </g>
                    <path class="${benchClassName}" d="M20,11.5 C20,16.1949732 16.1949732,20 11.5,20 C6.80502677,20 3,16.1949732 3,11.5 C3,6.80502677 6.80502677,3 11.5,3 C16.1949732,3 20,6.80502677 20,11.5"></path>
                    <text class="${benchClassName}" font-family="SourceSansPro-Bold, Source Sans Pro" font-size="12" font-weight="bold" fill="#FFFFFF">
                        <tspan x="8" y="15">${number}</tspan>
                    </text>
                </g>
            </g>
        </svg>
    `;
}
function getSelectedIcon(benchClassName, number) {
    return `
        <svg width="39px" height="48px" viewBox="0 0 39 48">
            <defs>
                <path d="M13.923,37.8555 C13.391,37.4685 0.897,28.2405 0.897,16.0005 C0.897,7.6635 7.939,0.6205 16.276,0.6205 C24.612,0.6205 31.655,7.6635 31.655,16.0005 C31.655,28.2405 19.16,37.4685 18.629,37.8555 L16.276,39.5665 L13.923,37.8555 Z" id="marker-selected-iocn-path-1"></path>
                <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="marker-selected-icon-filter">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(3.000000, 4.000000)">
                    <g>
                        <use fill="black" fill-opacity="1" filter="url(#marker-selected-icon-filter)" xlink:href="#marker-selected-iocn-path-1"></use>
                    </g>
                    <path class="${benchClassName}" d="M13.923,37.8555 C13.391,37.4685 0.897,28.2405 0.897,16.0005 C0.897,7.6635 7.939,0.6205 16.276,0.6205 C24.612,0.6205 31.655,7.6635 31.655,16.0005 C31.655,28.2405 19.16,37.4685 18.629,37.8555 L16.276,39.5665 L13.923,37.8555 L13.923,37.8555 Z" stroke="#FFFFFF" stroke-width="3"></path>
                    <path d="M25,16.5 C25,21.1949732 21.1949732,25 16.5,25 C11.8050268,25 8,21.1949732 8,16.5 C8,11.8050268 11.8050268,8 16.5,8 C21.1949732,8 25,11.8050268 25,16.5" fill="#FFFFFF"></path>
                    <text class="${benchClassName}" font-family="SourceSansPro-Bold, Source Sans Pro" font-size="12" font-weight="bold">
                        <tspan x="13" y="20">${number}</tspan>
                    </text>
                </g>
            </g>
        </svg>
    `;
}



export default function getDivIconOptions(isSelected = false, benchType = null, number = null){
    number = null === number ? '' : '' + number;
    let width = isSelected ? SELECTED_ICON_WITH : UNSELECTED_ICON_WITH,
        height = isSelected ? SELECTED_ICON_HEIGHT : UNSELECTED_ICON_HEIGHT,
        benchClassName = null === benchType ? 'bench-default' : 'bench-' + benchType,
        className = isSelected ? 'leaflet-div-icon leaflet-div-icon-selected' : 'leaflet-div-icon leaflet-div-icon-unselected'
    ;
    return {
        html: isSelected
            ? getSelectedIcon(benchClassName, number)
            : getUnselectedIcon(benchClassName, number),
        iconSize:[width, height],
        iconAnchor:[width/2, height -3],
        className
    };
}