import {DATAS_FULFILLED} from 'constants/actionTypes'
import {DATA_TYPE_FICHE_GHM} from 'constants/dataType.js'

const initialState = {
    selection: null,
    racines: null,
    ghms: null,
};

export default function ficheGhmReducer(state = initialState, action) {
if (DATAS_FULFILLED === action.type && action.dataType === DATA_TYPE_FICHE_GHM) {
        return {
            ...state,
            selection: action.payload.selection,
            racines: action.payload.racines,
            ghms: action.payload.ghms,
        }
    }
    return state;
}
