import React, {Component} from 'react'
import {SortDirection} from 'react-virtualized'
import PropTypes from 'prop-types';


export default class SortIndicator extends Component {
    render() {
        const ascClass = (this.props.sortActive && this.props.sortDirection === SortDirection.ASC ? ' active' : '');
        const descClass = (this.props.sortActive && this.props.sortDirection === SortDirection.DESC ? ' active' : '');

        return (
            <div className="sort">
                <span className={'icon icon-arrow-up asc' + ascClass}/>
                <span className={'icon icon-arrow-down desc' + descClass}/>
            </div>
        )
    }
}

SortIndicator.propTypes = {
    sortActive: PropTypes.string,
    sortDirection: PropTypes.object,
}