import axios from "axios";
import { PARAM_NAME_ACCES_TIME } from "constants/router";
import { formatAccessTimeParam } from "../managers/appRouter";
import { API_URL } from "constants/config";
import fetchDatasManager from "../managers/fetchDatasManager";

const DATAS_ACTION_TYPES = {
  PENDING: "DATAS_PENDING",
  FULFILLED: "DATAS_FULFILLED",
  REJECTED: "DATAS_REJECTED",
};

export const fetch =
  (params, dataType, apiRoute, extraParams = {}) =>
  async (dispatch, getState) => {
    const { PENDING, FULFILLED, REJECTED } = DATAS_ACTION_TYPES;

    dispatch({ type: PENDING, payload: { params, ...extraParams }, dataType });

    try {
      const response = await axios.post(
        `${API_URL}${apiRoute}`,
        {
          ...params,
          ...extraParams,
          [PARAM_NAME_ACCES_TIME]: formatAccessTimeParam(
            params[PARAM_NAME_ACCES_TIME]
          ),
        },
        { withCredentials: true }
      );

      if (
        !fetchDatasManager.hasDifferences(
          getState().fetchData[dataType],
          params
        )
      ) {
        dispatch({ type: FULFILLED, payload: response.data, dataType });
      }
    } catch (error) {
      dispatch({ type: REJECTED, payload: error.response, dataType });
    }
  };
