import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Trans from "components/atoms/Format/Trans";

export default class ErrorPageContent extends Component {

    render() {
        return (
            <div className="container">
                <div className="illustration">
                    <div className="svg-img" />
                </div>
                <div className="titre">
                    <Trans k={this.props.title} />
                </div>
                <div className="description">
                    <Trans k={this.props.description} />
                </div>
                {this.props.actionButton}
            </div>
        );
    }
}

ErrorPageContent.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    actionButton: PropTypes.object.isRequired
};