import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "react-virtualized/styles.css";
import Immutable from "immutable";

import persistentData from "managers/persistentData.js";

import { PARAM_NAME_ANNEE, PARAM_NAME_DATA_TYPE } from "constants/router";

import {
  DATA_FILTER_TYPE_REG_15,
  DATA_FILTER_TYPE_REG_16,
  DATA_FILTER_TYPE_DEP,
  DATA_FILTER_TYPE_FRANCE,
  DATA_FILTER_TYPE_ZONE_ACCES_TIME,
  DATA_FILTER_TYPE_ZONE_CHALANDISE,
  DATA_FILTER_TYPE_ZONE_PROXIMITE,
  DATA_FILTER_SELECTION_ALL,
  DATA_FILTER_SELECTION_IN,
  DATA_FILTER_CATEGORY_ALL,
  DATA_FILTER_DEPARTEMENT_ALL,
  DATA_FILTER_INITIAL_STATE,
} from "constants/dataFilterType";
import { DATA_TYPE_FICHE_ETABLISSEMENTS } from "constants/dataType";

import ListEtablissements from "components/molecules/FicheEtablissements/ListEtablissements";
import FilterDropdown from "components/molecules/FilterDropdownContent/FilterDropdown";
import ZonesFilter from "components/molecules/ZonesFilter/ZonesFilter";

import { fetchEtablissementsDatas } from "actions/ficheEtablissementsDatasActions.js";
import AccesTimeLib from "components/molecules/ZonesFilter/AccesTimeLib";
import Trans from "components/atoms/Format/Trans";

export class FicheEtablissementsListContainer extends ListEtablissements {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
    this.state = { ...DATA_FILTER_INITIAL_STATE };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      this.props.dataType !== nextProps.dataType ||
      (true === this.props.dataPending && false === nextProps.dataPending)
    ) {
      this.setEtablissementsList(null);
    }

    if (
      (null === this.props.datas && null !== nextProps.datas) ||
      (null !== this.props.datas && null === nextProps.datas)
    ) {
      this.setEtablissementsList(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toggleReset !== this.props.toggleReset) {
      this.setState({ ...DATA_FILTER_INITIAL_STATE });
    }
  }

  handleClickOutside = (event) => {
    if (
      this.state.dropdownOpen &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ dropdownOpen: false });
    }
  };

  initEtablissementsList() {
    let list = Object.values(this.props.etablissements).map((etsValues) => {
      const { id, bench } = etsValues;
      const { cat, code, lib, sec, id_parent } =
        persistentData.get("etablissements")[id];
      const dep = persistentData.get("departements")[id_parent];

      let row = {
        id,
        cat,
        code,
        lib,
        sec,
        bench,
        id_dep: dep.id,
        lib_dep: dep.lib,
      };

      Object.keys(persistentData.get("annees")).forEach((idAnnee) => {
        if (undefined !== this.props.datas && null !== this.props.datas) {
          const value =
            this.props.datas[id].value[idAnnee][this.props.dataType];
          row["value_" + idAnnee] = null === value ? "general.nd" : value;

          const pdm = this.props.datas[id].pdm[idAnnee][this.props.dataType];
          row["pdm_" + idAnnee] = null === pdm ? "general.nd" : pdm;
        } else {
          row["value_" + idAnnee] = null;
          row["pdm_" + idAnnee] = null;
        }
      });

      return row;
    });

    return Immutable.List(list);
  }

  getColumnsConf() {
    const columnSpacer = {
      ...this.getColumnDefault(),
      dataKey: "columnSpacer",
      width: 0,
      disableSort: true,
      className: "cell-spacer",
    };
    const columnRank = {
      ...this.getColumnDefault(),
      dataKey: "rank",
      width: 30,
      flexGrow: 0,
      disableSort: true,
      className: "cell-rank",
    };
    const columnLib = {
      ...this.getColumnDefault({
        filterDropdown: this.getFilterSelectionDropdown(),
        labelComponent: (
          <span>
            <Trans className="label-title" k="general.etablissement" />
            <br />
            <Trans k={"fiche.etablissements." + this.state.filterSelection} />
          </span>
        ),
      }),
      dataKey: "lib",
      width: 150,
      flexGrow: 3,
    };
    const columnCaegorie = {
      ...this.getColumnDefault({
        filterDropdown: this.getFilterCategoryDropdown(),
        labelComponent: (
          <span>
            <Trans
              className="label-title"
              k="fiche.etablissements.header.category"
            />
            <br />
            {DATA_FILTER_CATEGORY_ALL === this.state.filterCategory ? (
              <Trans k={"fiche.etablissements." + this.state.filterCategory} />
            ) : (
              this.state.filterCategory
            )}
          </span>
        ),
      }),
      dataKey: "cat",
    };

    let columnsConf = [
      columnRank,
      columnLib,
      columnCaegorie,
      this.getColumnGeo(),
    ];

    columnsConf = columnsConf.concat(this.getColumnsPdm());
    columnsConf.push(columnSpacer);
    columnsConf = columnsConf.concat(this.getColumnsValue());
    columnsConf.push(columnSpacer);

    return columnsConf;
  }

  getFilterSelectionDropdown() {
    const list = [
      {
        id: DATA_FILTER_SELECTION_ALL,
        lib: "fiche.etablissements." + DATA_FILTER_SELECTION_ALL,
      },
      {
        id: DATA_FILTER_SELECTION_IN,
        lib: "fiche.etablissements." + DATA_FILTER_SELECTION_IN,
      },
    ];
    return (
      <FilterDropdown
        list={list}
        activeItem={this.state.filterSelection}
        onItemClickHandler={(filterSelection) => {
          this.setState({ filterSelection });
        }}
      />
    );
  }

  getFilterCategoryDropdown() {
    let list = [
      {
        id: DATA_FILTER_CATEGORY_ALL,
        lib: "fiche.etablissements." + DATA_FILTER_CATEGORY_ALL,
      },
    ];
    list = list.concat(
      this.categoriesList.toJS().map((cat) => {
        return { id: cat, lib: cat };
      })
    );

    return (
      <FilterDropdown
        list={list}
        activeItem={this.state.filterCategory}
        onItemClickHandler={(filterCategory) => {
          this.setState({ filterCategory });
        }}
      />
    );
  }

  getColumnGeo() {
    return {
      ...this.getColumnDefault({
        filterDropdown: this.getFilterDepartementDropdown(),
        labelComponent: (
          <span>
            <Trans
              className="label-title"
              k="fiche.etablissements.header.departement"
            />
            <br />
            {DATA_FILTER_DEPARTEMENT_ALL === this.state.filterDepartement ? (
              <Trans
                k={"fiche.etablissements." + this.state.filterDepartement}
              />
            ) : (
              this.state.filterDepartement
            )}
          </span>
        ),
      }),
      width: 100,
      flexGrow: 2,
      dataKey: "lib_dep",
    };
  }

  getFilterDepartementDropdown() {
    let list = [
      {
        id: DATA_FILTER_DEPARTEMENT_ALL,
        lib: "fiche.etablissements." + DATA_FILTER_DEPARTEMENT_ALL,
      },
    ];
    list = list.concat(
      this.departementsList.toJS().map((dep) => {
        return { id: dep, lib: dep };
      })
    );

    return (
      <FilterDropdown
        list={list}
        activeItem={this.state.filterDepartement}
        onItemClickHandler={(filterDepartement) => {
          this.setState({ filterDepartement });
        }}
      />
    );
  }

  getColumnsPdm() {
    return Object.values(persistentData.get("annees"))
      .reverse()
      .map((annee, index) => {
        const isFirst = 0 === index;
        let labelComponent = null;
        if (
          isFirst &&
          DATA_FILTER_TYPE_ZONE_ACCES_TIME === this.props.typePdm
        ) {
          labelComponent = (
            <AccesTimeLib accesTime={this.props.accesTime} pdmPrefix={true} />
          );
        }
        return {
          ...this.getColumnDefault({
            dataDesc: { annee },
            filterDropdown: isFirst ? this.getFilterPdmDropdown() : null,
            labelComponent,
          }),
          width: 70,
          flexGrow: 0,
          dataKey: "pdm_" + annee.id,
          label: this.getFilterTransKey(),
          cellRenderer: this.cellPdmRenderer.bind(this),
          headerClassName:
            "header-multiline header-value " + (isFirst ? "first" : "other"),
        };
      });
  }

  getFilterTransKeys() {
    return {
      [DATA_FILTER_TYPE_FRANCE]: "dataType.PdmFrance",
      [DATA_FILTER_TYPE_REG_16]: "dataType.PdmNouvelleRegion",
      [DATA_FILTER_TYPE_REG_15]: "dataType.PdmAncienneRegion",
      [DATA_FILTER_TYPE_DEP]: "dataType.PdmDepartement",
      [DATA_FILTER_TYPE_ZONE_CHALANDISE]: "dataType.PdmZoneChalandise",
      [DATA_FILTER_TYPE_ZONE_PROXIMITE]: "dataType.PdmZoneProximite",
      [DATA_FILTER_TYPE_ZONE_ACCES_TIME]: "dataType.PdmTempsAcces",
    };
  }

  getFilterTransKey() {
    return this.getFilterTransKeys()[this.props.typePdm];
  }

  getFilterPdmDropdown() {
    const filterTransKeys = this.getFilterTransKeys();
    const zones = Object.keys(filterTransKeys)
      .map((value) => {
        return {
          value,
          transKey: filterTransKeys[value],
          disabled: -1 === this.props.availablePdm.indexOf(value),
        };
      })
      .filter((zone) => {
        return zone.value !== DATA_FILTER_TYPE_ZONE_ACCES_TIME;
      });

    return (
      <div
        className="filter-dropdown"
        ref={(dropdown) => {
          this.filterPdmDropdown = dropdown;
        }}
      >
        <button
          className="dropdown__trigger dropdown-orange"
          onClick={() => {
            this.setState({ dropdownOpen: !this.state.dropdownOpen });
          }}
        >
          <span className="icon icon-edit" />
        </button>
        {this.state.dropdownOpen && (
          <ul className="dropdown-menu">
            <ZonesFilter
              zones={zones}
              className="choices-list form-content zone"
              selectedZone={this.props.typePdm}
              selectedAccesTime={this.props.accesTime}
              accesTimeDisabled={
                -1 ===
                this.props.availablePdm.indexOf(
                  DATA_FILTER_TYPE_ZONE_ACCES_TIME
                )
              }
              onSubmitHandler={(selection) => {
                this.setState({ dropdownOpen: !this.state.dropdownOpen });
                this.props.fetchEtablissementsDatas(
                  selection.zone,
                  selection.accesTime
                );
              }}
            />
          </ul>
        )}
      </div>
    );
  }

  render() {
    if (null === this.getEtablissementsList()) {
      this.setEtablissementsList(this.initEtablissementsList());
    }
    if (this.needCalcPdmAccessTime) {
      this.setEtablissementsList(
        this.calcPdmAccessTime(this.getEtablissementsList())
      );
    }

    return (
      <div className="list-etablissements-container list-etablissements">
        {this.renderTable()}
      </div>
    );
  }
}

FicheEtablissementsListContainer.propTypes = {
  dataPending: PropTypes.bool,
  etablissements: PropTypes.object,
  fetchEtablissementsDatas: PropTypes.func,
  typePdm: PropTypes.string,
  accesTime: PropTypes.array,
  datas: PropTypes.object,
  availablePdm: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    annee: state.routing.params[PARAM_NAME_ANNEE],
    dataType: state.routing.params[PARAM_NAME_DATA_TYPE],
    dataPending: state.fetchData[DATA_TYPE_FICHE_ETABLISSEMENTS].pending,
    typePdm: state.ficheEtablissements.typePdm,
    accesTime: state.ficheEtablissements.accesTime,
    datas: state.ficheEtablissements.datas,
    availablePdm: state.ficheEtablissements.availablePdm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEtablissementsDatas: (typePdm, accesTime) => {
      dispatch(fetchEtablissementsDatas(typePdm, accesTime));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(FicheEtablissementsListContainer);
