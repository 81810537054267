import React from 'react';
import PropTypes from "prop-types";
import cn from 'classnames';

export default class Checkbox extends React.Component {
    getSelectedZone(){
        if(this.props.radio){
            return <div className="selected-zone" />;
        }
        return <span className="selected-zone icon icon-check" />
    }
    render(){
        return (
            <div className={cn('checkbox-btn', {'radio': this.props.radio, 'checked': this.props.checked})}>
                {this.getSelectedZone()}
            </div>
        );
    }
}

Checkbox.defaultProps = {
    checked: false,
    radio: false
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    radio: PropTypes.bool
};