import axios from "axios";
import {
  FICHE_GHM_DETAIL_SET_GHM_ID,
  FICHE_GHM_DETAIL_SET_GHM_DATAS,
} from "constants/actionTypes";
import { API_URL } from "constants/config";

export const fetchGhmDetailData =
  (idAnnee, idGhm) => async (dispatch, getState) => {
    const { idAnnee: stateIdAnnee, idGhm: stateIdGhm } =
      getState().ficheGhmDetail;

    if (stateIdAnnee !== idAnnee || stateIdGhm !== idGhm) {
      dispatch({
        type: FICHE_GHM_DETAIL_SET_GHM_ID,
        payload: { idAnnee, idGhm },
      });

      try {
        const response = await axios.post(
          `${API_URL}/fiche/ghm/detail`,
          {
            hash: getState().mainSelector.hash,
            annee: idAnnee,
            ghm: idGhm,
          },
          { withCredentials: true }
        );

        const { data } = response;

        if (
          data.hash === getState().mainSelector.hash &&
          data.annee === idAnnee &&
          data.ghm === idGhm
        ) {
          dispatch({
            type: FICHE_GHM_DETAIL_SET_GHM_DATAS,
            payload: { idGhm, datas: data.datas },
          });
        }
      } catch (error) {
        console.log("error", FICHE_GHM_DETAIL_SET_GHM_DATAS);
        // dispatch({type: FICHE_GHM_DETAIL_SET_GHM_ID, payload: {id: null}});
      }
    }
  };

export const clearGhmDetailData = () => (dispatch) => {
  dispatch({
    type: FICHE_GHM_DETAIL_SET_GHM_ID,
    payload: { idAnnee: null, idGhm: null },
  });
};
