import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {navigate} from 'actions/routerActions';
import {PARAM_VALUE_OFF, PARAM_NAME_GEO_SELECTION} from 'constants/router';

import TabMenu from 'components/molecules/TabsMenu/TabsMenu';
import SwitchSelection from 'components/molecules/CarteBottomNav/SwitchSelection';

class CarteBottomNav extends React.Component {

    changeGeoType(tab){
        if(!tab.isActive){
            this.props.navigate(this.props.paramName, tab.linkKey);
        }
    }

    render(){
        if(this.props.hidden){
            return null;
        }
        return (
            <div className="carte-bottom-nav center-container">
                <TabMenu
                    className="reverse"
                    tabs={this.props.tabs}
                    onItemClickHandler={this.changeGeoType.bind(this)}
                />
                {(true !== this.props.selectionDisabled)
                    ? <SwitchSelection disabled={this.props.selectionDisabled} />
                    : null}
            </div>
        );
    }
}


CarteBottomNav.propTypes = {
    tabs: PropTypes.array.isRequired,
    selectionDisabled: PropTypes.bool,
    navigate: PropTypes.func,
    hidden: PropTypes.bool,
    paramName: PropTypes.string.isRequired
};

CarteBottomNav.defaultProps = {
    selectionDisabled: false,
    hidden: false
};

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        navigate: (paramName, paramValue) => {
            dispatch(navigate(null, {
                [paramName]: paramValue,
                [PARAM_NAME_GEO_SELECTION]: PARAM_VALUE_OFF
            }));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarteBottomNav);
